import React, { useRef } from "react";
import { Box, AppBar, Toolbar, Slide, useScrollTrigger } from "@mui/material";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import useNavLinks from "./hooks/useNavLinks";
import { useTheme } from "themes/Palette";
import { useNavBar } from "components/common/NavBar/NavBarContext";

function HideOnScroll({ children, disable }) {
  const trigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true
  });

  if (disable) {
    return children;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const NavBar = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const links = useNavLinks();
  const { isDarkMode, toggleTheme } = useTheme();
  const navbarRef = useRef(null);
  const { hideNavbar } = useNavBar();

  if (hideNavbar) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <HideOnScroll disable={isHomePage}>
        <AppBar position="fixed" ref={navbarRef} color="transparent" elevation={0} // Add this line to set a specific elevation
        >
          <Toolbar>
            {!isHomePage && (
              <Header
                links={links}
                isDarkMode={isDarkMode}
                toggleTheme={toggleTheme}
              />
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar /> {/* This empty Toolbar adds the necessary offset */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: 2,
          boxSizing: 'border-box',
        }}
      >
        {children}
      </Box>
    </Box >
  );
};

export default NavBar;