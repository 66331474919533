import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const ClickableTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    
  },
  // You can use theme here if needed, for example:
  // color: theme.palette.primary.main,
}));
