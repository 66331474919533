import { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
/**
 * GalleryView Component
 * 
 * A reusable component that provides gallery functionality with navigation.
 * 
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the gallery is open
 * @param {Function} props.onClose - Function to call when closing the gallery
 * @param {Array} props.items - Array of items to display in the gallery
 * @param {number|string} props.initialItemIndex - Index or ID of the initially selected item
 * @param {Function} props.renderForegroundContent - Function to render the main foreground content
 * @param {Function} props.renderBackgroundContent - Function to render the background content
 */
const GalleryView = ({
    isOpen,
    onClose,
    items,
    initialItemIndex = 0,
    renderForegroundContent,
    renderBackgroundContent
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();

    const handleNavigation = useCallback((direction) => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + direction;
            return newIndex >= 0 && newIndex < items.length ? newIndex : prevIndex;
        });
    }, [items.length]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                handleNavigation(-1);
            } else if (event.key === 'ArrowRight') {
                handleNavigation(1);
            } else if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onClose, handleNavigation]);

    useEffect(() => {
        if (isOpen && currentIndex === 0) {
            if (typeof initialItemIndex === 'number') {
                setCurrentIndex(initialItemIndex);
            } else if (typeof initialItemIndex === 'string') {
                const index = items.findIndex(item => item.id === initialItemIndex);
                setCurrentIndex(index !== -1 ? index : 0);
            }
        }
    }, [isOpen, items, initialItemIndex, currentIndex]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        background: theme.palette.background.default,
                        zIndex: 1000,
                    }}
                >
                    {renderBackgroundContent && renderBackgroundContent({
                        currentIndex,
                        totalItems: items.length,
                        onClose
                    })}
                    <Box
                        display="flex"
                        flexGrow={1}
                        justifyContent="center"
                        alignItems="center"
                        position="relative"

                    >
                        {renderForegroundContent && renderForegroundContent({
                            currentItem: items[currentIndex],
                            onPrevious: () => handleNavigation(-1),
                            onNext: () => handleNavigation(1),
                            isFirstItem: currentIndex === 0,
                            isLastItem: currentIndex === items.length - 1
                        })}
                    </Box>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default GalleryView;
