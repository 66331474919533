import { Typography } from '@mui/material';
import BarChart from '../Charts/BarChart';

const GCMetrics = ({ metrics, theme }) => {
    if (!metrics || !metrics.python_gc_objects_collected_total) {
        return <Typography>No GC metrics available</Typography>;
    }

    const generations = ['0', '1', '2'];
    const gcData = [
        {
            x: generations,
            y: generations.map(gen => {
                const values = metrics.python_gc_objects_collected_total.values[`{"generation":"${gen}"}`];
                return values ? values[values.length - 1] : 0;
            }),
            type: 'bar',
            name: 'Objects Collected',
            marker: { color: theme.palette.primary.main }
        },
        {
            x: generations,
            y: generations.map(gen => {
                const values = metrics.python_gc_collections_total.values[`{"generation":"${gen}"}`];
                return values ? values[values.length - 1] : 0;
            }),
            type: 'bar',
            name: 'Collections',
            marker: { color: theme.palette.secondary.main }
        }
    ];

    const layout = {
        title: 'Garbage Collection Metrics',
        barmode: 'group',
        paper_bgcolor: theme.palette.background.paper,
        plot_bgcolor: theme.palette.background.paper,
        font: { color: theme.palette.text.primary },
        xaxis: { title: 'Generation', color: theme.palette.text.primary },
        yaxis: { title: 'Count', color: theme.palette.text.primary },
    };

    return <BarChart data={gcData} layout={layout} />;
};

export default GCMetrics;