import React from 'react';
import { Card, CardContent, Button, Typography, Box, Grid, Chip, Menu, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, Slider } from '@mui/material';
import { formatMoney } from 'utils/dataUtils';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import { alpha, useTheme } from '@mui/material/styles';


const PricingCard = ({ use, onPriceClick, onUpdates, videoPlayer }) => {
    const theme = useTheme();
    const [flagAnchorEl, setFlagAnchorEl] = React.useState(null);
    const [overrideOpen, setOverrideOpen] = React.useState(false);
    const [noteOpen, setNoteOpen] = React.useState(false);

    const handleFlagClick = (event) => setFlagAnchorEl(event.currentTarget);
    const handleFlagClose = () => setFlagAnchorEl(null);
    const handleFlagSelect = (flagType) => {
        onUpdates("add_flag", { useId: use.id, flag: flagType });
        handleFlagClose();
    };

    const handleFlagDelete = () => {
        onUpdates("add_flag", { useId: use.id, flag: null });
    };

    const handleOverrideOpen = () => setOverrideOpen(true);
    const handleOverrideClose = () => setOverrideOpen(false);
    const handleOverrideSubmit = (override) => {
        onUpdates("override", { useId: use.id, override });
        handleOverrideClose();
    };

    const handleNoteOpen = () => setNoteOpen(true);
    const handleNoteClose = () => setNoteOpen(false);
    const handleNoteSubmit = (note) => {
        console.log('handleNoteSubmit', { opportunity_id: use.opportunity_id, use_id: use.id, note });
        onUpdates("add_note", { matterId: use.opportunity_id, useId: use.id, note });
        handleNoteClose();
    };

    const handleUpdateVideoQuality = (quality) => {
        console.log('handleUpdateVideoQuality', {
            useId: use.id,
            page_url: use.page_url,
            quality,
            currentQuality: use.quality
        });
        onUpdates("video_quality", {
            useId: use.id,
            page_url: use.page_url,
            quality
        });
    };

    const handlePopularityRankUpdate = (popularity_rank) => {
        onUpdates("popularity_rank", { works_id: use.works_id, popularity_rank });
    };

    const handleOverrideDelete = () => {
        onUpdates("override", { useId: use.id, override: null });
    };

    return (
        <Card sx={{
            height: '100%',
            width: '100%',
            background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
            backdropFilter: 'blur(10px)',
            borderRadius: '20px',
            backdropFilter: 'blur(8px)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',


        }}>
            <CardContent sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                gap: 4,
                '&:last-child': {
                    pb: 4
                }
            }}>
                {/* Video Player Section */}
                <Box sx={{
                    width: '100%',
                    aspectRatio: '16/9',
                    position: 'relative',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',

                }}>
                    {videoPlayer}
                </Box>

                {/* Updated Flag Chip */}
                {use.flag && (
                    <Chip
                        label={use.flag}
                        onDelete={handleFlagDelete}
                        deleteIcon={<CancelIcon />}
                        sx={{
                            alignSelf: 'center',
                            background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
                            color: theme.palette.text.contrastText,
                            borderRadius: '16px',
                            border: `1px solid ${theme.palette.primary.contrastText}`,
                            '& .MuiChip-deleteIcon': {
                                color: theme.palette.text.contrastText,
                                '&:hover': {
                                    color: theme.palette.error.light,
                                }
                            }
                        }}
                    />
                )}

                {/* Pricing Information */}
                <Grid container spacing={3} >
                    {[
                        { label: 'Floor', value: use.floor },
                        {
                            label: use.flag === 'Repost' ? 'Repost' :
                                use.flag === 'Static Image' ? 'Static Image' :
                                    use.override !== null ? 'Override' : 'Valuation',
                            value: use.valuation
                        },
                        { label: 'Prorated', value: use.prorated }
                    ].map(({ label, value }) => (
                        <Grid item xs={12} sm={4} key={label} >
                            <Box sx={{
                                p: 2.5,
                                borderRadius: '12px',
                                background: alpha(theme.palette.background.default, 0.03),
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    background: alpha(theme.palette.background.default, 0.05),
                                },
                                textAlign: 'center'
                            }}>
                                <Typography
                                    variant="overline"
                                    sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        letterSpacing: '0.1em',
                                        fontSize: '0.75rem'
                                    }}
                                >
                                    {label}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontWeight: 600,
                                        mt: 1
                                    }}
                                >
                                    {value ? formatMoney.format(value) : '—'}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {/* Action Buttons */}
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    {[
                        { icon: <FlagIcon />, label: 'Flag', onClick: handleFlagClick },
                        {
                            icon: <EditIcon />,
                            label: 'Override',
                            onClick: handleOverrideOpen,
                            onDelete: use.override !== null ? handleOverrideDelete : undefined,
                            deleteIcon: use.override !== null ? <CancelIcon /> : undefined
                        },
                        { icon: <NoteAddIcon />, label: 'Note', onClick: handleNoteOpen }
                    ].map(({ icon, label, onClick, onDelete, deleteIcon }) => (
                        <Chip
                            key={label}
                            icon={icon}
                            label={label}
                            onClick={onClick}
                            onDelete={onDelete}
                            deleteIcon={deleteIcon}
                            sx={{
                                minWidth: '120px',
                                height: '42px',
                                borderRadius: '21px',
                                background: alpha(theme.palette.background.default, 0.05),
                                backdropFilter: 'blur(5px)',
                                border: `1px solid ${theme.palette.divider}`,
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    background: alpha(theme.palette.background.default, 0.1),
                                },
                                '& .MuiChip-icon': {
                                    color: alpha(theme.palette.text.primary, 0.8)
                                },
                                '& .MuiChip-deleteIcon': {
                                    color: theme.palette.text.primary,
                                    '&:hover': {
                                        color: theme.palette.error.light,
                                    }
                                }
                            }}
                        />
                    ))}
                </Box>

                {/* Ranking Sections */}
                <Box sx={{
                    display: 'flex',
                    gap: 4,
                    flexWrap: 'wrap',

                }}>
                    <RankingSection
                        title="Popularity Rank"
                        options={['Low', 'High']}
                        selected={use.popularity_rank}
                        onSelect={handlePopularityRankUpdate}
                        theme={theme}
                    />
                    <RankingSection
                        title="Quality"
                        options={['LQ', 'MQ', 'HQ']}
                        selected={use.quality}
                        onSelect={handleUpdateVideoQuality}
                        labelMap={{ LQ: 'Low', MQ: 'Medium', HQ: 'High' }}
                        theme={theme}
                    />
                </Box>
            </CardContent>

            {/* Update the dialogs styling */}
            <FlagMenu
                anchorEl={flagAnchorEl}
                onClose={handleFlagClose}
                onSelect={handleFlagSelect}
            />
            <OverrideDialog
                open={overrideOpen}
                onClose={handleOverrideClose}
                onSubmit={handleOverrideSubmit}
            />
            <NoteDialog
                open={noteOpen}
                onClose={handleNoteClose}
                onSubmit={handleNoteSubmit}
            />
        </Card>
    );
};

const RankingSection = ({ title, options, selected, onSelect, labelMap = {}, theme }) => {
    // Convert options to marks format for slider
    const marks = options.map((option, index) => ({
        value: index * 50,
        label: labelMap[option] || option,
        originalValue: option
    }));

    // Find current value based on selected option, default to null if not found
    const currentValue = selected === null ? null : (marks.find(mark => mark.originalValue === selected)?.value || 0);

    return (
        <Box sx={{ flex: 1, minWidth: '200px' }}>
            <Typography
                variant="overline"
                sx={{
                    color: selected === null ? theme.palette.warning.main : alpha(theme.palette.text.primary, 0.6),
                    letterSpacing: '0.1em',
                    display: 'block',
                    mb: 2,
                    textAlign: 'center'
                }}
            >
                {title}
            </Typography>
            <Box sx={{ px: 4 }}>
                <Slider
                    value={currentValue || 0}
                    step={null}
                    marks={marks}
                    min={0}
                    max={(options.length - 1) * 50}
                    onChange={(_, value) => {
                        const selectedMark = marks.find(mark => mark.value === value);
                        if (selectedMark) {
                            onSelect(selectedMark.originalValue);
                        }
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiSlider-track': {
                            background: selected === null ? 'transparent' : `linear-gradient(to right, ${alpha(theme.palette.text.contrastText, 1.0)} 0%, ${theme.palette.primary.main} 100%)`,
                        },
                        '& .MuiSlider-thumb': {
                            backgroundColor: selected === null ? theme.palette.grey[400] : theme.palette.primary.main,
                            animation: selected === null ? 'pulse 2s infinite' : 'none',
                        },
                        '& .MuiSlider-markLabel': {
                            color: theme.palette.text.primary,
                            marginTop: '8px',
                            transform: 'translateX(-50%)',
                        },
                        mt: 2,
                        mb: 1,
                        opacity: selected === null ? 0.5 : 1,
                        '@keyframes pulse': {
                            '0%': {
                                boxShadow: '0 0 0 0 rgba(255, 152, 0, 0.4)', // warning color with opacity
                            },
                            '70%': {
                                boxShadow: '0 0 0 10px rgba(255, 152, 0, 0)',
                            },
                            '100%': {
                                boxShadow: '0 0 0 0 rgba(255, 152, 0, 0)',
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const FlagMenu = ({ anchorEl, onClose, onSelect }) => {
    const sortedFlags = [
        'Artist Promo',
        'Drop Use',
        'Fair Use',
        'Incorrect Song Listed',
        'Missing Ownership %',
        'No Song Embedded',
        'Optics',
        'Owns Both Sides',
        'Repost',
        'Static Image',
        'UGC'
    ];

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
            {sortedFlags.map((flagType) => (
                <MenuItem key={flagType} onClick={() => onSelect(flagType)}>
                    {flagType}
                </MenuItem>
            ))}
        </Menu>
    );
};

const OverrideDialog = ({ open, onClose, onSubmit }) => {
    const [amount, setAmount] = React.useState('');

    const handleSubmit = () => {
        onSubmit(parseFloat(amount));
        setAmount('');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Override</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const NoteDialog = ({ open, onClose, onSubmit }) => {
    const [note, setNote] = React.useState('');

    const handleSubmit = () => {
        onSubmit(note);
        setNote('');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Note"
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PricingCard;
