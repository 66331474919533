import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { DataTable } from "components/common/DataTable";

const TaskTable = ({ title, tasks, rowsPerPage }) => {
    return (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', height: '50%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" component="h2">
                    {title} ({tasks.length})
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <DataTable
                    data={tasks}
                    rowsPerPage={rowsPerPage}
                />
            </Box>
        </Grid>
    );
};

export default TaskTable;