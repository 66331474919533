import {
    Box,
    Button,
    CardContent,
    Step, StepLabel,
    Stepper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { dashboardService, matterService } from 'services/api';
import { titleCase } from 'utils/dataUtils';
import LoadingSpinner from 'utils/LoadingSpinner';
import { CompleteButton } from '../../utils/CompleteButton';
import DetailView from '../DetailView';
import ReviewStart from './ReviewStart';
import ReviewStep from './ReviewStep';
import { INITIAL_REVIEW_DATA, STEP_CONFIGS } from './reviewSteps';
import ReviewSummary from './ReviewSummary';
import { useTimelineContext } from '../../TimelineContext';

const combineTextByParagraphClass = (fileMd, paragraphClass) => {
    if (!fileMd || !Array.isArray(fileMd)) return '';

    return fileMd
        .filter(row => row.paragraph_class === paragraphClass)
        .map(row => row.text)
        .join('\n\n')
        .trim();
};

const formatStepLabel = (label) => {
    return label
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
};


const AgreementReviewDetail = ({ item, onClose }) => {
    const theme = useTheme();
    const [agreement, setAgreement] = useState(item.meta);
    const [fileMd, setFileMd] = useState(item.file_md);
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [reviewStarted, setReviewStarted] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: null });
    const [preambleText, setPreambleText] = useState('');
    const [paymentText, setPaymentText] = useState('');
    const [clauseText, setClauseText] = useState('');
    const [reviewData, setReviewData] = useState(INITIAL_REVIEW_DATA);
    const [corrections, setCorrections] = useState({});
    const [matters, setMatters] = useState([]);
    const { handleComplete } = useTimelineContext();


    // Memoize step configurations
    const stepConfigs = useMemo(() => ({
        agreementType: {
            title: `Is this a ${titleCase(item.category || '')} agreement?`,
            subtitle: item.category
                ? `Detected Type: ${item.category}`
                : 'No agreement type detected',
            noValueTitle: 'Review Agreement Type',
            noValueSubtitle: 'Based on the excerpt below, please enter the correct agreement type',
            value: reviewData.agreementTypeCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: preambleText,
            onChange: (value) => handleStepUpdate('agreementTypeCorrect', value),
            showCorrection: reviewData.agreementTypeCorrect === 'no',
            correctionType: 'text',
            correctionValue: corrections.agreementType || '',
            onCorrectionChange: (value) => handleCorrectionChange('agreementType', value)
        },
        matter: {
            title: 'Is the matter information correct?',
            subtitle: item.matter_name
                ? `Detected Matter: ${item.matter_name}`
                : 'No matter information detected',
            noValueTitle: 'Review Matter Information',
            noValueSubtitle: 'Please review the agreement excerpt and enter the correct matter.',
            value: reviewData.matterCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: preambleText,
            onChange: (value) => handleStepUpdate('matterCorrect', value),
            showCorrection: reviewData.matterCorrect === 'no',
            correctionType: 'matter',
            correctionValue: corrections.matter || null,
            onCorrectionChange: (value) => handleCorrectionChange('matter', value),
            matterOptions: matters
        },
        effectiveDate: {
            title: 'Is the effective date correct?',
            subtitle: item.effective_date
                ? `Detected Date: ${item.effective_date}`
                : 'No effective date detected',
            noValueTitle: 'Review Effective Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the effective date',
            value: reviewData.effectiveDateCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: item.effective_date_span ? item.effective_date_span : preambleText,
            onChange: (value) => handleStepUpdate('effectiveDateCorrect', value),
            showCorrection: reviewData.effectiveDateCorrect === 'no',
            correctionType: 'date',
            correctionValue: corrections.effectiveDate || null,
            onCorrectionChange: (value) => handleCorrectionChange('effectiveDate', value)
        },
        expirationDate: {
            title: 'Is the expiration date correct?',
            subtitle: item.expiration_date
                ? `Detected Expiration Date: ${item.expiration_date}`
                : 'No expiration date detected',
            noValueTitle: 'Review Expiration Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the expiration date',
            value: reviewData.expirationDateStatus,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: item.expiration_date_span || clauseText,
            onChange: (value) => handleStepUpdate('expirationDateStatus', value),
            showCorrection: reviewData.expirationDateStatus === 'no',
            correctionType: 'date',
            correctionValue: corrections.expirationDate || null,
            onCorrectionChange: (value) => handleCorrectionChange('expirationDate', value)
        },
        paymentDate: {
            title: 'Is the payment due date correct?',
            subtitle: item.payment_due
                ? `Detected Payment Date: ${item.payment_due}`
                : 'No payment due date detected',
            noValueTitle: 'Review Payment Due Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the payment due date',
            value: reviewData.paymentDueDateCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
            ],
            text: paymentText,
            onChange: (value) => handleStepUpdate('paymentDueDateCorrect', value),
            showCorrection: reviewData.paymentDueDateCorrect === 'no',
            correctionType: 'date',
            correctionValue: corrections.paymentDate || null,
            onCorrectionChange: (value) => handleCorrectionChange('paymentDate', value)
        }
    }), [item, preambleText, paymentText, reviewData, corrections, matters]);

    const steps = useMemo(() => {
        let steps = [...STEP_CONFIGS.base];
        if (item.category && STEP_CONFIGS[item.category]) {
            steps = [...steps, ...STEP_CONFIGS[item.category]];
        }
        return steps;
    }, [item.category]);

    useEffect(() => {
        setPreambleText(combineTextByParagraphClass(item.file_md, 'PREAMBLE'));
        setPaymentText(combineTextByParagraphClass(item.file_md, 'SETTLEMENT_PAYMENT'));
        setClauseText(combineTextByParagraphClass(item.file_md, 'CLAUSE'));
    }, [item.file_md]);

    useEffect(() => {
        const fetchMatters = async () => {
            try {
                const response = await matterService.getAllMatters();
                setMatters(response.data);
            } catch (error) {
                console.error('Failed to fetch matters:', error);
            }
        };
        fetchMatters();
    }, []);

    useEffect(() => {
        setReviewStarted(false);
        setActiveStep(0);
        setReviewData(INITIAL_REVIEW_DATA);
        setCorrections({});
        setSubmitStatus({ success: false, error: null });

        // Reset the text fields
        setPreambleText(combineTextByParagraphClass(item.file_md, 'PREAMBLE'));
        setPaymentText(combineTextByParagraphClass(item.file_md, 'SETTLEMENT_PAYMENT'));
        setClauseText(combineTextByParagraphClass(item.file_md, 'CLAUSE'));
    }, [item]);

    const handleStepUpdate = (fieldName, value) => {
        setReviewData(prev => ({
            ...prev,
            [fieldName]: value
        }));

        // Only advance to next step if the answer is 'yes' or 'correct'
        if (value === 'yes' || value === 'correct') {
            handleNext();
        }
    };

    const handleCorrectionChange = (fieldName, value) => {
        setCorrections(prev => ({
            ...prev,
            [fieldName]: value
        }));
    };

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleStartReview = () => {
        setReviewStarted(true);
        setActiveStep(0);
    };

    const handleReviewSubmit = async () => {
        setLoading(true);
        try {
            // Mark as complete first
            await handleComplete(item.id);

            // Format date corrections to YYYY-MM-DD string
            const formatDate = (date) => {
                if (!date) return null;
                return new Date(date).toISOString().split('T')[0];
            };

            const review = [
                {
                    field: 'agreement_type',
                    extracted: item.category || null,
                    correct: reviewData.agreementTypeCorrect === 'yes',
                    correction: reviewData.agreementTypeCorrect === 'no' ? corrections.agreementType : null
                },
                {
                    field: 'matter_id',
                    extracted: item.matter_id || null,
                    correct: reviewData.matterCorrect === 'yes',
                    correction: reviewData.matterCorrect === 'no' ? corrections.matter?.id : null,
                },
                {
                    field: 'matter',
                    extracted: item.matter_name || null,
                    correct: reviewData.matterCorrect === 'yes',
                    correction: reviewData.matterCorrect === 'no' ? corrections.matter?.name : null
                },
                {
                    field: 'effective_date',
                    extracted: item.effective_date || null,
                    correct: reviewData.effectiveDateCorrect === 'yes',
                    correction: reviewData.effectiveDateCorrect === 'no' ? formatDate(corrections.effectiveDate) : null
                },
                {
                    field: 'expiration_date',
                    extracted: item.expiration_date || null,
                    correct: reviewData.expirationDateStatus === 'yes',
                    correction: reviewData.expirationDateStatus === 'no' ? formatDate(corrections.expirationDate) : null
                },
                {
                    field: 'payment_due',
                    extracted: item.payment_due || null,
                    correct: reviewData.paymentDueDateCorrect === 'yes',
                    correction: reviewData.paymentDueDateCorrect === 'no' ? formatDate(corrections.paymentDate) : null
                }
            ];


            // Submit the review after completion
            await dashboardService.submitAgreementReview(item.file_id, { review });

            setSubmitStatus({ success: true, error: null });
        } catch (error) {
            // If either operation fails, revert the success status
            setSubmitStatus({
                success: false,
                error: error.message || 'Failed to submit review'
            });
            throw error; // Re-throw to ensure the error is properly handled
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setReviewStarted(false);
        setActiveStep(0);
        setReviewData(INITIAL_REVIEW_DATA);
        setCorrections({});
        setSubmitStatus({ success: false, error: null });
        onClose?.();
    };

    const getStepContent = (step) => {
        if (step === steps.length) {
            return (
                <>
                    <ReviewSummary
                        reviewData={reviewData}
                        corrections={corrections}
                        item={item}
                        submitStatus={submitStatus}
                    />
                    <CompleteButton text="Complete Review" onClick={handleReviewSubmit} disabled={submitStatus.success} />
                </>
            );
        }

        const stepConfig = stepConfigs[steps[step]];
        if (!stepConfig) return null;

        if (loading) {
            return <LoadingSpinner />;
        }

        return (
            <>
                <ReviewStep {...stepConfig} driveUrl={item.url} />
                {stepConfig.showCorrection && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={!corrections[steps[step]]}
                        >
                            Next
                        </Button>
                    </Box>
                )}
            </>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DetailView onClose={onClose} item={item}>
                <CardContent sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    '&:last-child': { pb: 4 }
                }}>
                    {!reviewStarted ? (
                        <ReviewStart
                            item={item}
                            onStartReview={handleStartReview}
                        />
                    ) : (
                        <>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{formatStepLabel(label)}</StepLabel>
                                    </Step>
                                ))}
                                <Step>
                                    <StepLabel>Summary</StepLabel>
                                </Step>
                            </Stepper>
                            <Box sx={{ mt: 4, minHeight: '300px' }}>
                                {getStepContent(activeStep)}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                            </Box>
                        </>
                    )}
                </CardContent>
            </DetailView>
        </LocalizationProvider>
    );
};

AgreementReviewDetail.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
};

export default memo(AgreementReviewDetail);
