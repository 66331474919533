import { useState, useCallback } from 'react';

export const useSelectedUse = () => {
    const [selectedUseId, setSelectedUseId] = useState(null);

    const clearSelectedUse = useCallback(() => {
        setSelectedUseId(null);
    }, []);

    return {
        selectedUseId,
        setSelectedUseId,
        clearSelectedUse
    };
}; 