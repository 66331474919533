import React from 'react';
import { Typography } from '@mui/material';
import NotificationItem from './NotificationItem';

const NotificationList = ({ notifications, onClose }) => {
    if (notifications.length === 0) {
        return (
            <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                No notifications
            </Typography>
        );
    }

    const sortedNotifications = [...notifications].sort((a, b) =>
        new Date(b.createdAt) - new Date(a.createdAt)
    );

    return sortedNotifications.map((notification) => (
        <NotificationItem
            key={notification.id}
            notification={notification}
            onClose={onClose}
        />
    ));
};

export default NotificationList;
