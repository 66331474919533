import React, { useState, useContext, createContext, useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import lightThemeJson from "./medium_theme.json";
import darkThemeJson from "./medium_theme_dark.json";

// Import the logo images
import lightLogo from "images/klg_logo_light.png";
import darkLogo from "images/klg_logo_dark.png";

const createCustomTheme = (themeJson, mode) => {
  return createTheme({
    ...themeJson,
    palette: {
      mode,
      ...themeJson.palette,
    },
    mixins: {
      ...themeJson.mixins,
      toolbar: {
        minHeight: 0,
        [`@media (min-width:0px) and (orientation: landscape)`]: {
          minHeight: 0,
        },
        [`@media (min-width:600px)`]: {
          minHeight: 12,
        },
      },
    },
    components: {
      ...themeJson.components,
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            // Add padding to account for the toolbar
            paddingTop: 68,
            [`@media (min-width:0px) and (orientation: landscape)`]: {
              paddingTop: 48,
            },
            [`@media (min-width:600px)`]: {
              paddingTop: 64,
            },
          },
        },
      },
    },
  });
};

const lightTheme = createCustomTheme(lightThemeJson, 'light');
const darkTheme = createCustomTheme(darkThemeJson, 'dark');

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export default function Palette({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(getInitialTheme);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const controller = new AbortController();

    const handleChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addEventListener('change', handleChange, { signal: controller.signal });

    return () => controller.abort();
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  };

  const theme = isDarkMode ? darkTheme : lightTheme;
  const logo = isDarkMode ? darkLogo : lightLogo;

  const contextValue = useMemo(() => ({
    isDarkMode,
    toggleTheme,
    theme,
    logo
  }), [isDarkMode, theme, logo]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

function getInitialTheme() {
  const storedPreference = localStorage.getItem('theme');
  if (storedPreference) {
    return storedPreference === 'dark';
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}
