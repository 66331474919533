import { Typography } from '@mui/material';
import BarChart from '../Charts/BarChart';

const DiskIOMetrics = ({ metrics, theme }) => {
    if (!metrics || !metrics.disk_io_read_bytes_total || !metrics.disk_io_write_bytes_total) {
        return <Typography>No disk I/O metrics available</Typography>;
    }

    const readBytes = metrics.disk_io_read_bytes_total.values[''][0] || 0;
    const writeBytes = metrics.disk_io_write_bytes_total.values[''][0] || 0;

    const data = [
        {
            x: ['Read', 'Write'],
            y: [readBytes, writeBytes],
            type: 'bar',
            marker: {
                color: [theme.palette.primary.main, theme.palette.secondary.main]
            }
        }
    ];

    const layout = {
        title: 'Disk I/O Metrics',
        paper_bgcolor: theme.palette.background.paper,
        plot_bgcolor: theme.palette.background.paper,
        font: { color: theme.palette.text.primary },
        xaxis: { title: 'Operation', color: theme.palette.text.primary },
        yaxis: { title: 'Bytes', color: theme.palette.text.primary },
    };

    return <BarChart data={data} layout={layout} />;
};

export default DiskIOMetrics;