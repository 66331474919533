import { Box, Typography } from '@mui/material';

const GaugeChart = ({ value, title, max, theme, style = {} }) => {
    const radius = 120;
    const strokeWidth = 20;
    const normalizedValue = (value / max) * 100;

    // Calculate the circumference and the filled portion
    const circumference = 2 * Math.PI * radius;
    const fillPercent = (normalizedValue / 100) * 0.75; // 0.75 for a 270-degree arc
    const strokeDasharray = `${circumference} ${circumference}`;
    const rotation = -135; // Start at -135 degrees for a 270-degree arc

    return (
        <Box sx={{
            width: style.width || 500,
            height: style.height || 450,
            position: 'relative',
            bgcolor: theme.palette.background.paper,
            p: 2
        }}>
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.text.primary,
                    textAlign: 'center',
                    mb: 2
                }}
            >
                {title}
            </Typography>

            <svg
                width="100%"
                height="300"
                viewBox="-150 -150 300 300"
                style={{ transform: `rotate(${rotation}deg)` }}
            >
                {/* Background arc */}
                <circle
                    cx="0"
                    cy="0"
                    r={radius}
                    fill="none"
                    stroke={theme.palette.grey[200]}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * 0.25} // 0.25 to make it a 270-degree arc
                />

                {/* Value arc */}
                <circle
                    cx="0"
                    cy="0"
                    r={radius}
                    fill="none"
                    stroke={theme.palette.primary.main}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - fillPercent)}
                    style={{
                        transition: 'stroke-dashoffset 0.3s ease'
                    }}
                />
            </svg>

            <Typography
                variant="h4"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: theme.palette.text.primary
                }}
            >
                {Math.round(value)}%
            </Typography>
        </Box>
    );
};

export default GaugeChart; 