import { useState, useEffect, useCallback } from 'react';
import { useService } from 'services/api';
import { createLogger } from 'utils/debug-config';

const log = createLogger('useUsesData');

export const useUsesData = (matterId) => {
    const [uses, setUses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchUses = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            log.debug('Fetching uses details for matter:', matterId);
            const response = await useService.getUses(matterId);
            setUses(response.data);
            log.debug('Uses data fetched:', response.data);
        } catch (err) {
            log.error('Error fetching uses details:', err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [matterId]);

    useEffect(() => {
        fetchUses();
    }, [matterId, fetchUses]);

    return { uses, setUses, isLoading, error, fetchUses };
}; 