import React, { useState, useEffect, useCallback } from "react";
import { Resizable } from "react-resizable";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "react-resizable/css/styles.css";
import { guideService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";


const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  backgroundColor: "black",
  overflow: "hidden",
}));

const Sidebar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: "auto",
  flex: 1,
}));

const FileViewer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
}));

const IframeContainer = styled(Box)({
  flex: 1,
  "& iframe": {
    width: "100%",
    height: "100%",
    border: "none",
  },
});

const ResizeHandle = styled(Box)(({ theme }) => ({
  width: "12px",
  cursor: "col-resize",
  padding: "4px 0 0",
  borderTop: `1px solid ${theme.palette.divider}`,
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "4px",
    height: "30px",
    backgroundColor: theme.palette.divider,
    borderRadius: "2px",
  },
  "&:hover::before": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Guides = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [driveUrl, setDriveUrl] = useState(null);
  const [sidebarWidth, setSidebarWidth] = useState(300);

  const fetchDocuments = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await guideService.getGuides();
      setDocuments(response.data);
    } catch (err) {
      console.error("Error fetching documents:", err);
      setError(
        err.response?.data?.detail ||
        "Failed to load documents. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleDocumentClick = useCallback((document) => {
    setSelectedDocument(document);
    setDriveUrl(document.embedLink);
  }, []);

  const onResize = (event, { size }) => {
    setSidebarWidth(size.width);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container>
      <Resizable
        width={sidebarWidth}
        height={Infinity}
        onResize={onResize}
        minConstraints={[200, Infinity]}
        maxConstraints={[600, Infinity]}
        handle={<ResizeHandle />}

      >
        <Sidebar style={{ width: sidebarWidth }}>
          <SidebarContent>
            <Typography variant="h6" gutterBottom>
              Guides
            </Typography>
            <List>
              {documents.map((doc) => (
                <ListItemButton
                  key={doc.id}
                  onClick={() => handleDocumentClick(doc)}
                  selected={selectedDocument?.id === doc.id}
                >
                  <ListItemText
                    primary={doc.title}
                    primaryTypographyProps={{ noWrap: true, variant: "body2" }}
                  />
                </ListItemButton>
              ))}
            </List>
          </SidebarContent>
        </Sidebar>
      </Resizable>
      <FileViewer>
        {selectedDocument ? (
          <IframeContainer>
            <iframe
              src={driveUrl}
              title={selectedDocument.title}
              allowFullScreen
            />
          </IframeContainer>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography color="textSecondary">
              Select a document to view
            </Typography>
          </Box>
        )}
      </FileViewer>
    </Container>
  );
};

export default Guides;
