import React, { useEffect, useState, useCallback } from 'react';
import { attachmentService } from '../../services/api';
import { DataTableGrouped } from "components/common/DataTable";
import { Typography } from '@mui/material';
import LoadingSpinner from 'utils/LoadingSpinner';



const Attachments = () => {
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAttachments = async () => {
            try {
                const response = await attachmentService.getAttachments();
                setAttachments(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching attachments:', error);
            }
        };

        fetchAttachments();
    }, []);


    const onRowClick = useCallback((rowData) => {
        console.log('Row clicked:', rowData);
        if (rowData && rowData.drive_url) {
            console.log('Opening URL:', rowData.drive_url);
            window.open(rowData.drive_url, '_blank', 'noopener,noreferrer');
        } else {
            console.log('No URL found for this row');
        }
    }, []);



    if (loading) {
        return <LoadingSpinner />;
    }

    if (!attachments || attachments.length === 0) {
        return <Typography variant="h6">No file data found.</Typography>;
    }
    return (
        <DataTableGrouped
            data={attachments}
            onRowClick={onRowClick}
            groupBy="category"
            itemName="file"
        />
    );
};


export default Attachments;