import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { userSuggestionService } from 'services/api';

const SUGGESTION_TYPES = [
    'Bug Report',
    'Feature Request',
    'UI/UX Improvement',
    'Other'
];

export default function SuggestionModal({ open, onClose }) {
    const [suggestionType, setSuggestionType] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async () => {
        try {
            await userSuggestionService.suggest({
                suggestion_type: suggestionType,
                suggestion: suggestion,
                page_url: window.location.href
            });
            setIsSubmitted(true);
        } catch (error) {
            console.error('Failed to submit suggestion:', error);
        }
    };

    const handleClose = () => {
        onClose();
        // Reset form after a short delay to allow close animation
        setTimeout(() => {
            setSuggestionType('');
            setSuggestion('');
            setIsSubmitted(false);
        }, 300);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            {!isSubmitted ? (
                <>
                    <DialogTitle>Submit a Suggestion</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={suggestionType}
                                onChange={(e) => setSuggestionType(e.target.value)}
                                label="Type"
                            >
                                {SUGGESTION_TYPES.map((type) => (
                                    <MenuItem key={type} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            label="Suggestion"
                            value={suggestion}
                            onChange={(e) => setSuggestion(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            onClick={handleSubmit}
                            disabled={!suggestionType || !suggestion}
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            py={3}
                        >
                            <CheckCircleOutlineIcon
                                color="success"
                                sx={{ fontSize: 60, mb: 2 }}
                            />
                            <Typography variant="h6" gutterBottom>
                                Thank you for your feedback!
                            </Typography>
                            <Typography color="text.secondary" align="center">
                                Your suggestion has been submitted successfully.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
} 