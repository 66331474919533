import {
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Link,
  Fade,
} from "@mui/material";
import InputBar from "components/common/InputBar";
import { DataTable } from "components/common/DataTable";
import { searchService } from "services/api";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import LoadingSpinner from "utils/LoadingSpinner";
import Dashboard from "pages/Dashboard/Dashboard";
import { titleCase } from "utils/dataUtils";


const LICENSE_COLS = ["client", "song_title", "artist", "licensee", "title", "term_start", "term_end", "territory"]
const MATTER_COLS = ["client", "matter", "stage", "number_of_uses", "assigned_to", "last_modified"]
const SONG_COLS = ["song_title", "artist", "album", "genre", "released", "record_label"]
const PAYMENT_COLS = ["client", "matter", "settlement_amount", "per_use", "structure", "received", "received_by", "received_on", "received_to_date"]


const searchCategories = [
  { value: "matters", label: "Matters", route: "/matter", cols: MATTER_COLS },
  { value: "songs", label: "Songs", route: "/song_title", cols: SONG_COLS },
  { value: "licenses", label: "Licenses", route: "/license_detail", cols: LICENSE_COLS },
  { value: "payments", label: "Payments", route: "/matter", cols: PAYMENT_COLS },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const DocumentDisplay = ({ document }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ mb: 1, borderLeft: "2px solid #ccc", pl: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Link
          href={document.metadata.page_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="caption" color="secondary.light" fontWeight={100}>
            {document.metadata.email_from} -{" "}
            {formatDate(document.metadata.email_date)} -{" "}
            {document.metadata.subject}
          </Typography>
        </Link>
      </Box>
      <Collapse in={expanded}>
        <Box>
          <Box
            sx={{
              display: "flex",
              color: "text.secondary",
              mb: 1,
            }}
          ></Box>
          <Typography variant="body1" color="text.primary" fontStyle="italic" fontWeight={400}>
            {document.page_content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};


const createExportFilename = (searchType, searchQuery) => {
  const date = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
  const sanitizedQuery = searchQuery.replace(/[^a-zA-Z0-9]/g, '_'); // Remove special characters
  const formattedType = titleCase(searchType);
  const formattedQuery = titleCase(sanitizedQuery);
  return `${formattedType} Report - ${formattedQuery} - ${date}.xlsx`;
};

const SearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse search params from URL
  const searchParams = new URLSearchParams(location.search);
  const initialTerm = searchParams.get('term') || '';
  const initialCategory = searchParams.get('category') || searchCategories[0].value;

  const [searchTerm, setSearchTerm] = useState(initialTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  const handleSearch = useCallback(async (term, category) => {
    if (!term.trim()) {
      setSearchResults([]);
      setHasSearched(false);
      return;
    }

    setHasSearched(true);
    setLoading(true);
    setError(null);
    // Clear existing results
    setSearchResults([]);
    try {
      const response = await searchService.search(category, term);
      setSearchResults(response.data.content);
      // Update URL with search params
      navigate(`/search?term=${encodeURIComponent(term)}&category=${encodeURIComponent(category)}`, { replace: true });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  // Effect to perform search when component mounts or URL changes
  useEffect(() => {
    if (initialTerm) {
      handleSearch(initialTerm, initialCategory);
    }
  }, [initialTerm, initialCategory, handleSearch]);

  const handleRowClick = (row) => {
    const selectedCategoryObj = searchCategories.find(
      (cat) => cat.value === selectedCategory
    );
    if (selectedCategoryObj.route) {
      navigate(`${selectedCategoryObj.route}/${row.id}`, {
        state: { id: row.id },
      });
    }
  };

  const handleDropdownChange = (category) => {
    setSelectedCategory(category);
    setSearchTerm("");
    setSearchResults([]);
    setHasSearched(false);
    // Update URL when category changes
    navigate('/search', { replace: true });
  };

  const categoryColumnMap = {
    matters: MATTER_COLS,
    songs: SONG_COLS,
    licenses: LICENSE_COLS,
    payments: PAYMENT_COLS
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        background: "default.paper",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          p: 2,
          display: "flex",
          flexDirection: "column",
          paddingBottom: "180px",
        }}
      >
        <Box sx={{ minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Fade in={!loading} timeout={300}>
              <Box sx={{ width: '100%', height: '100%' }}>
                {searchResults.length > 0 ? (
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <DataTable
                      data={searchResults}
                      onRowClick={handleRowClick}
                      style={{ width: '98%', height: '100%' }}
                      rowsPerPage={20}
                      defaultVisibleColumns={categoryColumnMap[selectedCategory]}
                      exportFilename={createExportFilename(selectedCategory, searchTerm)}
                    />
                  </Box>
                ) : !hasSearched ? (
                  <Dashboard />
                ) : !error ? (
                  <Typography align="center" style={{ color: "primary.contrastText" }}>
                    No results found. Try a different search term.
                  </Typography>
                ) : null}
              </Box>
            </Fade>
          )}
        </Box>

        {error && (
          <Typography color="error" align="center" mb={4}>
            Error: {error}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          backgroundColor: "background.default",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <InputBar
            onSubmit={(term) => handleSearch(term, selectedCategory)}
            placeholder="Search..."
            width="100%"
            value={searchTerm}
            onChange={setSearchTerm}
            dropdownOptions={searchCategories}
            onDropdownChange={handleDropdownChange}
            mode="search"
            autoFocus={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchPage;
