import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { alpha } from '@mui/material/styles';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';

const ViewAgreementButton = memo(({ url }) => {
    const theme = useTheme();
    return (
        <Button
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            sx={{
                borderRadius: '21px',
                textTransform: 'none',
                px: 3,
                py: 1,
                background: alpha(theme.palette.background.default, 0.05),
                backdropFilter: 'blur(5px)',
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.text.primary,
                '&:hover': {
                    background: alpha(theme.palette.background.default, 0.1),
                }
            }}
            startIcon={<FileOpenIcon />}
        >
            View Agreement
        </Button>
    );
});

ViewAgreementButton.propTypes = {
    url: PropTypes.string.isRequired,
};

ViewAgreementButton.displayName = 'ViewAgreementButton';

export default ViewAgreementButton;