// TollingAgreementFields.js
import React, { useState } from 'react';
import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    FormControl,
} from '@mui/material';

const TollingAgreementFields = ({ onDataChange }) => {
    const [effectiveDate, setEffectiveDate] = useState('');
    const [noExpiration, setNoExpiration] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');

    // Update parent component whenever form data changes
    React.useEffect(() => {
        onDataChange({
            effective_date: effectiveDate,
            no_expiration: noExpiration,
            expiration_date: expirationDate,
        });
    }, [effectiveDate, noExpiration, expirationDate, onDataChange]);

    const handleNoExpirationChange = (e) => {
        setNoExpiration(e.target.checked);
        if (e.target.checked) {
            setExpirationDate('');
        }
    };

    return (
        <Box mt={2}>
            <Typography variant="h6" gutterBottom>
                Tolling Agreement Fields
            </Typography>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Effective Date"
                    type="date"
                    value={effectiveDate}
                    onChange={(e) => setEffectiveDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={noExpiration}
                        onChange={handleNoExpirationChange}
                    />
                }
                label="No Expiration"
            />
            {!noExpiration && (
                <FormControl fullWidth margin="normal">
                    <TextField
                        label="Expiration Date"
                        type="date"
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
            )}
        </Box>
    );
};

export default TollingAgreementFields;