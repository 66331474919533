import React, { useState } from 'react';
import { Button, Box, CircularProgress, Alert } from '@mui/material';
import { read, utils } from 'xlsx';
import { approvalService } from 'services/api';
import { snake_case } from 'utils/dataUtils';


const ImportApprovals = ({ onImportComplete }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const ALLOWED_COLUMNS = [
        "id",
        "account_id",
        "client_id",
        "sent_date",
        "response_date",
        "client_notes",
        "internal_notes",
        "status",
    ];

    const validateData = (data) => {
        // Required columns
        const requiredColumns = ['id', 'account_id', 'client_id', 'status']

        // Check if data is empty
        if (!data || data.length === 0) {
            throw new Error('File is empty');
        }

        // Check if all required columns exist
        const headers = Object.keys(data[0]);
        const missingColumns = requiredColumns.filter(col => !headers.includes(col));

        if (missingColumns.length > 0) {
            throw new Error(`Missing required columns: ${missingColumns.join(', ')}`);
        }

        // Validate each row
        data.forEach((row, index) => {
            if (!row.account_id) {
                throw new Error(`Row ${index + 1}: Missing account_id`);
            }
            if (!['Approved', 'Do Not Pursue', 'Unreviewed'].includes(row.status)) {
                throw new Error(`Row ${index + 1}: Invalid status. Must be Approved, Do Not Pursue, or Unreviewed`);
            }
        });

        return true;
    };

    const processData = (data) => {
        return data.map(row => {
            // Convert keys to snake_case and filter columns
            const processedRow = Object.fromEntries(
                Object.entries(row)
                    .map(([key, value]) => {
                        const snakeCaseKey = snake_case(key);

                        // Handle empty, NA, or null values
                        if (value === '' || value === 'NA' || value === 'N/A' || value === null || value === undefined) {
                            return [snakeCaseKey, null];
                        }

                        // Handle date columns
                        if (['sent_date', 'response_date'].includes(snakeCaseKey) && value) {
                            // Convert Excel date number to JS Date if necessary
                            const dateValue = typeof value === 'number'
                                ? new Date(Math.round((value - 25569) * 86400 * 1000))
                                : new Date(value);
                            return [snakeCaseKey, dateValue.toISOString().split('T')[0]];
                        }
                        return [snakeCaseKey, value];
                    })
                    .filter(([key]) => ALLOWED_COLUMNS.includes(key))
            );
            return processedRow;
        });
    };

    const processFile = async (file) => {
        try {
            const data = await readFileData(file);
            const processedData = processData(data);

            if (validateData(processedData)) {
                await approvalService.importData(processedData);
                setSuccess(true);
                setTimeout(async () => {
                    if (onImportComplete) {
                        await onImportComplete();
                    }
                }, 1500);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const workbook = read(e.target.result, { type: 'array' });
                    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const data = utils.sheet_to_json(firstSheet);
                    resolve(data);
                } catch (err) {
                    reject(new Error('Error processing file'));
                }
            };
            reader.onerror = () => reject(new Error('Error reading file'));
            reader.readAsArrayBuffer(file);
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setLoading(true);
        setError(null);
        setSuccess(false);

        const fileExt = file.name.split('.').pop().toLowerCase();
        if (!['csv', 'xlsx'].includes(fileExt)) {
            setError('Invalid file type. Please upload a CSV or XLSX file');
            setLoading(false);
            return;
        }

        await processFile(file);
    };

    return (
        <Box sx={{ mb: 2 }}>
            <input
                accept=".csv,.xlsx"
                style={{ display: 'none' }}
                id="import-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="import-file">
                <Button
                    variant="contained"
                    component="span"
                    disabled={loading}
                    sx={{ mr: 2 }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Import Approvals'}
                </Button>
            </label>
            {error && (
                <Alert severity="error" sx={{ mt: 1 }}>
                    {error}
                </Alert>
            )}
            {success && (
                <Alert severity="success" sx={{ mt: 1 }}>
                    Import completed successfully
                </Alert>
            )}
        </Box>
    );
};

export default ImportApprovals; 