import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService } from 'services/api';

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await authService.getCurrentUser();
                setCurrentUser(response.data);
                setError(null);
            } catch (error) {
                console.error('Error fetching current user:', error);
                setError('Failed to fetch user data');
                setCurrentUser(null);
            } finally {
                setLoading(false);
            }
        };

        const token = localStorage.getItem('token');
        if (token) {
            fetchCurrentUser();
        } else {
            setLoading(false);
        }
    }, []);

    const login = async (username, password) => {
        try {
            setLoading(true);
            const response = await authService.login(username, password);
            localStorage.setItem('token', response.data.access_token);
            const userResponse = await authService.getCurrentUser();
            setCurrentUser(userResponse.data);
            setError(null);
            return userResponse.data;
        } catch (error) {
            console.error('Login error:', error);
            setError('Login failed. Please check your credentials and try again.');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        try {
            setLoading(true);
            await authService.logout(); // Assuming there's a logout endpoint
            localStorage.removeItem('token');
            setCurrentUser(null);
            setError(null);
        } catch (error) {
            console.error('Logout error:', error);
            setError('Logout failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const value = {
        currentUser,
        login,
        logout,
        loading,
        error,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
