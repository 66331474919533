import React, { createContext, useState, useContext } from 'react';
import { createLogger } from 'utils/debug-config';

const NavBarContext = createContext();

const log = createLogger('NavBarContext');

export const NavBarProvider = ({ children }) => {
    const [hideNavbar, setHideNavbar] = useState(false);
    const [error, setError] = useState(null);

    const handleSetHideNavbar = (value) => {
        try {
            if (typeof value !== 'boolean') {
                throw new Error('hideNavbar must be a boolean value');
            }
            setHideNavbar(value);
        } catch (err) {
            log.error('Error setting navbar visibility:', err);
            setError(err);
        }
    };

    if (error) {
        log.error('NavBarProvider encountered an error:', error);
        // Fallback UI in case of error
        return <div>Navigation Error: Please refresh the page</div>;
    }

    return (
        <NavBarContext.Provider value={{
            hideNavbar,
            setHideNavbar: handleSetHideNavbar,
            error
        }}>
            {children}
        </NavBarContext.Provider>
    );
};

export const useNavBar = () => useContext(NavBarContext);