import NotificationPage from 'components/common/Notifications/NotificationPage';
import ChatGod from "pages/Chat/ChatGod";
import SearchPage from "pages/Search/SearchPage";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "components/common/NavBar/NavBar";
import AccountDetail from "../pages/Account/AccountDetail";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Firms from "pages/Firms/Firms";
import Guides from "pages/Guides";
import Logs from "pages/Logs/Logs";
import MatterDetail from "../pages/Matter/MatterDetail";
import Monitor from "components/Monitor/Monitor";
import UserHistory from "pages/History/UserHistory";
import UserProfile from "pages/UserProfile";
import SongTitle from "../pages/Song/SongTitle";
import TaskManager from "../pages/Tasks/Tasks";
import UseDetail from "../pages/Use/UseDetail";
import LicenseDetail from "../pages/License/LicenseDetail";
import Attachments from "../pages/Attachments/Attachments";
import Approvals from "../pages/Approvals/Approvals";
import Detections from "../pages/Approvals/Detections";

// Move ProtectedRoute component here
const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    const location = useLocation();

    if (!token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    return children;
};

export const AuthRoutes = () => (
    <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Login />} />
    </Routes>
);

export const MainRoutes = () => (
    <NavBar>
        <Routes>
            {/* Authentication */}
            <Route path="/" element={<Login />} />

            {/* Core Features */}
            <Route path="/logs" element={<ProtectedRoute><Logs /></ProtectedRoute>} />
            <Route path="/guides" element={<ProtectedRoute><Guides /></ProtectedRoute>} />
            <Route path="/search" element={<ProtectedRoute><SearchPage /></ProtectedRoute>} />

            {/* Detail Pages */}
            <Route path="/matter/:id" element={<ProtectedRoute><MatterDetail /></ProtectedRoute>} />
            <Route path="/account/:id" element={<ProtectedRoute><AccountDetail /></ProtectedRoute>} />
            <Route path="/firm/:id" element={<ProtectedRoute><Firms /></ProtectedRoute>} />

            {/* User Related */}
            <Route path="/user-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/history" element={<ProtectedRoute><UserHistory /></ProtectedRoute>} />

            {/* System Pages */}
            <Route path="/system-monitor" element={<ProtectedRoute><Monitor /></ProtectedRoute>} />
            <Route path="/notifications" element={<NotificationPage />} />

            {/* Additional Core Features */}
            <Route path="/chat_god" element={<ProtectedRoute><ChatGod /></ProtectedRoute>} />
            <Route path="/tasks" element={<ProtectedRoute><TaskManager /></ProtectedRoute>} />
            <Route path="/attachments" element={<ProtectedRoute><Attachments /></ProtectedRoute>} />

            {/* Additional Detail Pages */}
            <Route path="/use/:id" element={<ProtectedRoute><UseDetail /></ProtectedRoute>} />
            <Route path="/song_title/:id" element={<ProtectedRoute><SongTitle /></ProtectedRoute>} />
            <Route path="/license_detail/:id" element={<ProtectedRoute><LicenseDetail /></ProtectedRoute>} />
            <Route path="/approvals" element={<ProtectedRoute><Approvals /></ProtectedRoute>} />
            <Route path="/approvals/:clientId" element={<ProtectedRoute><Approvals /></ProtectedRoute>} />
            <Route path="/approvals/detections/:clientId/:accountId" element={<ProtectedRoute><Detections /></ProtectedRoute>} />
            {/* Dashboard placeholder */}
            <Route path="/dashboard" />

            {/* Fallback */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </NavBar>
)

