import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Chip,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { ClickableTypography } from "components/common/Style/ClickableTypography";
import { formatPercentage, formatDate } from "utils/dataUtils";
import { useService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";

const UseDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const useId = location.state?.useId || id;

  const { use, isLoading, error } = useUseDetails(useId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!use) {
    return <Typography>No use details found.</Typography>;
  }

  const handleMatterClick = () => {
    if (use.opportunity_id) {
      navigate(`/matter/${use.opportunity_id}`);
    }
  };

  const handleTitleClick = () => {
    if (use.shazam_id) {
      navigate(`/song_title/${use.shazam_id}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container maxWidth={false} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: { xs: 2, sm: 3 }, px: { xs: 2, sm: 3 } }}>
        <HeaderCard use={use} onMatterClick={handleMatterClick} />
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <VideoCard use={use} />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <UseDetailsCard use={use} titleClick={handleTitleClick} />
            <VideoInfoCard use={use} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useUseDetails = (useId) => {
  const [use, setUse] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchUseDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await useService.getUseDetails(useId);
        setUse(response.data);
      } catch (err) {
        setError(err.response?.data?.detail || "Failed to fetch use details");
      } finally {
        setLoading(false);
      }
    };

    fetchUseDetails();
  }, [useId]);

  return { use, isLoading, error };
};

const ErrorMessage = ({ message }) => (
  <Typography color="error">{message}</Typography>
);

const HeaderCard = ({ use, onMatterClick }) => (
  <Card elevation={3} sx={{ p: 2, mb: 2 }}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <ClickableTypography variant="h5" onClick={onMatterClick}>
          {use.name}
        </ClickableTypography>
      </Grid>
      <Grid item>
        <Chip label={use.status || "Unopened"} color="primary" size="small" />
      </Grid>
    </Grid>
  </Card>
);

export const VideoCard = ({ use }) => (
  <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative', minHeight: { xs: '300px', sm: '400px', md: '500px' } }}>
      <CardMedia component="div" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <VideoPlayer src={`${use.archive_link}#t=${use.found_at}`} />
      </CardMedia>
    </Box>
    <CardContent sx={{ p: 2, flexShrink: 0 }}>
      <VideoInfo use={use} />
      <WatchButton service={use.service} url={use.page_url} />
    </CardContent>
  </Card>
);

export const VideoPlayer = ({ src, poster }) => (
  <Box
    component="video"
    src={src}
    poster={poster}
    controls
    sx={{
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    }}
  />
);

const VideoInfo = ({ use }) => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <img
        src={`http://logo.clearbit.com/${use.service}.com`}
        alt={`${use.service} logo`}
        style={{ width: 20, height: 20, marginRight: 8 }}
      />
      <Typography variant="subtitle1">
        {use.video_title || `No Title`}
      </Typography>
    </Box>
    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
      {use.channel} • {formatDate(use.date_uploaded)}
    </Typography>
  </>
);

const WatchButton = ({ service, url }) => (
  <Button
    variant="contained"
    startIcon={<PlayArrow />}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    size="small"
    fullWidth
  >
    Watch on {service}
  </Button>
);

const ClickableTableCell = styled(TableCell)(() => ({
  cursor: "pointer",
  color: "#1976d2",
  textDecoration: "underline",
  "&:hover": {
    textDecoration: "underline",
    color: "#1976d2",
  },
}));

const UseDetailsCard = ({ use, titleClick }) => (
  <Card elevation={3} sx={{ mb: 2, flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
    <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Use Details
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Song Title</TableCell>
            <ClickableTableCell align="right" onClick={titleClick}>
              {use.song_title}
            </ClickableTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell align="right">{use.artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Side</TableCell>
            <TableCell align="right">{use.side}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Share</TableCell>
            <TableCell align="right">
              {formatPercentage(use.ownership_info)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ISRC</TableCell>
            <TableCell align="right">{use.isrc || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ISWC</TableCell>
            <TableCell align="right">{use.iswc || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Track ID</TableCell>
            <TableCell align="right">{use.track_id || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date Modified</TableCell>
            <TableCell align="right">{formatDate(use.date_modified)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

const VideoInfoCard = ({ use }) => (
  <Card elevation={3} sx={{ flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
    <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Video Information
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Channel</TableCell>
            <TableCell align="right">{use.channel}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell align="right">{use.service}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date Uploaded</TableCell>
            <TableCell align="right">{formatDate(use.date_uploaded)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Video Screenshot Date</TableCell>
            <TableCell align="right">{formatDate(use.video_ss_date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Visual Screenshot Date</TableCell>
            <TableCell align="right">
              {formatDate(use.visual_ss_date)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default UseDetail;