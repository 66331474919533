// src/utils/debug-config.js
import debug from 'debug';

// Enable debug in development, disable in production
if (process.env.NODE_ENV === 'development') {
    localStorage.setItem('debug', 'app:*');
} else {
    localStorage.removeItem('debug');
    debug.disable();  // Explicitly disable debug in non-development environments
}

export const createLogger = (namespace) => {
    const baseLogger = debug(`app:${namespace}`);

    return {
        info: (...args) => baseLogger('[INFO]', ...args),
        error: (...args) => baseLogger('[ERROR]', ...args),
        warn: (...args) => baseLogger('[WARN]', ...args),
        debug: (...args) => baseLogger('[DEBUG]', ...args)
    };
};
