import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  Container,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { titleAndReplace } from "utils/dataUtils";
import { licenseService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  "&:first-of-type": {
    width: "30%",
  },
}));

const LicenseDetail = () => {
  const [licenseData, setLicenseData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const fetchLicenseDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await licenseService.getLicenseDetails(id);
        setLicenseData(response.data);
      } catch (err) {
        setError(
          err.response?.data?.detail || "Failed to fetch license details"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseDetails();
  }, [id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 5, width: "100%" }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  if (!licenseData) {
    return (
      <Box sx={{ p: 5, width: "100%" }}>
        <Typography variant="h6">No license details found.</Typography>
      </Box>
    );
  }

  const renderValue = (value) => {
    if (value === null || value === undefined || value === "") return null;
    if (typeof value === "boolean") {
      return (
        <Chip
          label={value ? "Yes" : "No"}
          color={value ? "success" : "error"}
          size="small"
        />
      );
    }
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  const renderTableRow = (label, value) => {
    const renderedValue = renderValue(value);
    if (renderedValue === null) return null;
    return (
      <TableRow key={label}>
        <StyledTableCell component="th" scope="row">
          <Typography variant="subtitle2">{label}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{renderedValue}</Typography>
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      <Container maxWidth={false}>
        <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs>
              <Typography variant="h4" gutterBottom>
                {licenseData.client}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {licenseData.category}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">Song Title</Typography>
              <Typography variant="body1">
                {licenseData.song_title || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">Artist</Typography>
              <Typography variant="body1">
                {licenseData.artist || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">Licensee</Typography>
              <Typography variant="body1">
                {licenseData.licensee || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">License Fee</Typography>
              <Typography variant="body1">
                {licenseData.license_fee || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>

        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              License Details
            </Typography>
            <Table>
              <TableBody sx={{ '& .MuiTableCell-root': { backgroundColor: 'background.paper' } }}>
                {renderTableRow("Title", licenseData.title)}
                {renderTableRow("Term Start", licenseData.term_start)}
                {renderTableRow("Term End", licenseData.term_end)}
                {renderTableRow("Media", licenseData.media)}
                {renderTableRow("Territory", licenseData.territory)}
                {renderTableRow("Song Code", licenseData.song_code)}
                {renderTableRow("Filename", licenseData.filename)}
                {licenseData.md &&
                  Object.entries(licenseData.md).map(([key, value]) =>
                    renderTableRow(titleAndReplace(key), value)
                  )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LicenseDetail;
