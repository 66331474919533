import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        // position: 'fixed', // Fixed position to cover the entire screen
        // top: 0,
        // left: 0,
        color: 'text.primary'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;