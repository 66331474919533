import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export const BackgroundContent = ({ currentIndex, totalGroups, onClose }) => (
    <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={2}
        zIndex={1001}
        sx={{
            pointerEvents: 'none',
            '& > *': {
                pointerEvents: 'auto'
            }
        }}
    >
        <Box display="flex" justifyContent="right" width="100%">

            <IconButton onClick={onClose} color='inherit' aria-label="close" sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}>
                <CloseIcon />
            </IconButton>

            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    m: 2,


                }}
            >
                <Typography variant="body2" color="text.secondary">
                    {currentIndex + 1} of {totalGroups}
                </Typography>
            </Box>
        </Box>
    </Box>
);

export const RepostInfo = ({ currentRepost }) => (
    <Box sx={{
        position: 'absolute',
        left: 0,
        top: '0%',
        width: '30%',
        textAlign: 'left',
        padding: 4,
    }}>
        <Typography variant="h4" gutterBottom>{currentRepost.song_title}</Typography>
        <Typography variant="h4" gutterBottom>{currentRepost.artist}</Typography>
        <Typography variant="h5" gutterBottom>Side: {currentRepost.side}</Typography>
    </Box>
);