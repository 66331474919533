import { Autocomplete, Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { TextBox } from '../../utils/TextBox';
import ViewAgreementButton from '../../utils/ViewAgreementButton';

const ReviewStep = memo(({
    title,
    subtitle,
    noValueTitle,
    noValueSubtitle,
    value,
    options,
    onChange,
    text,
    driveUrl,
    showCorrection,
    correctionType,
    correctionValue,
    onCorrectionChange,
    matterOptions,
    correctionOptions,
}) => {
    const theme = useTheme();

    const StyledBox = styled(Box)(({ theme }) => ({
        maxWidth: 800,
        margin: '0 auto',
        padding: theme.spacing(4),
        background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        backdropFilter: 'blur(10px)',
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[1]
    }));

    const GradientTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: theme.spacing(1)
    }));

    const displayTitle = subtitle?.includes('No') ? noValueTitle : title;
    const displaySubtitle = subtitle?.includes('No') ? noValueSubtitle : subtitle;

    return (
        <StyledBox>
            <Box sx={{ mb: 4, textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <GradientTypography variant="h5">
                        {displayTitle}
                    </GradientTypography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: alpha(theme.palette.text.primary, 0.7),
                            lineHeight: 1.5
                        }}
                    >
                        {displaySubtitle}
                    </Typography>
                </Box>
                {driveUrl && <ViewAgreementButton url={driveUrl} />}
            </Box>

            {text && (
                <TextBox text={text} />
            )}

            <FormControl required fullWidth>
                <RadioGroup
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    row
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 2,
                        width: '100%',
                        maxWidth: '400px',
                        mx: 'auto'
                    }}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                                <Radio
                                    sx={{ display: 'none' }}
                                />
                            }
                            label={
                                <Box
                                    sx={{
                                        width: '100%',
                                        py: 2,
                                        px: 4,
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease',
                                        background: value === option.value
                                            ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                                            : alpha(theme.palette.background.default, 0.03),
                                        border: `1px solid ${value === option.value
                                            ? theme.palette.primary.main
                                            : theme.palette.divider}`,
                                        boxShadow: value === option.value
                                            ? `0 4px 20px ${alpha(theme.palette.primary.main, 0.25)}`
                                            : 'none',
                                        '&:hover': {
                                            background: value === option.value
                                                ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                                                : alpha(theme.palette.background.default, 0.08),
                                            transform: 'translateY(-2px)',
                                            boxShadow: value === option.value
                                                ? `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                                                : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 500,
                                            color: value === option.value
                                                ? theme.palette.primary.contrastText
                                                : theme.palette.text.primary
                                        }}
                                    >
                                        {option.label}
                                    </Typography>
                                </Box>
                            }
                            sx={{
                                margin: 0,
                                width: '100%'
                            }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {showCorrection && (
                <Box sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: '12px',
                    background: alpha(theme.palette.background.default, 0.03),
                    backdropFilter: 'blur(5px)',
                    border: `1px solid ${theme.palette.divider}`
                }}>
                    <Typography
                        variant="body2"
                        sx={{
                            mb: 2,
                            color: alpha(theme.palette.text.primary, 0.87)
                        }}
                    >
                        Please enter the correct value:
                    </Typography>
                    {correctionType === 'select' && (
                        <FormControl fullWidth size="small">
                            <Select
                                value={correctionValue}
                                onChange={(e) => onCorrectionChange(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select agreement type</MenuItem>
                                {correctionOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {correctionType === 'matter' && (
                        <Autocomplete
                            options={matterOptions}
                            getOptionLabel={(option) => option?.name || ''}
                            value={correctionValue}
                            onChange={(_, newValue) => onCorrectionChange(newValue)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            filterOptions={(options, params) => {
                                const { inputValue } = params;
                                const searchTerms = inputValue.toLowerCase().trim().split(/\s+/);

                                // First filter by search terms
                                const filtered = options.filter(option => {
                                    const optionName = option.name.toLowerCase();
                                    return searchTerms.every(term => optionName.includes(term));
                                });

                                // Then remove duplicates based on name
                                const uniqueFiltered = filtered.filter((option, index, self) =>
                                    index === self.findIndex((o) => o.name === option.name)
                                );

                                return uniqueFiltered;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    placeholder="Search for matter"
                                    size="small"
                                />
                            )}
                            noOptionsText="No matters found"
                        />
                    )}
                    {correctionType === 'text' && (
                        <TextField
                            fullWidth
                            value={correctionValue}
                            onChange={(e) => onCorrectionChange(e.target.value)}
                            placeholder="Enter correct value"
                        />
                    )}
                    {correctionType === 'boolean' && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={correctionValue}
                                    onChange={(e) => onCorrectionChange(e.target.checked)}
                                />
                            }
                            label="Correct Value"
                        />
                    )}
                    {correctionType === 'date' && (
                        <DatePicker
                            value={correctionValue}
                            onChange={(newValue) => {
                                if (newValue) {
                                    const dateOnly = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate());
                                    onCorrectionChange(dateOnly);
                                } else {
                                    onCorrectionChange(null);
                                }
                            }}
                            slotProps={{ textField: { fullWidth: false } }}
                        />
                    )}
                </Box>
            )}
        </StyledBox>
    );
});


ReviewStep.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    noValueTitle: PropTypes.string.isRequired,
    noValueSubtitle: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    driveUrl: PropTypes.string,
    showCorrection: PropTypes.bool,
    correctionType: PropTypes.oneOf(['text', 'boolean', 'date', 'select', 'matter']),
    correctionValue: PropTypes.any,
    onCorrectionChange: PropTypes.func,
    matterOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    correctionOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })),
};

ReviewStep.displayName = 'ReviewStep';

export default ReviewStep;