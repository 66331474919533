
export const parsePrometheusText = (text) => {
    const metrics = {};
    const lines = text.split('\n');
    let currentMetric = null;

    lines.forEach(line => {
        if (line.startsWith('# HELP')) {
            const [, name, help] = line.match(/# HELP\s+(\S+)\s+(.+)/);
            metrics[name] = { help, type: null, values: {} };
            currentMetric = name;
        } else if (line.startsWith('# TYPE')) {
            const [, name, type] = line.match(/# TYPE\s+(\S+)\s+(\S+)/);
            if (metrics[name]) {
                metrics[name].type = type;
            }
        } else if (line && !line.startsWith('#')) {
            const [name, value] = line.split(' ');
            const labels = name.match(/{(.+)}/);
            if (labels) {
                const baseName = name.split('{')[0];
                const labelObj = {};
                labels[1].split(',').forEach(label => {
                    const [key, val] = label.split('=');
                    labelObj[key] = val.replace(/"/g, '');
                });
                if (!metrics[baseName].values[JSON.stringify(labelObj)]) {
                    metrics[baseName].values[JSON.stringify(labelObj)] = [];
                }
                metrics[baseName].values[JSON.stringify(labelObj)].push(parseFloat(value));
            } else {
                if (!metrics[name].values['']) {
                    metrics[name].values[''] = [];
                }
                metrics[name].values[''].push(parseFloat(value));
            }
        }
    });

    return metrics;
};