import React from 'react';
import { IconButton, Badge, Menu } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotifications } from 'components/common/Notifications/NotificationContext';
import { useNavigate } from 'react-router-dom';
import NotificationList from 'components/common/Notifications/NotificationList';
import NotificationActions from 'components/common/Notifications/NotificationActions';

const Notifications = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { notifications, clearNotifications } = useNotifications();

    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const handleClearNotifications = () => {
        clearNotifications();
        handleNotificationClose();
    };

    const handleViewAllNotifications = () => {
        navigate('/notifications');
        handleNotificationClose();
    };

    return (
        <>
            <IconButton
                sx={{ ml: 2, color: "inherit" }}
                onClick={handleNotificationClick}
                aria-controls="notification-menu"
                aria-haspopup="true"
            >
                <Badge badgeContent={notifications.length} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleNotificationClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        style: {
                            width: '350px',
                        },
                    },
                }}
            >
                <NotificationList notifications={notifications} onClose={handleNotificationClose} />
                <NotificationActions
                    onClearAll={handleClearNotifications}
                    onViewAll={handleViewAllNotifications}
                />
            </Menu>
        </>
    );
};

export default Notifications;
