import {
    TYPE_ICONS
} from './styles';
import { differenceInDays } from 'date-fns';
import { titleAndReplace } from 'utils/dataUtils';

const driveUrl = (driveId) => `https://drive.google.com/file/d/${driveId}`;

const displayName = (type) => {
    return titleAndReplace(type);
};

const createDefaultItem = (item) => ({
    id: item.id,
    type: item.feed,
    displayType: displayName(item.feed),
    matter_name: `${item.matter} - ${item.client}`,
    title: `${item.matter} - ${item.client}`,
    priority: 'medium',
    time: new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    }),
    icon: TYPE_ICONS[item.feed] || TYPE_ICONS.default,
    matter: item.matter,
    client: item.client,
    meta: item.meta,
    matter_id: item.meta.matter_id,
    complete: item.complete,
    completed_date: item.completed_date,
    completed_by: item.completed_by,
});

const createTollsExpiringItem = (item) => {
    const expiryDate = new Date(item.meta.expiration_date);
    const effectiveDate = new Date(item.meta.effective_date);
    const daysLeft = differenceInDays(expiryDate, new Date());

    return {
        ...createDefaultItem(item),
        description: `Agreement Expires in ${daysLeft} days`,
        daysLeft: daysLeft,
        expiryDate: item.meta.expiration_date,
        effectiveDate: item.meta.effective_date,
        category: item.meta.category,
        fileId: item.meta.file_id,
        url: driveUrl(item.meta.drive_id) || null
    };
};

const createFollowupItem = (item) => {
    const daysText = item.meta.days === 1 ? 'day' : 'days';
    return {
        ...createDefaultItem(item),
        description: `Followup`,
        lastCommDate: item.meta.last_comm_date,
        commType: item.meta.comm_type,
        textValue: item.meta.text_value,
        daysAgo: item.meta.days,
        url: driveUrl(item.meta.drive_id) || null,
    };
};

const createAgreementItem = (item) => ({
    ...createDefaultItem(item),
    description: `New ${item.meta.category} agreement for review`,
    file_id: item.meta.file_id,
    effective_date: item.meta.md.effective_date,
    effective_date_span: item.meta.md.effective_date_span,
    expiration_date: item.meta.md.expiration_date,
    expiration_date_span: item.meta.md.expiration_date_span,
    payment_due: item.meta.md.payment_due,
    payment_due_span: item.meta.md.payment_due_span,
    filename: item.meta.filename,
    md: item.meta.md,
    file_md: item.meta.md.file_md,
    category: item.meta.category,
    url: driveUrl(item.meta.drive_id) || null
    // Add specific fields once we see the structure for agreement items
});

const createFeedItems = {
    default: createDefaultItem,
    followup: createFollowupItem,
    toll_expiring: createTollsExpiringItem,
    agreement_review: createAgreementItem
};

export const createDayItems = (feedData, targetDate) => {
    console.log('createDayItems called with:', { feedData, targetDate });

    if (!feedData || !Array.isArray(feedData)) {
        console.warn('Invalid feedData structure:', feedData);
        return [];
    }

    try {
        // Process all items and add the target date
        const items = feedData.map(item => {
            console.log('Processing item:', item);
            // Use the appropriate creator function based on the feed type
            const createItem = createFeedItems[item.feed] || createFeedItems.default;
            const processedItem = {
                ...createItem(item),
                date: targetDate
            };
            console.log('Created item:', processedItem);
            return processedItem;
        });

        console.log('Returning processed items:', items);
        return items;
    } catch (err) {
        console.error('Error in createDayItems:', err);
        throw new Error(`Failed to create day items: ${err.message}`);
    }
};