import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import InfoIcon from '@mui/icons-material/Info';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Button, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { DraggableVideo } from './DraggableVideo';

export const VideoGridGroup = ({
    groupId,
    reposts,
    videoPlayer,
    urlField,
    autoplayGroup,
    onAutoplayToggle,
    isNewGroupArea = false,
}) => {
    const [isMuted, setIsMuted] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);

    React.useEffect(() => {
        if (autoplayGroup === groupId) {
            setIsMuted(true);
        }
    }, [autoplayGroup, groupId]);

    const handleAutoplayToggle = () => {
        if (autoplayGroup !== groupId) {
            setIsMuted(true);
        } else {
            setIsMuted(false);
        }
        onAutoplayToggle(groupId);
    };

    const { setNodeRef, isOver } = useDroppable({
        id: groupId,
        data: {
            type: 'group',
            groupId: groupId
        }
    });

    return (
        <Grid item xs={12}>
            <Box
                ref={setNodeRef}
                sx={{
                    mb: 3,
                    backgroundColor: isNewGroupArea ? 'action.hover' : 'background.paper',
                    borderRadius: '16px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden',
                    border: isOver || isNewGroupArea ? '2px dashed' : 'none',
                    borderColor: 'primary.main',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: isOver ? 'scale(1.02)' : 'scale(1)',
                    opacity: isOver ? 0.95 : 1,
                    minHeight: isNewGroupArea ? '200px' : 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 24px rgba(0, 0, 0, 0.12)'
                    }
                }}
            >
                {!isNewGroupArea ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                px: 3,
                                py: 2,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                // backgroundColor: groupId > 0 ? 'primary.light' : 'background.default',
                                backgroundColor: groupId > 0 ? 'primary.main' : 'primary.contrastText',

                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                gap: 2
                            }}>
                                <Button
                                    startIcon={<PlayArrowIcon />}
                                    variant="outlined"

                                    sx={{
                                        borderRadius: '12px',
                                        textTransform: 'none',
                                        px: 3,
                                        py: 1,
                                        boxShadow: 'none',
                                        color: groupId > 0 ? 'text.contrastText' : 'text.primary',
                                        // color: groupId > 0 ? 'primary.contrastText' : 'primary.contrastText',
                                        '&:hover': {
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                                        }
                                    }}
                                    onClick={handleAutoplayToggle}
                                >
                                    {autoplayGroup === groupId ? 'Pause All' : 'Play Together'}
                                </Button>

                                <IconButton
                                    onClick={() => setIsMuted(!isMuted)}
                                    sx={{
                                        borderRadius: '50%',
                                        width: 48,
                                        height: 48,
                                        // backgroundColor: groupId > 0 ? 'primary.light' : 'primary.main',
                                        color: groupId > 0 ? 'text.contrastText' : 'text.primary',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                                        }
                                    }}
                                >
                                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                </IconButton>

                                <IconButton
                                    onClick={() => setShowDetails(!showDetails)}
                                    sx={{
                                        borderRadius: '50%',
                                        width: 48,
                                        height: 48,
                                        color: groupId > 0 ? 'text.contrastText' : 'text.primary',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                                        }
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    color: groupId > 0 ? 'text.contrastText' : 'text.secondary'
                                }}
                            >
                                {groupId > 0 ? `Repost Group ${groupId}` : "Unique Uses"}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 3 }}>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-start"
                                sx={{
                                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                }}
                            >
                                <SortableContext
                                    items={reposts.map(r => r.id)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {reposts.map((repost) => (
                                        <DraggableVideo
                                            key={repost.id}
                                            repost={repost}
                                            videoPlayer={videoPlayer}
                                            urlField={urlField}
                                            autoplay={autoplayGroup === groupId}
                                            isDragging={false}
                                            isMuted={isMuted}
                                            showDetails={showDetails}
                                        />
                                    ))}
                                </SortableContext>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '200px',
                        width: '100%'
                    }}>
                        <Typography variant="h6" color="text.secondary" textAlign="center">
                            Drop here to create new group
                        </Typography>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};