import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/system";

const MessageBubble = styled(Paper)(({ theme, isFromKlg }) => ({
  padding: theme.spacing(1.5),
  borderRadius: 12,
  maxWidth: "70%",
  display: "inline-flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
  backgroundColor: isFromKlg ? theme.palette.primary.light : theme.palette.background.paper,
  color: isFromKlg ? theme.palette.primary.contrastText : theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  alignSelf: isFromKlg ? "flex-end" : "flex-start",
  position: "relative",
}));

const MessageContainer = styled(Box)(({ isFromKlg }) => ({
  display: "flex",
  justifyContent: isFromKlg ? "flex-end" : "flex-start",
  width: "100%",
  padding: "0 24px",
}));

const EmailBubble = ({ email, isFromKlg }) => {
  return (
    <MessageContainer isFromKlg={isFromKlg}>
      <MessageBubble isFromKlg={isFromKlg}>
        <Typography
          variant="body1"
          component="div"
          sx={{ marginBottom: 1 }}
          dangerouslySetInnerHTML={{ __html: email.message }}
        />
        <Typography
          variant="caption"
          component="div"
          sx={{
            textAlign: "right",
            color: (theme) => isFromKlg ? theme.palette.primary.contrastText : theme.palette.text.secondary,
          }}
        >
          {email.email_from}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{
            textAlign: "right",
            color: (theme) => isFromKlg ? theme.palette.primary.contrastText : theme.palette.text.secondary,
          }}
        >
          {new Date(email.email_date).toLocaleString(undefined, {
            dateStyle: 'medium',
            timeStyle: 'medium'
          })}
        </Typography>
      </MessageBubble>
    </MessageContainer>
  );
};

export default EmailBubble;
