import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
    Box,
    Paper,
    Typography,
    Grid,
    Card,
    CardContent,
    Avatar,
    Container,
    Link,
    Stack,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingSpinner from "utils/LoadingSpinner";
import { firmService } from "services/api"; // You'll need to create this service
import { DataTableGrouped } from "components/common/DataTable";
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const MATTER_COLUMNS = ['status', 'client', 'matter', 'assigned_to', 'stage', 'current_offer', 'counteroffer', 'settlement_amount'];

const EmployeeCards = ({ employees }) => (
    <Box sx={{ width: '100%' }}>
        <Stack
            direction="row"
            spacing={2}
            sx={{
                overflowX: 'auto',
                pb: 2
            }}
        >
            {employees.map((employee) => (
                <Card
                    key={employee.id}
                    variant="outlined"
                    sx={{
                        minWidth: '300px',
                        '&:hover': {
                            boxShadow: 3,
                            transform: 'translateY(-2px)',
                            transition: 'all 0.2s ease-in-out'
                        },
                    }}
                >
                    <CardContent sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        p: 2
                    }}>
                        <Avatar
                            src={employee.headshot}
                            alt={employee.name}
                            sx={{
                                width: 60,
                                height: 60,
                                border: '2px solid',
                                borderColor: 'primary.light',
                            }}
                        >
                            {employee.name.charAt(0)}
                        </Avatar>
                        <Box>
                            <Typography variant="h6">
                                {employee.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {employee.title}
                            </Typography>
                            {employee.email && (
                                <Typography variant="body2" color="text.secondary">
                                    {employee.email}
                                </Typography>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Stack>
    </Box>
);


export const TopPageGrid = ({ firm }) => (
    <Grid container spacing={3} justifyContent="center" sx={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Typography variant="subtitle2">Open Matters</Typography>
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 500 }}>
                {firm.number_of_open_matters || 0}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Typography variant="subtitle2">Settled Matters</Typography>
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 500 }}>
                {firm.number_of_settled_matters || 0}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Typography variant="subtitle2">Closed Matters</Typography>
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 500 }}>
                {firm.number_of_closed_matters || 0}
            </Typography>
        </Grid>
    </Grid>
);

const FirmDetailsCards = ({ firm }) => {
    // Helper function to check if firm has any information fields
    const hasFirmInfo = firm.hq || firm.employees;

    // Helper function to check if firm has any social links
    const hasLinks = firm.website || firm.linkedin || firm.twitter || firm.facebook;

    return (
        <>
            {firm.description && (
                <StyledPaper elevation={1} sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        About
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {firm.full_description || firm.description}
                    </Typography>
                </StyledPaper>
            )}

            <Grid container spacing={3} sx={{ mb: 4 }}>
                {hasFirmInfo && (
                    <Grid item xs={12} md={hasLinks ? 6 : 12}>
                        <Card variant="outlined" sx={{ mb: 4 }}>
                            <CardContent sx={{ pb: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Firm Information
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        {firm.hq && (
                                            <TableRow>
                                                <TableCell>Headquarters</TableCell>
                                                <TableCell align="right">{firm.hq}</TableCell>
                                            </TableRow>
                                        )}
                                        {firm.employees && (
                                            <TableRow>
                                                <TableCell>Number of Employees</TableCell>
                                                <TableCell align="right">{firm.employees}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {hasLinks && (
                    <Grid item xs={12} md={hasFirmInfo ? 6 : 12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Links
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        {firm.website && (
                                            <TableRow>
                                                <TableCell>Website</TableCell>
                                                <TableCell align="right">
                                                    <Link href={firm.website} target="_blank" rel="noopener noreferrer">
                                                        {firm.website}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {firm.linkedin && (
                                            <TableRow>
                                                <TableCell>LinkedIn</TableCell>
                                                <TableCell align="right">
                                                    <Link href={firm.linkedin} target="_blank" rel="noopener noreferrer">
                                                        {firm.linkedin}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {firm.twitter && (
                                            <TableRow>
                                                <TableCell>Twitter</TableCell>
                                                <TableCell align="right">
                                                    <Link href={firm.twitter} target="_blank" rel="noopener noreferrer">
                                                        {firm.twitter}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {firm.facebook && (
                                            <TableRow>
                                                <TableCell>Facebook</TableCell>
                                                <TableCell align="right">
                                                    <Link href={firm.facebook} target="_blank" rel="noopener noreferrer">
                                                        {firm.facebook}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const Firms = () => {
    const [firm, setFirm] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [matters, setMatters] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    const { id } = useParams();
    const location = useLocation();
    const firmId = location.state?.firmId || id;

    useEffect(() => {
        const fetchFirmDetails = async () => {
            setLoading(true);
            setError(null);
            try {
                console.log("Fetching firm details for ID:", firmId);
                const response = await firmService.getFirm(firmId);
                setFirm(response.data.firm);
                setEmployees(response.data.employees);
                setMatters(response.data.matters);
            } catch (err) {
                console.error("Error fetching firm details:", err);
                setError(err.response?.data?.detail || "An error occurred while fetching data");
            } finally {
                setLoading(false);
            }
        };

        if (firmId) {
            fetchFirmDetails();
        }
    }, [firmId]);


    const handleRowClick = (dataRow) => {
        console.log("Row Clicked", dataRow.row);
        if (dataRow.row.id) {
            navigate(`/matter/${dataRow.row.id}`);
        }
    };

    if (isLoading) return <LoadingSpinner />;
    if (error) return <Typography color="error">Error: {error}</Typography>;
    if (!firm) return <Typography>No firm details found.</Typography>;

    return (
        <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <Container maxWidth={false}>
                <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <Avatar
                                src={firm.logo}
                                alt={firm.name}
                                sx={{ width: 80, height: 80 }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h4" gutterBottom>
                                {firm.display_name || firm.name}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                                <Link href={`https://${firm.domain}`} target="_blank" rel="noopener noreferrer">
                                    {firm.domain}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 3 }} />

                    <TopPageGrid firm={firm} />
                </StyledPaper>

                <FirmDetailsCards firm={firm} />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom sx={{ pl: 1 }}>
                            Employees
                        </Typography>
                        <EmployeeCards employees={employees} />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom sx={{ pl: 1 }}>
                        Related Matters
                    </Typography>
                    {matters && matters.length > 0 ? (
                        <StyledPaper elevation={1}>
                            <DataTableGrouped
                                data={matters}
                                columns={MATTER_COLUMNS}
                                onRowClick={handleRowClick}
                                groupBy={['status']}
                                groupByLabel="Status"
                            />
                        </StyledPaper>
                    ) : (
                        !isLoading && (
                            <StyledPaper elevation={1}>
                                <Typography variant="body1" color="text.secondary">
                                    No Related Matters
                                </Typography>
                            </StyledPaper>
                        )
                    )}
                    {isLoading && <LoadingSpinner />}
                </Box>
            </Container>
        </Box>
    );
};

export default Firms;
