import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const NotificationIcon = ({ type }) => {
    switch (type) {
        case 'error': return <ErrorIcon color="error" />;
        case 'warning': return <WarningIcon color="warning" />;
        case 'success': return <CheckCircleIcon color="success" />;
        case 'info': return <InfoIcon color="info" />;
        default: return <InfoIcon color="info" />;
    }
};

export default NotificationIcon;