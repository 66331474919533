import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Select,
  MenuItem,
  Paper,

} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";

const getInputBarStyles = (theme) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  borderRadius: "25px",
  margin: "auto",
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create(["background-color", "box-shadow"]),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:focus-within": {
    boxShadow: `
      inset 0 5px 5px -5px rgba(0, 0, 0, 0.15),
      inset 0 -5px 5px -5px rgba(0, 0, 0, 0.05),
      inset 5px 0 5px -5px rgba(0, 0, 0, 0.1),
      inset -5px 0 5px -5px rgba(0, 0, 0, 0.1),
      0 0 0 2px ${theme.palette.primary.main}
    `,
  },
});

const InputBar = ({
  onSubmit,
  placeholder = "Type here...",
  width = "100%",
  value: externalValue,
  onChange: externalOnChange,
  dropdownOptions = null,
  onDropdownChange = null,
  mode = "chat", // 'chat' or 'search'
  elevation = 1, // New prop for elevation
  autoFocus = false, // New prop to control auto-focus behavior
}) => {
  const [internalValue, setInternalValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState(
    dropdownOptions ? dropdownOptions[0].value : ""
  );
  const [dropdownWidth, setDropdownWidth] = useState(100);
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const isControlled = externalValue !== undefined;
  const value = isControlled ? externalValue : internalValue;

  useEffect(() => {
    if (selectRef.current && dropdownOptions) {
      const selected = dropdownOptions.find(
        (option) => option.value === dropdownValue
      );
      const selectedText = selected ? selected.label : "";
      const textWidth = getTextWidth(
        selectedText,
        getComputedStyle(selectRef.current).font
      );
      setDropdownWidth(Math.max(textWidth + 40, 100)); // Add padding and set minimum width
    }
  }, [dropdownValue, dropdownOptions]);

  const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  };

  const handleChange = (event) => {
    if (!isControlled) {
      setInternalValue(event.target.value);
    }
    if (externalOnChange) {
      externalOnChange(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
      if (isMobileDevice()) {
        event.target.blur();
      }
    }
  };

  const handleClear = () => {
    if (!isControlled) {
      setInternalValue("");
    }
    if (externalOnChange) {
      externalOnChange("");
    }
    onSubmit("", dropdownValue);
  };

  const handleSubmit = () => {
    onSubmit(value, dropdownValue);
    if (!isControlled) {
      setInternalValue("");
    }
    if (isMobileDevice()) {
      inputRef.current.blur();
    }
  };

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setDropdownValue(newValue);
    if (onDropdownChange) {
      onDropdownChange(newValue);
    }
  };

  const renderLeftAdornment = () => {
    if (dropdownOptions) {
      return (
        <Select
          ref={selectRef}
          value={dropdownValue}
          onChange={handleDropdownChange}
          sx={{
            width: `${dropdownWidth}px`,
            // center aligned
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-select": {
            },
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      );
    }
    return mode === "search" ? (
      <IconButton onClick={handleSubmit} size="small" sx={{ ml: 1 }}>
        <SearchIcon color="action" sx={{ color: "primry.main" }} />
      </IconButton>
    ) : null;
  };

  const renderRightAdornment = () => (
    <InputAdornment position="end">
      {value && (
        <IconButton onClick={handleClear} edge="end" size="small">
          <ClearIcon sx={{ color: "primary.main" }} />
        </IconButton>
      )}
      {mode === "chat" && (
        <IconButton onClick={handleSubmit} edge="end" size="small">
          <SendIcon sx={{ color: "primary.main" }} />
        </IconButton>
      )}
    </InputAdornment>
  );

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      // Delay focus to ensure it works after component mount
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [autoFocus]);

  return (
    <Paper
      elevation={elevation}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        borderRadius: "25px", // Ensure the Paper component has rounded corners
        alignItems: "center", // Ensure vertical centering within Paper
      }}
    >
      <Box sx={(theme) => ({
        ...getInputBarStyles(theme), width,
        height: "100%", // Take full height of Paper
        px: 2,
      })}>
        {renderLeftAdornment()}
        <TextField
          fullWidth
          variant="standard"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          data-form-type="other"
          placeholder={placeholder}
          inputRef={inputRef}
          autoFocus={autoFocus}
          InputProps={{
            disableUnderline: true,
            endAdornment: renderRightAdornment(),
          }}
          sx={{
            "& .MuiInputBase-root": {
              padding: "5px 14px",
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default InputBar;
