import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Snackbar,
    Alert,
} from "@mui/material";
import { authService } from "services/api";
import { styled } from "@mui/material/styles";
import { useTheme } from "themes/Palette";
import { createLogger } from 'utils/debug-config';

const log = createLogger('TaskManagement');

// ... reuse the styled components from ForgotPassword ...
const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
        },
    },
    '& .MuiInputLabel-root': {
        padding: theme.spacing(0, 0),
        '&.Mui-focused': {
            color: theme.palette.primary.main,
        },
    },
    marginBottom: theme.spacing(0),
    '& input:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset`,
        '-webkit-text-fill-color': theme.palette.text.primary,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.7,
        color: theme.palette.common.white,
    },
}));

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [isValidToken, setIsValidToken] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { logo } = useTheme();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        const urlParams = new URLSearchParams(window.location.search);
        const resetToken = urlParams.get('token');
        if (resetToken) {
            setToken(resetToken);
            verifyToken(resetToken);
        } else {
            setError('Invalid reset link');
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const verifyToken = async (token) => {
        try {
            if (!token || token.split('.').length !== 3) {
                throw new Error('Invalid reset link format');
            }

            const response = await fetch('/api/auth/verify-reset-token', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            log.info('Response status:', response.status);
            log.info('Parsed data:', data);

            if (!response.ok) {
                throw new Error(data.detail || 'Token verification failed');
            }

            if (!data.valid) {
                throw new Error('Invalid or expired reset link');
            }

            setEmail(data.email);
            setIsValidToken(true);
        } catch (error) {
            setError('Invalid or expired reset link');
            setTimeout(() => navigate("/forgot-password"), 3000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            setLoading(false);
            return;
        }

        try {
            const response = await authService.updatePassword(token, password);
            setMessage(response.data.message || "Password successfully reset");
            setTimeout(() => navigate("/login"), 2000);
        } catch (err) {
            const errorMessage = err.response?.data?.detail ||
                err.response?.data?.message ||
                err.message ||
                "An error occurred while resetting password";
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "background.default",
                px: { xs: 2, sm: 4 },
            }}
        >
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        p: { xs: 3, sm: 4, md: 6 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: 450,
                        width: "100%",
                        borderRadius: "15px",
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <img
                        src={logo}
                        alt="Law Firm Logo"
                        style={{ width: '100%', maxWidth: 120, marginBottom: 30 }}
                    />
                    <Typography variant="h6" component="h1" gutterBottom>
                        Reset Password
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3, textAlign: 'center' }}>
                        Enter your new password below.
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: "100%" }}>
                        <StyledTextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <StyledTextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <StyledButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                py: { xs: 1.5, sm: 1.5 },
                                bgcolor: "primary.main",
                                '&:hover': {
                                    bgcolor: "primary.dark"
                                },
                                height: 48,
                            }}
                            disabled={loading}
                        >
                            {loading ? "Resetting..." : "Reset Password"}
                        </StyledButton>
                        <Button
                            fullWidth
                            onClick={() => navigate("/login")}
                            sx={{ mt: 1, textTransform: 'none' }}
                        >
                            Back to Login
                        </Button>
                    </Box>
                </Paper>
            </motion.div>

            <Snackbar
                open={!!error || !!message}
                autoHideDuration={6000}
                onClose={() => {
                    setError("");
                    setMessage("");
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => {
                        setError("");
                        setMessage("");
                    }}
                    severity={error ? "error" : "success"}
                    elevation={6}
                    variant="filled"
                >
                    {error || message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ResetPassword;