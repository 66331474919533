import { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTimelineContext } from '../TimelineContext';

const CompleteButton = ({ text, onClick, disabled, loading }) => {
    const theme = useTheme();
    return <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Button
            variant="contained"
            onClick={onClick}
            disabled={disabled || loading}
            sx={{
                background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
                minWidth: 120,
                position: 'relative',
            }}
        >
            {loading ? (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        color: theme.palette.primary.light,
                    }}
                />
            ) : text}
        </Button>
    </Box>
};

const CompleteTaskButton = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { handleComplete } = useTimelineContext();

    const handleClick = async () => {
        if (!id || isLoading) return;

        setIsLoading(true);
        try {
            await handleComplete(id);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CompleteButton
            text="Complete"
            onClick={handleClick}
            disabled={!id}
            loading={isLoading}
        />
    );
};

export { CompleteButton, CompleteTaskButton };