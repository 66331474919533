import { styled, alpha } from '@mui/material';
import { Box, Typography, Paper } from '@mui/material';
import {
    NotificationsOutlined as BellIcon,
    AccessTimeOutlined as ClockIcon,
    GroupsOutlined as UsersIcon,
    DescriptionOutlined as FileIcon
} from '@mui/icons-material';

export const PageContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
}));

export const TimelineCard = styled(Box)(({ theme, priority, selected }) => ({
    backgroundColor: selected
        ? alpha(theme.palette.background.paper, 0.1)
        : 'transparent',


    transition: 'all 0.2s ease-in-out',
    padding: theme.spacing(2.5),
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    position: 'relative',
    paddingLeft: theme.spacing(2.5),
    borderLeft: `2px solid ${priority === 'High'
        ? alpha(theme.palette.error.main, 0.7)
        : priority === 'Medium'
            ? alpha(theme.palette.warning.main, 0.5)
            : alpha(theme.palette.primary.main, 0.3)
        }`,
    '&:hover': {
        backgroundColor: alpha(theme.palette.background.paper, 0.05),
        transform: 'translateX(4px)',
        bgcolor: alpha(theme.palette.primary.main, 0.04),
    },
    ...(selected && {
        borderColor: theme.palette.primary.main,
        bgcolor: alpha(theme.palette.primary.main, 0.08),
        bgcolor: alpha(theme.palette.primary.main, 0.08),
    })
}));

export const IconContainer = styled(Box)(({ theme }) => ({
    color: alpha(theme.palette.secondary.main, 0.7),
    '& svg': {
        fontSize: '1.1rem'
    }
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.7),
    fontSize: '0.95rem',
    lineHeight: 1.5
}));

export const DashboardLayout = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '400px 1fr', // Fixed width for timeline, remaining space for detail
    gap: theme.spacing(3),
    height: '100vh',
    maxWidth: '100%',
    margin: '0 auto'
}));

export const DetailPane = styled(Box)(({ theme }) => ({
    background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
    borderRadius: theme.spacing(2),
    height: '100%',
    transition: 'all 0.3s ease-in-out',
    backdropFilter: 'blur(10px)',
    border: `0px solid ${alpha(theme.palette.common.white, 0.1)}`,
}));

export const TYPE_ICONS = {
    tolls_expiring: ClockIcon,
    agreement: FileIcon,
    followup: UsersIcon,
    // Add any other types here
    default: BellIcon
};