import { EventEmitter } from 'events';

const DEFAULT_OPTIONS = {
    reconnectAttempts: 5,
    reconnectDelay: 3000,
    connectionTimeout: 100000,
    debug: true
};

class WebSocketConnection extends EventEmitter {
    constructor(url, options = {}) {
        super();
        this.url = url;
        this.options = { ...DEFAULT_OPTIONS, ...options };
        this.socket = null;
        this.state = {
            isConnecting: false,
            reconnectAttempts: 0,
            reconnectTimeout: null,
            connectionTimeoutId: null
        };
    }

    connect() {
        if (this.state.isConnecting) {
            this.log('Connection attempt already in progress');
            return;
        }

        if (!this.validateUrl()) {
            const error = new Error('Invalid WebSocket URL');
            this.handleError(error);
            return;
        }

        this.establishConnection();
    }

    validateUrl() {
        return this.url && (this.url.startsWith('ws://') || this.url.startsWith('wss://'));
    }

    establishConnection() {
        this.state.isConnecting = true;
        this.log(`Connecting to ${this.url}`);

        try {
            this.socket = new WebSocket(this.url);
            this.setupConnectionTimeout();
            this.attachEventListeners();
        } catch (error) {
            this.handleError(error);
        }
    }

    setupConnectionTimeout() {
        if (this.state.connectionTimeoutId) {
            clearTimeout(this.state.connectionTimeoutId);
        }

        this.state.connectionTimeoutId = setTimeout(() => {
            if (this.socket?.readyState !== WebSocket.OPEN) {
                this.log('Connection timeout, attempting reconnect');
                this.socket?.close();
                this.attemptReconnection();
            }
        }, this.options.connectionTimeout);
    }

    attachEventListeners() {
        this.socket.onopen = () => {
            clearTimeout(this.state.connectionTimeoutId);
            this.state = {
                ...this.state,
                isConnecting: false,
                reconnectAttempts: 0
            };
            this.log('Connection established');
            this.emit('open');
        };

        this.socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                this.emit('message', data);
            } catch (error) {
                this.handleError(new Error('Message parsing failed'));
            }
        };

        this.socket.onerror = () => {
            this.state.isConnecting = false;
            this.attemptReconnection();
        };

        this.socket.onclose = (event) => {
            this.state.isConnecting = false;
            this.emit('close', event);

            if (event.code !== 1000 && !this.state.isConnecting) {
                this.attemptReconnection();
            }
        };
    }

    attemptReconnection() {
        if (this.state.reconnectAttempts >= this.options.reconnectAttempts) {
            this.handleError(new Error('Maximum reconnection attempts reached'));
            return;
        }

        if (this.state.reconnectTimeout) {
            clearTimeout(this.state.reconnectTimeout);
        }

        const delay = this.options.reconnectDelay * Math.pow(2, this.state.reconnectAttempts);
        this.log(`Reconnecting in ${delay}ms (attempt ${this.state.reconnectAttempts + 1}/${this.options.reconnectAttempts})`);

        this.state.reconnectTimeout = setTimeout(() => {
            this.state.reconnectAttempts++;
            this.connect();
        }, delay);
    }

    handleError(error) {
        this.log(error.message, 'error');
        this.emit('error', error);
    }

    log(message, level = 'info') {
        if (!this.options.debug) return;
        console[level](`[WebSocket] ${message}`);
    }

    send(data) {
        if (this.socket?.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(data));
        } else {
            this.log(`Cannot send: socket not open (state: ${this.socket?.readyState})`, 'warn');
        }
    }

    close() {
        if (this.state.reconnectTimeout) {
            clearTimeout(this.state.reconnectTimeout);
        }
        if (this.socket) {
            this.socket.close(1000, 'Normal closure');
        }
    }

    getState() {
        return {
            readyState: this.socket?.readyState,
            ...this.state
        };
    }
}

export const webSocketService = {
    createConnection: (url, options = {}) => {
        const connection = new WebSocketConnection(url, options);
        connection.connect();
        return connection;
    },

};
