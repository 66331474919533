import {
  useState,
  useRef,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, List } from "@mui/material";
import InputBar from "components/common/InputBar";
import ChatMessage from "./ChatMessage"; // Assuming ChatMessage is in the same directory

const ChatGod = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [error, setError] = useState(null);
  const chatContainerRef = useRef(null);

  const navigate = useNavigate();

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const handleChatSearch = async (term) => {
    if (!term.trim()) {
      setSearchResults([]);
      setChatMessages([]);
      setHasSearched(false);
      return;
    }

    setSearchTerm(""); // Clear the input only for chat category

    const newUserMessage = { text: term, isUser: true };
    setChatMessages((prevMessages) => [
      ...prevMessages,
      newUserMessage,
      { text: "", isUser: false, documents: [], isLoading: true },
    ]);
    setChatLoading(true);

    try {
      const response = await fetch("http://localhost:8000/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: term }),
      });

      if (!response.ok) {
        throw new Error("Failed to get response from server");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let buffer = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value);
        const lines = buffer.split("\n");
        buffer = lines.pop() || "";

        for (const line of lines) {
          if (line.trim() !== "") {
            try {
              const data = JSON.parse(line);
              setChatMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const lastIndex = updatedMessages.length - 1;
                const lastMessage = updatedMessages[lastIndex];
                const updatedLastMessage = {
                  ...lastMessage,
                  documents: Array.isArray(lastMessage.documents)
                    ? [...lastMessage.documents]
                    : [],
                  tables: Array.isArray(lastMessage.tables)
                    ? [...lastMessage.tables]
                    : [],
                  isLoading: false,
                };

                if (data.data_type === "table") {
                  updatedLastMessage.tables = [
                    ...updatedLastMessage.tables,
                    ...data.content,
                  ];
                } else if (data.data_type === "docs") {
                  updatedLastMessage.documents = [
                    ...updatedLastMessage.documents,
                    ...data.content,
                  ];
                } else if (data.data_type === "token") {
                  updatedLastMessage.text += data.content;
                }

                updatedMessages[lastIndex] = updatedLastMessage;

                return updatedMessages;
              });
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Sorry, there was an error processing your request.",
          isUser: false,
          documents: [],
          isLoading: false,
        },
      ]);
    } finally {
      setChatLoading(false);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/song_title/${row.id}`, {
      state: { id: row.id },
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: "default.paper",
        color: "white",
      }}
    >
      <Box
        ref={chatContainerRef}
        sx={{
          flexGrow: 1,
          overflow: "auto",

          p: 4,
        }}
      >
        {error && (
          <Typography color="error" align="center" mb={4}>
            Error: {error}
          </Typography>
        )}

        {!loading && !error && (
          <List>
            {chatMessages.map((msg, index) => (
              <ChatMessage
                key={index}
                message={msg.text}
                isUser={msg.isUser}
                documents={msg.documents}
                isLoading={msg.isLoading && index === chatMessages.length - 1}
                data={msg.tables}
                onRowClick={handleRowClick}
              />
            ))}
          </List>
        )}
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          backgroundColor: "background.default",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <InputBar
            onSubmit={(term) => handleChatSearch(term)}
            placeholder="Ask me anything..."
            width="100%"
            value={searchTerm}
            onChange={setSearchTerm}
            dropdownOptions={[{ value: "chat", label: "Chat", route: null }]}
            onDropdownChange={() => { }}
            mode="chat"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatGod;
