import React, { createContext, useContext, useState, useCallback } from 'react';
import isToday from 'date-fns/isToday';
import { useCompleteTask } from '../../services/api';
import { TaskOutlined } from '@mui/icons-material';

const TimelineContext = createContext();

export const TimelineProvider = ({ children }) => {
    const [timelineData, setTimelineData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const { mutate: completeTask } = useCompleteTask();

    // Memoize functions to prevent unnecessary rerenders
    const getNextItem = useCallback((currentItemId) => {
        // Find the day containing the current item
        const currentDay = timelineData.find(day =>
            day.items.some(item => item.id === currentItemId)
        );
        if (!currentDay) return null;

        // Find the item's index within its day
        const currentDayIndex = timelineData.indexOf(currentDay);
        const currentItemIndex = currentDay.items.findIndex(item => item.id === currentItemId);

        // First try to find next item in the same day
        if (currentItemIndex < currentDay.items.length - 1) {
            return currentDay.items[currentItemIndex + 1];
        }

        // If no more items in current day, look in next day
        if (currentDayIndex < timelineData.length - 1) {
            const nextDay = timelineData[currentDayIndex + 1];
            return nextDay.items[0] || null;
        }

        return null;
    }, [timelineData]);

    const handleItemClick = useCallback((item) => {
        // If there's already a selected item, close it first
        if (selectedItem) {
            setIsDetailOpen(false);
            // Use setTimeout to ensure the closing animation completes
            setTimeout(() => {
                setSelectedItem(item);
                setIsDetailOpen(true);
            }, 400); // Match the animation duration in DetailView
        } else {
            setSelectedItem(item);
            setIsDetailOpen(true);
        }
    }, [selectedItem]);

    const navigateToItem = useCallback((item) => {
        if (item) {
            setSelectedItem(item);
            setIsDetailOpen(true);
        }
    }, []);

    const closeDetail = useCallback(() => {
        setSelectedItem(null);
        setIsDetailOpen(false);
    }, []);

    const handleComplete = useCallback(async (id) => {
        if (!id) return;

        const nextItem = getNextItem(id);

        try {
            // Update to remove completed items from view
            setTimelineData(prevData =>
                prevData.map(day => ({
                    ...day,
                    items: day.items
                        .map(item =>
                            item.id === id
                                ? { ...item, complete: true }
                                : item
                        )
                        // Filter out completed items that weren't just completed
                        .filter(item => !item.complete)
                }))
            );

            // Navigate to next item or close detail view
            if (nextItem) {
                navigateToItem(nextItem);
            } else {
                closeDetail();
            }

            await completeTask(id);
        } catch (error) {
            console.error('Error completing task:', error);
            // Revert optimistic update
            setTimelineData(prevData =>
                prevData.map(day => ({
                    ...day,
                    items: day.items.map(item =>
                        item.id === id
                            ? { ...item, complete: false }
                            : item
                    )
                }))
            );
            throw error;
        }
    }, [getNextItem, closeDetail, completeTask, navigateToItem]);

    const value = {
        timelineData,
        setTimelineData,
        selectedItem,
        isDetailOpen,
        handleItemClick,
        getNextItem,
        navigateToItem,
        closeDetail,
        handleComplete
    };

    return (
        <TimelineContext.Provider value={value}>
            {children}
        </TimelineContext.Provider>
    );
};

export const useTimelineContext = () => {
    const context = useContext(TimelineContext);
    if (!context) {
        throw new Error('useTimelineContext must be used within a TimelineProvider');
    }
    return context;
}; 