import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";

const ChatBubbleLoader = () => {
  const [activeDot, setActiveDot] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot + 1) % 3);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        backgroundColor: theme.palette.secondary.main,
        padding: "8px 12px",
        borderRadius: "18px 18px 0 18px",
        minHeight: "40px", // Adjust this value to match your typical text height
        minWidth: "100px",
      }}
    >
      {[0, 1, 2].map((index) => (
        <Box
          key={index}
          sx={{
            width: 10,
            height: 10,
            borderRadius: "100%",

            backgroundColor:
              activeDot === index
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.light[300],
            transition: "background-color 0.3s ease",
          }}
        />
      ))}
    </Box>
  );
};

export default ChatBubbleLoader;
