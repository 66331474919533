import {
    Typography, Card, CardContent, Box, Grid, Alert
} from '@mui/material';
import { STEP_CONFIGS } from './reviewSteps';

const ReviewSummary = ({ reviewData, corrections, item, submitStatus }) => {
    // Get all applicable fields based on agreement type
    const getApplicableFields = () => {
        const baseFields = STEP_CONFIGS.base;
        const typeSpecificFields = STEP_CONFIGS[item.category] || [];
        return [...baseFields, ...typeSpecificFields];
    };

    // Add this helper function near the top of the component
    const formatDate = (date) => {
        if (!date) return '';
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    // Field configuration mapping
    const fieldConfig = {
        agreementType: {
            label: 'Agreement Type',
            original: item.category,
            correction: corrections.agreementType,
            status: reviewData.agreementTypeCorrect
        },
        matter: {
            label: 'Matter Information',
            original: item.matter_name,
            correction: 'Marked as incorrect',
            status: reviewData.matterCorrect
        },
        effectiveDate: {
            label: 'Effective Date',
            original: formatDate(item.effective_date),
            correction: formatDate(corrections.effectiveDate),
            status: reviewData.effectiveDateCorrect
        },
        expirationDate: {
            label: 'Expiration Date',
            original: formatDate(item.expiration_date),
            correction: formatDate(corrections.expirationDate),
            status: reviewData.expirationDateStatus
        },
        paymentDate: {
            label: 'Payment Due Date',
            original: formatDate(item.payment_due),
            correction: formatDate(corrections.paymentDate),
            status: reviewData.paymentDueDateCorrect
        }
    };

    // Helper function to render status indicator
    const StatusIndicator = ({ isCorrect }) => (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: isCorrect ? '#4caf50' : '#ff9800',
                marginRight: 1
            }}
        />
    );

    // Helper function to format review item
    const ReviewItem = ({ label, original, correction, status }) => (
        <Card
            elevation={0}
            sx={{
                mb: 2,
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                    transform: 'translateX(4px)'
                }
            }}
        >
            <CardContent sx={{ p: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{ textTransform: 'uppercase', letterSpacing: 0.5 }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StatusIndicator isCorrect={status === 'yes' || status === 'correct'} />
                            <Typography>
                                {status === 'yes' || status === 'correct'
                                    ? `${original}`
                                    : `${correction}`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', p: 3 }}>
            <Typography
                variant="h4"
                sx={{
                    mb: 4,
                    fontWeight: 500,
                    color: 'text.primary',
                    borderBottom: '2px solid',
                    borderColor: 'primary.main',
                    pb: 1
                }}
            >
                Review Summary
            </Typography>

            {submitStatus?.success && (
                <Alert severity="success" sx={{ mb: 3 }}>
                    Review has been successfully saved!
                </Alert>
            )}

            {submitStatus?.error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {submitStatus.error}
                </Alert>
            )}

            {getApplicableFields().map(fieldName => {
                const config = fieldConfig[fieldName];
                return (
                    <ReviewItem
                        key={fieldName}
                        label={config.label}
                        original={config.original}
                        correction={config.correction}
                        status={config.status}
                    />
                );
            })}
        </Box>
    );
};

export default ReviewSummary;