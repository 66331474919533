import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "themes/Palette";
import { IconButton } from '@mui/material';

const Logo = () => {
  const { logo } = useTheme();
  const navigate = useNavigate();

  // Check if the logo property exists in the theme
  const handleMenu = () => {
    navigate('/search');
  };

  return (

    <IconButton
      onClick={handleMenu}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          height: "20px",
          width: "auto",
          display: "block",
        }}
      />

    </IconButton>
  );
};

export default Logo;