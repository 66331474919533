import React, { useState, useEffect } from 'react';
import { authService } from 'services/api';
import {
    Box,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    Avatar,
    Grid,
    Divider,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';

const UserProfile = () => {
    const [userProfile, setUserProfile] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const profile = await authService.getUserProfile();
                setUserProfile(profile.data);
                console.log("Profile", profile);
            } catch (err) {
                setError('Failed to fetch user profile');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (!userProfile) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography>No User Found</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
            <Card elevation={3} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={3} alignItems="stretch" sx={{ flex: 1 }}>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Avatar
                                    src={userProfile.headshot}
                                    alt={`${userProfile.first_name} ${userProfile.last_name}`}
                                    sx={{ width: '80%', height: 'auto', aspectRatio: '1 / 1', maxWidth: 300, mb: 2 }}
                                />
                                <Typography variant="h4" gutterBottom align="center">
                                    {userProfile.first_name} {userProfile.last_name}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom align="center">
                                    @{userProfile.username}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="body1" gutterBottom>
                                    <BadgeIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    {userProfile.role}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <EmailIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    {userProfile.email}
                                </Typography>
                                <Typography variant="body1">
                                    <PhoneIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    {userProfile.phone}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="textSecondary">
                        User ID: {userProfile.id}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default UserProfile;
