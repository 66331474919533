import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuid4 } from 'uuid';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Load notifications from localStorage on mount
        try {
            const storedNotifications = JSON.parse(localStorage.getItem('notifications') || '[]');
            setNotifications(storedNotifications);
        } catch (error) {
            console.error('Error loading notifications from localStorage:', error);
        }
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        // Save notifications to localStorage whenever they change, but only after initial load
        if (isLoaded) {
            localStorage.setItem('notifications', JSON.stringify(notifications));
            console.log('Notifications updated:', notifications);
        }
    }, [notifications, isLoaded]);

    const addNotification = (notification) => {
        const newNotification = {
            ...notification,
            id: uuid4(),
            createdAt: new Date().toISOString(),
            type: notification.type || 'info',
        };
        setNotifications(prev => [...prev, newNotification]);
    };

    const removeNotification = (id) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    };

    const clearNotifications = () => {
        setNotifications([]);
        localStorage.removeItem('notifications');
    };

    // Automatically remove notifications older than 1 day
    useEffect(() => {
        const interval = setInterval(() => {
            const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
            setNotifications(prev => prev.filter(notification => notification.createdAt > oneDayAgo));
        }, 60000); // Check every minute

        return () => clearInterval(interval);
    }, []);

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification, clearNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);