import { useState, useCallback, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { pricingService } from 'services/api';
import { produce } from 'immer';
import { PieChart } from '@mui/x-charts/PieChart';
import { alpha } from '@mui/material/styles';
import { Snackbar } from '@mui/material';


export const usePricingManagement = ({ matter, selectedUseId, clearSelectedUse, uses = [], setUses, currentUser }) => {
    const [isPricingOpen, setIsPricingOpen] = useState(false);
    const theme = useTheme();
    const [orderedUseIds, setOrderedUseIds] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [latestReviews, setLatestReviews] = useState({ attorney_review: null, partner_review: null });

    const handlePricingClose = useCallback(() => {
        setIsPricingOpen(false);
        clearSelectedUse();
    }, [clearSelectedUse]);

    const handleOrderChanged = useCallback((newOrder) => {
        console.log("New order:", newOrder);
        setOrderedUseIds(newOrder);
    }, []);

    const initializeOrderedIds = useCallback((uses) => {
        const initialOrderedIds = uses.map(use => use.id);
        setOrderedUseIds(initialOrderedIds);
    }, []);

    const handleUpdates = useCallback(async (updateType, updates) => {

        try {
            const newUses = produce(uses, draft => {
                // Helper function to calculate valuation
                const calculateValuation = (use) => {
                    // Return null if required fields are missing, unless it's a special case
                    if (use.flag === 'Static Image') return 15000;
                    if (use.flag === 'Repost' || use.repost) return 5000;
                    if (use.override !== null) return use.override;

                    // Return null if either popularity_rank or quality is missing
                    if (!use.popularity_rank || !use.quality) return null;

                    const durationOffer =
                        use.use_duration < '00:00:15' ? 3000 :
                            use.use_duration < '00:00:30' ? 3900 :
                                use.use_duration < '00:01:00' ? 4800 :
                                    use.use_duration < '00:02:00' ? 5400 : 6000;

                    const qualityOffer =
                        use.quality === 'LQ' ? 6750 :
                            use.quality === 'MQ' ? 10800 :
                                use.quality === 'HQ' ? 13500 : 0;

                    const popularityOffer =
                        use.popularity_rank === 'High' ? 13500 :
                            use.popularity_rank === 'Medium' ? 10800 :
                                use.popularity_rank === 'Low' ? 6750 : 0;

                    return durationOffer + qualityOffer + popularityOffer;
                };

                // Update the specific use and recalculate values
                const updateUseValues = (use) => {
                    const valuation = calculateValuation(use);
                    use.valuation = valuation;
                    use.prorated = valuation !== null ? valuation * (use.ownership_info || 0) : null;
                };

                switch (updateType) {
                    case "popularity_rank":
                        draft.forEach(use => {
                            if (use.works_id === updates.works_id) {
                                use.popularity_rank = updates.popularity_rank;
                                updateUseValues(use);
                            }
                        });
                        break;

                    default:
                        const useIndex = draft.findIndex(use => use.id === updates.useId);
                        if (useIndex === -1) return;

                        switch (updateType) {
                            case "video_quality":
                                draft[useIndex].quality = updates.quality;
                                updateUseValues(draft[useIndex]);
                                break;
                            case "override":
                                draft[useIndex].override = updates.override;
                                draft[useIndex].valuation = updates.override;
                                draft[useIndex].prorated = updates.override * (draft[useIndex].ownership_info || 0);
                                break;
                            case "add_flag":
                                draft[useIndex].flag = updates.flag;
                                draft[useIndex].flags = [...(draft[useIndex].flags || []), updates.flag];
                                updateUseValues(draft[useIndex]);
                                break;
                        }
                }
            });

            // Update state immediately
            setUses(newUses);

            // Make API call
            switch (updateType) {
                case "video_quality":
                    await pricingService.videoQuality(updates.page_url, updates.quality);
                    break;
                case "popularity_rank":
                    await pricingService.popularityRank(updates.works_id, updates.popularity_rank);
                    console.log("Popularity rank updated, ", updates.works_id, updates.popularity_rank);
                    break;
                case "override":
                    await pricingService.override(updates.useId, updates.override);
                    break;
                case "add_note":
                    const noteResult = await pricingService.addNote(updates.matterId, updates.useId, updates.note);
                    return noteResult;
                case "add_flag":
                    await pricingService.addFlag(updates.useId, updates.flag);
                    break;
                default:
                    console.warn(`Unknown update type: ${updateType}`);
            }
        } catch (error) {
            // Revert on error
            setUses(uses);  // Revert to previous state
            console.error(`Error updating ${updateType}:`, error);
            throw error;
        }
    }, [uses, setUses]);

    const fetchLatestReviews = useCallback(async () => {
        try {
            const logs = await pricingService.getMatterLogs(matter.id);
            // Ensure logs is an array before sorting
            const logsArray = Array.isArray(logs.data) ? logs.data : [];
            // Sort by date_created descending and find latest partner review
            const sortedLogs = logsArray.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
            const latestPartnerLog = sortedLogs.find(log => log.role === 'Partner');
            const latestAssociateLog = sortedLogs.find(log => log.role === 'Associate');
            console.log("Latest partner log:", latestPartnerLog);
            console.log("Latest associate log:", latestAssociateLog);

            setLatestReviews({
                partner_review: latestPartnerLog?.partner_review || null,
                attorney_review: latestAssociateLog?.attorney_review || null
            });
        } catch (error) {
            console.error('Error fetching matter logs:', error);
        }
    }, [matter.id]);

    const handlePricingComplete = useCallback(async () => {
        // Check for valid role first
        if (!['Associate', 'Partner'].includes(currentUser.role)) {
            setSnackbarMessage('You must be an Associate or Partner to mark pricing as complete.  You are currently logged in as ' + currentUser.role);
            setSnackbarOpen(true);
            return;
        }

        try {
            const pricedUses = uses.filter(use => use.prorated !== null);
            const unpricedUses = uses.filter(use => use.prorated === null);
            const flaggedUses = uses.filter(use => use.flags?.length > 0);
            const totalProposedOffer = pricedUses.reduce((sum, use) => sum + (use.prorated || 0), 0);

            // Optimistically update the review status
            setLatestReviews(prev => ({
                ...prev,
                attorney_review: currentUser.role === 'Associate' ? 'Complete' : prev.attorney_review,
                partner_review: currentUser.role === 'Partner' ? 'Complete' : prev.partner_review
            }));

            const logData = {
                opportunity_id: matter.id,
                proposed_starting_offer: totalProposedOffer,
                percent_complete: (pricedUses.length / uses.length),
                number_of_uses: uses.length,
                priced: pricedUses.length,
                unpriced: unpricedUses.length,
                flags: flaggedUses.length,
                user_id: currentUser.id,
                attorney_review: currentUser.role === 'Associate' ? 'Complete' : 'Incomplete',
                partner_review: currentUser.role === 'Partner' ? 'Complete' : 'Incomplete'
            };

            await pricingService.addMatterLog(logData);
            // Still fetch the latest reviews to ensure consistency with server
            await fetchLatestReviews();
        } catch (error) {
            // Revert the optimistic update on error
            await fetchLatestReviews();
            console.error('Error completing pricing review:', error);
            throw error;
        }
    }, [matter, uses, currentUser, fetchLatestReviews]);

    const getPricingInfoContent = useCallback(() => {
        const pricedUses = uses.filter(use => use.prorated !== null);
        const unpricedUses = uses.filter(use => use.prorated === null);
        const repostCount = uses.filter(use => use.repost).length;
        const totalProposedOffer = pricedUses.reduce((sum, use) => sum + (use.prorated || 0), 0);

        const pieData = [
            {
                id: 0,
                value: pricedUses.length,
                label: 'Priced Uses',
                color: theme.palette.primary.main
            },
            {
                id: 1,
                value: unpricedUses.length,
                label: 'Remaining Uses',
                color: theme.palette.grey[300]
            }
        ];

        return (
            <Box sx={{
                p: 2.5,
                background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
                backdropFilter: 'blur(8px)',
                borderRadius: '16px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="overline"
                            sx={{
                                letterSpacing: '0.1em',
                                color: alpha(theme.palette.text.primary, 0.7),
                                fontSize: '0.75rem',
                                fontWeight: 600
                            }}
                        >
                            PRICING REVIEW
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        display: 'block',
                                        mb: 0.5
                                    }}>
                                        Uses Priced
                                    </Typography>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700,
                                        lineHeight: 1,
                                        color: theme.palette.primary.main
                                    }}>
                                        {pricedUses.length}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        display: 'block',
                                        mb: 0.5
                                    }}>
                                        Total Offer
                                    </Typography>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700,
                                        lineHeight: 1
                                    }}>
                                        ${totalProposedOffer.toLocaleString()}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        display: 'block',
                                        mb: 0.5
                                    }}>
                                        Review Status
                                    </Typography>
                                    <Grid container spacing={1} justifyContent="center" direction="column">
                                        <Grid item>
                                            <Typography sx={{
                                                color: latestReviews.attorney_review === 'Complete' ? theme.palette.success.main : theme.palette.text.secondary,
                                                fontSize: '0.875rem',
                                                fontWeight: 600
                                            }}>
                                                Attorney: {latestReviews.attorney_review === 'Complete' ? '✓' : 'Pending'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{
                                                color: latestReviews.partner_review === 'Complete' ? theme.palette.success.main : theme.palette.text.secondary,
                                                fontSize: '0.875rem',
                                                fontWeight: 600
                                            }}>
                                                Partner: {latestReviews.partner_review === 'Complete' ? '✓' : 'Pending'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handlePricingComplete}
                                                disabled={
                                                    (currentUser.role === 'Associate' && latestReviews.attorney_review) ||
                                                    (currentUser.role === 'Partner' && latestReviews.partner_review)
                                                }
                                                sx={{ mt: 1 }}
                                            >
                                                Mark Review Complete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Box sx={{ height: 160 }}>
                                    <PieChart
                                        series={[{
                                            data: pieData,
                                            highlightScope: { faded: 'global', highlighted: 'item' },
                                            innerRadius: 60,
                                            paddingAngle: 2,
                                            cornerRadius: 4,
                                        }]}
                                        slotProps={{
                                            legend: {
                                                direction: 'row',
                                                position: { vertical: 'bottom', horizontal: 'middle' },
                                                padding: 0,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 5,
                                                itemGap: 15,
                                            }
                                        }}
                                        height={160}
                                        margin={{ top: 10, bottom: 40, left: 0, right: 0 }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
            </Box>
        );
    }, [uses, theme, matter, currentUser, handlePricingComplete, snackbarOpen, snackbarMessage, latestReviews]);

    useEffect(() => {
        fetchLatestReviews();
    }, [fetchLatestReviews]);

    return {
        isPricingOpen,
        setIsPricingOpen,
        orderedUseIds,
        handlePricingClose,
        handleOrderChanged,
        initializeOrderedIds,
        getPricingInfoContent,
        handleUpdates,
        handlePricingComplete,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        fetchLatestReviews,
    };
}; 