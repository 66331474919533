import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChatBubbleLoader from "pages/Chat/ChatBubbleLoader";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { IconButton, Collapse, Link } from "@mui/material";
import TableRenderer from "./TableRenderer"; // Assuming TableRenderer is in the same directory

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const DocumentDisplay = ({ document }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ mb: 1, pl: 2, display: 'flex', alignItems: 'flex-start' }}>
      <IconButton size="small" onClick={() => setExpanded(!expanded)} sx={{ mt: -0.5, mr: 1 }}>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Box>
        <Link
          href={document.metadata.page_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            variant="caption"
            color="secondary.light"
            fontWeight={100}
          >
            {document.metadata.email_from} -{" "}
            {formatDate(document.metadata.email_date)} -{" "}
            {document.metadata.subject}
          </Typography>
        </Link>
        <Collapse in={expanded}>
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="body1"
              color="text.primary"
              fontStyle="italic"
              fontWeight={400}
            >
              {document.page_content}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

const ChatMessage = ({
  message,
  isUser,
  documents,
  isLoading,
  data,
  onRowClick,
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: isUser ? "flex-end" : "flex-start",
      mb: 2,
    }}
  >
    <Box
      sx={{
        maxWidth: "70%",
        backgroundColor: isUser ? "primary.main" : "transparent",
        borderRadius: isUser ? "18px 18px 0 18px" : "18px 18px 18px 0",
        padding: "8px 12px",
        alignSelf: isUser ? "flex-end" : "flex-start",
      }}
    >
      {isLoading ? (
        <ChatBubbleLoader />
      ) : (
        <Typography
          variant="body1"
          color={isUser ? "text.default" : "text.default"}
        >
          {message}
        </Typography>
      )}
    </Box>
    {!isUser && !isLoading && documents && documents.length > 0 && (
      <Box sx={{ mt: 1, maxWidth: "70%" }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1, ml: 1.5 }}>
          Related Documents:
        </Typography>
        <Box sx={{ pl: 1.5 }}>
          {documents.map((doc, index) => (
            <DocumentDisplay key={index} document={doc} />
          ))}
        </Box>
      </Box>
    )}

    {!isUser && !isLoading && data && data.length > 0 && (
      <TableRenderer data={data} onRowClick={onRowClick} />
    )}

  </Box>
);

export default ChatMessage;
