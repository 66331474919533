import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  Avatar,
  Container,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { accountService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";
import { DataTable } from "components/common/DataTable";
import { titleCase } from "utils/dataUtils";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) return "N/A";

  const num = parseFloat(amount);
  if (isNaN(num)) return "N/A";

  if (num >= 1000000) {
    return `$${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `$${(num / 1000).toFixed(1)}k`;
  } else {
    return `$${num.toFixed(2)}`;
  }
};

export const TopPageGrid = ({ account }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle2">Industry</Typography>
      <Typography variant="body1">
        {account.industry || "N/A"}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle2">Revenue</Typography>
      <Typography variant="body1">
        {account.revenue_millions
          ? `$${account.revenue_millions}M`
          : account.estimated_revenue_range || "N/A"}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle2">Employees</Typography>
      <Typography variant="body1">
        {account.employees || "N/A"}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle2">Headquarters</Typography>
      <Typography variant="body1">{account.hq || "N/A"}</Typography>
    </Grid>
  </Grid>
);

const createExportFilename = (account) => {
  const date = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
  const formattedType = titleCase(account.account);
  return `${formattedType} - Matters - ${date}.xlsx`;
};

const AccountDetail = () => {
  const [account, setAccount] = useState(null);
  const [matters, setMatters] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const location = useLocation();
  const accountId = location.state?.accountId || id;
  const navigate = useNavigate();
  const [exportFilename, setExportFilename] = useState(null);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const [accountResponse, mattersResponse] = await Promise.all([
          accountService.getAccountDetails(accountId),
          accountService.getAssociatedMatters(accountId),
        ]);

        setAccount(accountResponse.data[0]);
        setMatters(mattersResponse.data);
        setExportFilename(createExportFilename(accountResponse.data[0]));
      } catch (err) {
        setError(
          err.response?.data?.detail || "An error occurred while fetching data"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAccountDetails();
  }, [accountId]);

  const handleRowClick = (dataRow) => {
    console.log("Row Clicked", dataRow.row);
    if (dataRow.row.matter_id) {
      navigate(`/matter/${dataRow.row.matter_id}`);
    }
  };

  const loadMoreData = () => {
    console.log("Loading more data");
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          pt: 4,
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{
        height: "100%",
        width: "100%",
        pt: 4,
      }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  if (!account) {
    return (
      <Box sx={{ p: 5, width: "100%" }}>
        <Typography variant="h6">No account details found.</Typography>
      </Box>
    );
  }

  const renderField = (label, value) => {
    if (value === null || value === undefined || value === "") return null;
    return (
      <TableRow>
        <TableCell>{label}</TableCell>
        <TableCell align="right">{value}</TableCell>
      </TableRow>
    );
  };

  const renderLinkField = (label, value, prefix = "") => {
    if (value === null || value === undefined || value === "") return null;
    return (
      <TableRow>
        <TableCell>{label}</TableCell>
        <TableCell align="right">
          <Link
            href={`${prefix}${value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      <Container maxWidth={false}>
        <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar
                src={account.logo}
                alt={account.account}
                sx={{ width: 80, height: 80 }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h4" gutterBottom>
                {account.account}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {account.legal_name || account.parent}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <TopPageGrid account={account} />
        </StyledPaper>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Company Information
                </Typography>
                <Table size="small">
                  <TableBody>
                    {renderField("Brand", account.brand)}
                    {renderField("Legal Name", account.legal_name)}
                    {renderField("Parent Company", account.parent)}
                    {renderField(
                      "Founded Date",
                      account.founded_date
                        ? new Date(account.founded_date).toLocaleDateString()
                        : null
                    )}
                    {renderField("Founders", account.founders)}
                    {renderField(
                      "Number of Founders",
                      account.number_of_founders
                    )}
                    {renderField("Company Type", account.company_type)}
                    {renderField("Operating Status", account.operating_status)}
                    {renderField("CB Rank", account.cb_rank)}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Table size="small">
                  <TableBody>
                    {renderLinkField("Website", account.website)}
                    {renderLinkField("LinkedIn", account.linkedin)}
                    {renderLinkField("Twitter", account.twitter)}
                    {renderLinkField("Facebook", account.facebook)}
                    {renderField("Phone", account.phone_number)}
                    {renderField("Email", account.contact_email)}
                    {renderField("Postal Code", account.postal_code)}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Business Details
                </Typography>
                <Table size="small">
                  <TableBody>
                    {renderField("Industries", account.industries)}
                    {renderField("Industry Groups", account.industry_groups)}
                    {renderField("Category", account.category)}
                    {renderField("Description", account.description)}
                    {renderField("Full Description", account.full_description)}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Financial Information
                </Typography>
                <Table size="small">
                  <TableBody>
                    {renderField(
                      "Total Funding Amount",
                      account.total_funding_amount
                        ? formatCurrency(account.total_funding_amount)
                        : null
                    )}

                    {renderField(
                      "Number of Investors",
                      account.number_of_investors
                    )}
                    {renderField(
                      "Estimated Revenue Range",
                      account.estimated_revenue_range
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Additional Information
                </Typography>
                <Table size="small">
                  <TableBody>
                    {renderField("League", account.league)}
                    {renderField("Abbreviation", account.abbreviation)}
                    {renderField("Country HQ", account.country_hq)}
                    {renderField(
                      "Headquarters Regions",
                      account.headquarters_regions
                    )}
                    {renderField("Brand Notes", account.brand_notes)}
                    {renderField("Brand Summary", account.brand_summary)}
                    {renderField("Account Groups", account.account_groups)}
                    {renderField("Farthest Stage", account.farthest_stage)}
                    {renderField(
                      "Has Settled Matter",
                      account.has_settled_matter !== null
                        ? account.has_settled_matter
                          ? "Yes"
                          : "No"
                        : null
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {matters && matters.length > 0 ? (
          < DataTable
            data={matters}
            onRowClick={handleRowClick}
            exportFilename={exportFilename}
          />
        ) : (
          !isLoading && <Typography>No data available</Typography>
        )}
        {isLoading && <LoadingSpinner />}
      </Container>
    </Box>
  );
};

export default AccountDetail;
