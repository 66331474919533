export const STEP_CONFIGS = {
    base: ['agreementType', 'matter', 'effectiveDate'],
    SETTLEMENT: ['paymentDate'],
    TOLLING: ['expirationDate']
};

export const INITIAL_REVIEW_DATA = {
    agreementTypeCorrect: '',
    matterCorrect: '',
    effectiveDateCorrect: '',
    expirationDateStatus: '',
    paymentDueDateCorrect: '',
    comments: ''
}; 