import { Warning as AlertIcon, Launch as LaunchIcon } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { TYPE_ICONS } from '../utils/styles';
import DetailView from './DetailView';
import ViewAgreementButton from '../utils/ViewAgreementButton';

const generateTitle = (item) => {
    return item.title;
};

const generateDescription = (item) => {
    const daysText = item.daysLeft === 1 ? 'day' : 'days';
    return `The current tolling agreement expires in ${item.daysLeft} ${daysText}`;
};

const generateMatterLink = (item) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Link href={`/matter/${item.matter_id}/?tab=2`} sx={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                {item.matter}
            </Link>
            <LaunchIcon sx={{ fontSize: 14, opacity: 0.6, color: 'primary.main' }} />
        </Box>
    );
};

const generateFieldsList = (item) => {
    const fields = {
        'Effective Date': item.effectiveDate,
        'Expiration Date': item.expiryDate,
        'Matter Page': generateMatterLink(item)
    };

    return fields;
};

const TollsExpiringDetail = ({ item, onClose }) => {
    const theme = useTheme();
    const IconComponent = TYPE_ICONS[item.type] || TYPE_ICONS.default;

    const GradientTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 700,
        fontSize: '1.35rem',
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        letterSpacing: '-0.02em'
    }));

    return (
        <DetailView onClose={onClose} item={item}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 3
            }}>
                <IconComponent sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    opacity: 0.9
                }} />
                <GradientTypography>
                    {generateTitle(item)}
                </GradientTypography>
            </Box>

            <Box sx={{ pl: '2.6rem' }}>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 3,
                        whiteSpace: 'pre-line',
                        color: alpha(theme.palette.text.primary, 0.87),
                        lineHeight: 1,
                        letterSpacing: '0.01em',
                    }}
                >
                    {generateDescription(item)}
                </Typography>

                <Box sx={{
                    display: 'grid',
                    gap: 1.5,
                    mb: 3,
                    pt: 2,
                    borderTop: `1px solid ${alpha(theme.palette.divider, 0.08)}`
                }}>
                    {Object.entries(generateFieldsList(item)).map(([label, value]) => (
                        <Box key={label} sx={{ display: 'flex', gap: 1 }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: alpha(theme.palette.text.primary, 0.6),
                                    fontWeight: 600,
                                    minWidth: '120px'
                                }}
                            >
                                {label}:
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: alpha(theme.palette.text.primary, 0.87),
                                }}
                            >
                                {value}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 3,
                    pt: 1,
                    borderTop: `1px solid ${alpha(theme.palette.divider, 0.08)}`
                }}>
                    {item.priority === 'high' && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            color: theme.palette.error.main,
                            background: alpha(theme.palette.error.main, 0.08),
                            px: 1.5,
                            py: 0.5,
                            borderRadius: '16px'
                        }}>
                            <AlertIcon sx={{ fontSize: 14 }} />
                            <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                Urgent
                            </Typography>
                        </Box>
                    )}
                    <Typography
                        variant="caption"
                        sx={{
                            color: alpha(theme.palette.text.primary, 0.6),
                            fontWeight: 500
                        }}
                    >
                        {item.date}
                    </Typography>
                </Box>

                {item.url && (
                    <Box sx={{ mt: 4 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                mb: 2,
                                color: alpha(theme.palette.text.primary, 0.6),
                                fontStyle: 'italic'
                            }}
                        >
                            For your reference, please find a link to the latest tolling agreement below:
                        </Typography>
                        <ViewAgreementButton url={item.url} />
                    </Box>
                )}
            </Box>
        </DetailView>
    );
};

export default TollsExpiringDetail;