import { DataTable } from "components/common/DataTable";
import PropTypes from 'prop-types';

export const UsesTable = ({
    data,
    defaultVisibleColumns,
    onRowClick,
    tableStyle,
    trackOrderBy,
    onOrderChanged,
    pagination,
    exportFilename
}) => {
    return (
        <DataTable
            data={data}
            defaultVisibleColumns={defaultVisibleColumns}
            onRowClick={onRowClick}
            sx={tableStyle}
            trackOrderBy={trackOrderBy}
            onOrderChanged={onOrderChanged}
            pagination={pagination}
            exportFilename={exportFilename}
        />
    );
};

UsesTable.propTypes = {
    data: PropTypes.array.isRequired,
    defaultVisibleColumns: PropTypes.array.isRequired,
    onRowClick: PropTypes.func.isRequired,
    tableStyle: PropTypes.object,
    trackOrderBy: PropTypes.string,
    onOrderChanged: PropTypes.func,
    exportFilename: PropTypes.string
}; 
