import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import TaskInputForm from './TaskInputForm';
import TaskTable from './TaskTable';
import useTaskManagement from './useTaskManagement';

const TaskManager = () => {
    const {
        kwargs,
        setKwargs,
        taskType,
        setTaskType,
        createTask,
        activeTasks,
        completedTasks
    } = useTaskManagement();

    // Add effects to monitor tasks updates
    useEffect(() => {
        console.log('TaskManager - Active Tasks updated:', activeTasks);
    }, [activeTasks]);

    useEffect(() => {
        console.log('TaskManager - Completed Tasks updated:', completedTasks);
    }, [completedTasks]);

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4" component="h1">
                    Task Manager
                </Typography>
            </Box>

            <TaskInputForm
                kwargs={kwargs}
                setKwargs={setKwargs}
                taskType={taskType}
                setTaskType={setTaskType}
                createTask={createTask}
            />
            <Grid container spacing={0} sx={{ flexGrow: 1, overflow: 'auto' }}>
                <TaskTable
                    title="Active Tasks"
                    tasks={activeTasks}
                    rowsPerPage={50}

                />
                <TaskTable
                    title="Completed Tasks"
                    tasks={completedTasks}
                    rowsPerPage={50}
                />
            </Grid>

        </Box>
    );
};

export default TaskManager;
