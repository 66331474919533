import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useTimelineContext } from '../TimelineContext';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { CompleteTaskButton } from '../utils/CompleteButton';

const DetailView = ({ children, onClose, item }) => {
    const theme = useTheme();
    const { handleComplete } = useTimelineContext();

    const handleClose = () => {
        onClose?.();
    };

    return (
        <Card
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            sx={{
                height: '100%',
                width: '100%',
                background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
                backdropFilter: 'blur(10px)',
                borderRadius: '20px',
                boxShadow: `0 8px 32px -8px ${alpha(theme.palette.common.black, 0.05)}`,
            }}
        >
            <CardContent sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                gap: 1,
                position: 'relative',
                '&:last-child': { pb: 4 }
            }}>
                {onClose && (
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}

                {children}

                {item.id && item.type !== 'agreement_review' && (
                    <Box sx={{ mt: 'auto', pt: 2 }}>
                        <CompleteTaskButton
                            id={item.id}
                            onComplete={handleComplete}
                        />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

DetailView.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    item: PropTypes.object,
};

export default DetailView;