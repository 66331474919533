import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const TextContent = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    lineHeight: 1.6,
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    wordBreak: 'break-word',
    fontSize: '0.925rem',
    '& p': {
        marginBottom: theme.spacing(1.5)
    }
}));

const TextContentBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    maxHeight: '280px',
    overflow: 'auto',
    border: '1px solid',
    borderColor: theme.palette.divider,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        borderColor: theme.palette.primary.light,
        boxShadow: `0 0 0 1px ${theme.palette.primary.light}25`,
    },
    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        height: '20px',
        pointerEvents: 'none',
        zIndex: 2,
        transition: 'opacity 0.2s ease-in-out',
    },
    '&::before': {
        top: 0,
        background: `linear-gradient(to bottom, ${theme.palette.background.paper}, transparent)`,
        borderTopLeftRadius: theme.shape.borderRadius * 2,
        borderTopRightRadius: theme.shape.borderRadius * 2,
    },
    '&::after': {
        bottom: 0,
        background: `linear-gradient(to top, ${theme.palette.background.paper}, transparent)`,
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
    }
}));

const TextBox = ({ text }) => {
    return text ? (
        <Box sx={{
            mb: 3,
            position: 'relative',
            borderRadius: theme => theme.shape.borderRadius * 2,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
        }}>
            <TextContentBox>
                <TextContent component="pre" variant="body2">
                    {text}
                </TextContent>
            </TextContentBox>
        </Box>
    ) : null;
};

export { TextContentBox, TextContent, TextBox };