import React from 'react';
import {
    Typography,
    Paper,
    Button
} from '@mui/material';
import { useNotifications } from './NotificationContext';
import NotificationList from 'components/common/Notifications/NotificationList';


const NotificationDisplay = () => {
    const { notifications, addNotification, removeNotification } = useNotifications();

    const handleAddTestNotification = () => {
        addNotification({
            message: 'This is a test notification',
            type: 'info',
        });
    };

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: 400, margin: 'auto', mt: 4 }}>
            <Typography variant="h6" gutterBottom>
                Live Notifications
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddTestNotification}
                sx={{ mb: 2 }}
            >
                Add Test Notification
            </Button>
            <NotificationList
                notifications={notifications}
                onClose={removeNotification}
            />
        </Paper>
    );
};

export default NotificationDisplay;
