import { useMemo } from "react";
import {
  Notes,
  Login,
  Search,
  Description,
} from "@mui/icons-material";

import ChatBubbleOutlineSharpIcon from "@mui/icons-material/ChatBubbleOutlineSharp";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
// Map of icons for different link types
const iconMap = {
  search: Search,
  guides: ArticleOutlinedIcon,
  chat: ChatBubbleOutlineSharpIcon,
  page: Description,
  logs: Notes,
  login: Login,
};

const useNavLinks = () => {
  const links = useMemo(
    () => [
      // {
      //   to: "/search",
      //   label: "Search",
      //   openInNewTab: false,
      //   icon: iconMap.search,
      // },
      {
        to: "/guides",
        label: "Guides",
        openInNewTab: false,
        icon: iconMap.guides,
      },
      // { to: "/chat_god", label: "Chat", openInNewTab: true, icon: iconMap.logs },
      { to: "/history", label: "History", openInNewTab: false, icon: iconMap.login },
    ],
    []
  );

  return links;
};

export default useNavLinks;
