import { useState } from "react";
import {
  Box,
  AppBar,
  Tabs,
  Tab, Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MatterDetails from "./MatterDetails";
import Uses from "../Use/Uses";
import Files from 'components/Files';
import EmailThread from 'pages/Emails/EmailThread';
import { useSearchParams } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`matter-tabpanel-${index}`}
      aria-labelledby={`matter-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `matter-tab-${index}`,
    "aria-controls": `matter-tabpanel-${index}`,
  };
}

export default function MatterTabs({ matter, files, emails, scrollToTabs }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(() => {
    const tabParam = searchParams.get("tab");
    return tabParam ? parseInt(tabParam) : 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <StyledPaper elevation={3}>

        <AppBar position="static" color='inherit' elevation={3}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="matter tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Uses" {...a11yProps(1)} />
            <Tab label="Files" {...a11yProps(2)} />
            <Tab label="Emails" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MatterDetails matter={matter} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Uses matter={matter} scrollToTabs={scrollToTabs} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Files file_data={files} matter={matter} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EmailThread emails={emails} />
        </TabPanel>
      </StyledPaper>

    </Box>
  );
}
