import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const UnderMaintenance = () => {
    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
            >
                <Typography variant="h2" gutterBottom>
                    Under Maintenance
                </Typography>
                <Typography variant="body1">
                    We're currently performing some maintenance. Please check back later.
                </Typography>
            </Box>
        </Container>
    );
};

export default UnderMaintenance;