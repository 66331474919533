import { useState, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import GalleryView from 'pages/Pricing/GalleryView';
import VideoGrid from './VideoGrid';
import VideoPlayer from 'pages/Pricing/VideoPlayer';
import { BackgroundContent, RepostInfo } from './RepostPageContent';
import { createLogger } from 'utils/debug-config';
import { useTheme } from '@mui/material/styles';

const log = createLogger('RepostsUse');

// Utility function to group reposts by song title id
const groupRepostsBySongTitle = (repostData) => {
    const groupedData = {};
    repostData.forEach(repost => {
        if (!groupedData[repost.song_title_id]) {
            groupedData[repost.song_title_id] = [];
        }
        groupedData[repost.song_title_id].push(repost);
    });

    // Filter out groups with less than 2 uses
    return Object.values(groupedData).filter(group => group.length >= 2);
};

// Custom hook to manage repost groups state
const useRepostGroupsState = (repostData) => {
    const [useRepostGroups, setUseRepostGroups] = useState(() => {
        const initialGroups = {};
        repostData.forEach(repost => {
            initialGroups[repost.id] = repost.repost_group;
        });
        return initialGroups;
    });

    return [useRepostGroups, setUseRepostGroups];
};

const RepostsUse = ({ isOpen, onClose, repostData, selectedUseId, onUpdateRepostGroup, onUpdateRepostsReviewed }) => {
    const [useRepostGroups, setUseRepostGroups] = useRepostGroupsState(repostData);
    const theme = useTheme();

    // Group reposts by song title
    const groupedRepostData = useMemo(
        () => groupRepostsBySongTitle(repostData),
        [repostData]
    );

    const initialItemIndex = useMemo(() => {
        if (selectedUseId) {
            return groupedRepostData.findIndex(group =>
                group.some(repost => repost.id === selectedUseId)
            );
        }
        return 0;
    }, [groupedRepostData, selectedUseId]);

    const handleUpdateRepostGroup = useCallback(({ useId, newGroupId, position }) => {
        log.debug('Updating repost group', { useId, newGroupId, position });
        setUseRepostGroups(prev => ({
            ...prev,
            [useId]: newGroupId === "null" ? null : newGroupId
        }));

        const repost = repostData.find(r => r.id === useId);
        if (!repost) {
            log.error('Repost not found', { useId });
            return;
        }

        onUpdateRepostGroup({
            useId,
            oldGroupId: repost.repost_group,
            newGroupId,
            position
        });
    }, [repostData, onUpdateRepostGroup, setUseRepostGroups]);

    const renderForegroundContent = useCallback(({ currentItem, onPrevious, onNext, isFirstItem, isLastItem }) => {
        log.debug('Rendering foreground content', { currentItem, isFirstItem, isLastItem });
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: "100vw",
                    height: "60vh",
                    padding: 1,
                    zIndex: 1000,
                }}
            >
                <Box sx={{ width: '60%' }}>
                    <VideoGrid
                        videoPlayer={<VideoPlayer />}
                        urlField='archive_link'
                        repostGroup={currentItem}
                        onPrevious={onPrevious}
                        onNext={onNext}
                        isFirstGroup={isFirstItem}
                        isLastGroup={isLastItem}
                        useRepostGroups={useRepostGroups}
                        onUpdateRepostGroup={handleUpdateRepostGroup}
                        onUpdateRepostsReviewed={onUpdateRepostsReviewed}
                    />
                </Box>
            </Box>
        );
    }, [useRepostGroups, handleUpdateRepostGroup, onUpdateRepostsReviewed]);

    const renderBackgroundContent = useCallback(({ currentIndex, totalItems, onClose }) => {
        // log.debug('Rendering background content', { currentIndex, totalItems });
        const currentGroup = groupedRepostData[currentIndex];
        const currentRepost = currentGroup[0];
        return (
            <>
                <RepostInfo currentRepost={currentRepost} />
                <BackgroundContent
                    currentIndex={currentIndex}
                    totalGroups={totalItems}
                    onClose={onClose}
                />
            </>
        );
    }, [groupedRepostData]);

    const handleNavigate = useCallback((currentItem) => {
        // Update selectedUseId with the first repost ID from the current group
        if (currentItem && currentItem.length > 0) {
            const firstRepostInGroup = currentItem[0];
            onClose(firstRepostInGroup.id); // Pass the new selectedUseId to onClose
        }
    }, [onClose]);

    return (
        <GalleryView
            isOpen={isOpen}
            onClose={onClose}
            items={groupedRepostData}
            initialItemIndex={initialItemIndex}
            renderForegroundContent={renderForegroundContent}
            renderBackgroundContent={renderBackgroundContent}
            onNavigate={handleNavigate}
        />
    );
};

export default RepostsUse;
