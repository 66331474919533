import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Paper, Typography, Container, Grid, Avatar, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingSpinner from 'utils/LoadingSpinner';
import { approvalService } from 'services/api';
import { DataTable } from "components/common/DataTable";
import { ReviewModal } from './ReviewModal';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

export const TopPageGrid = ({ key1, value1, key2, value2, key3, value3, key4, value4 }) => (
    <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key1}</Typography>
            <Typography variant="body1">
                {value1 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key2}</Typography>
            <Typography variant="body1">
                {value2 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key3}</Typography>
            <Typography variant="body1">
                {value3 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key4}</Typography>
            <Typography variant="body1">{value4 || "-"}</Typography>
        </Grid>
    </Grid>
);

const TABLE_COLS = ['song_title', 'artist', 'side', 'ownership_info', 'service', 'video_title', 'date_uploaded']

const Detections = () => {
    const { clientId, accountId } = useParams();
    const location = useLocation();
    const rowData = location.state?.rowData;
    const [detections, setDetections] = useState([]);
    const [numDetections, setNumDetections] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Inside the Detections component
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchDetections = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await approvalService.getDetections(clientId, accountId);
                setDetections(response.data);
                setNumDetections(response.data.length);
            } catch (err) {
                console.error('Error fetching detections:', err);
                setError(err.response?.data?.detail || 'An error occurred while fetching detections');
            } finally {
                setLoading(false);
            }
        };

        if (accountId && clientId) {
            fetchDetections();
        }
    }, [accountId, clientId]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    pt: 4,
                }}
            >
                <LoadingSpinner />
            </Box>
        );
    }
    if (error) {
        return (
            <Box sx={{ height: "100%", width: "100%", pt: 4 }}>
                <Typography variant="h6" color="error">
                    Error: {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <Container maxWidth={false}>
                <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <Avatar
                                src={rowData?.logo}
                                alt={rowData?.client || "logo"}
                                sx={{ width: 80, height: 80 }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h4" gutterBottom>
                                {rowData?.account || "Account"}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                                {rowData?.client || "Client"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <TopPageGrid
                        key1="Est Value."
                        value1={rowData?.est_value}
                        key2="Detections"
                        value2={rowData?.detections || '0'}
                        key3="Domestic"
                        value3={rowData?.domestic || '0'}
                        key4="Foreign"
                        value4={rowData?.foreign || '0'}

                    />
                </StyledPaper>
                <Box elevation={1} sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                    <Button variant="contained" onClick={() => setModalOpen(true)}>Update Approval</Button>
                    <ReviewModal open={modalOpen} handleClose={() => setModalOpen(false)} client={rowData?.client} account={rowData?.account} clientId={clientId} accountId={accountId} />
                </Box>
                <StyledPaper elevation={1}>
                    <Typography variant="h6" gutterBottom sx={{ pl: 1 }}>
                        Detections
                    </Typography>
                    <DataTable
                        data={detections}
                        defaultVisibleColumns={TABLE_COLS}
                        exportFilename={`detections-${accountId}-${clientId}.xlsx`}

                    />
                </StyledPaper>
            </Container>
        </Box >
    );
};

export default Detections; 