import { useState, useCallback } from 'react';
import { VIEW_MODES, BUTTON_CONFIG, REPOST_COLUMNS, DEFAULT_COLUMNS, PRICING_COLUMNS } from '../constants';

export const useViewManagement = ({ matterId, createTask, addNotification, scrollToTabs }) => {
    const [currentView, setCurrentView] = useState(VIEW_MODES.USES);

    const shouldShowInfoSection = useCallback(() => {
        return [VIEW_MODES.REPOST, VIEW_MODES.PRICING].includes(currentView);
    }, [currentView]);

    const getTableColumns = useCallback(() => {
        switch (currentView) {
            case VIEW_MODES.REPOST:
                return REPOST_COLUMNS;
            case VIEW_MODES.PRICING:
                return PRICING_COLUMNS;
            default:
                return DEFAULT_COLUMNS;
        }
    }, [currentView]);

    const getTableStyle = useCallback(() => {
        const config = BUTTON_CONFIG.find(btn => btn.id === currentView);
        return {
            border: config ? `0px solid ${config.borderColor}` : 'none'
        };
    }, [currentView]);

    const handleViewChange = useCallback((newView) => {
        setCurrentView(newView);
        scrollToTabs();

        if (newView === VIEW_MODES.UPDOWN) {
            if (!matterId) {
                console.error('matterId is not defined');
                addNotification('Error: Matter ID is not available', 'error');
                return;
            }
            createTask('up_down', { matter_id: matterId });
        }
    }, [matterId, createTask, addNotification, scrollToTabs]);



    return {
        currentView,
        getTableColumns,
        getTableStyle,
        handleViewChange,
        shouldShowInfoSection
    };
}; 