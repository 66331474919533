import { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { metricsService } from 'services/api';
import { useTheme } from '@mui/material/styles';
import GCMetrics from 'components/Monitor/GCMetrics';
import SystemMetrics from 'components/Monitor/SystemMetrics';
import DiskIOMetrics from 'components/Monitor/DiskIOMetrics';
import { parsePrometheusText } from 'components/Monitor/parsePrometheusText';

const MonitorCharts = () => {
    const [metrics, setMetrics] = useState(null);
    const [error, setError] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                console.log('Fetching metrics...');
                const response = await metricsService.getMetrics();
                const parsedMetrics = parsePrometheusText(response.data);
                setMetrics(parsedMetrics);
                console.log('Metrics fetched successfully:', parsedMetrics);
            } catch (error) {
                console.error('Failed to fetch metrics:', error);
                setError('Failed to fetch metrics data. Please try again later.');
            }
        };

        fetchMetrics();
        const interval = setInterval(fetchMetrics, 30000);
        return () => clearInterval(interval);
    }, []);



    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography color="error" variant="h6">{error}</Typography>
            </Box>
        );
    }

    if (!metrics) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6">Loading metrics...</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item>
                    <GCMetrics metrics={metrics} theme={theme} />
                </Grid>
                <Grid item>
                    <SystemMetrics metrics={metrics} theme={theme} />
                </Grid>
                <Grid item>
                    <DiskIOMetrics metrics={metrics} theme={theme} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default MonitorCharts;
