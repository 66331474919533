import React, { useState } from 'react';
import { Typography, Button, Box, Fade } from '@mui/material';
import { motion } from 'framer-motion';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

const ReviewStart = ({ item, onStartReview }) => {
    const theme = useTheme();
    const [agreement, setAgreement] = useState(item.meta);
    const IconComponent = item.icon

    const StyledBox = styled(Box)(({ theme }) => ({
        maxWidth: 800,
        margin: '80px auto 0',
        padding: theme.spacing(4),
        background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        backdropFilter: 'blur(10px)',
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[1]
    }));

    const GradientTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: theme.spacing(1)
    }));

    return (
        <Fade in timeout={800}>
            <StyledBox>
                <Box
                    component={motion.div}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    sx={{ textAlign: 'left', minWidth: 800 }}
                >
                    <GradientTypography variant="h5">
                        Agreement Review
                    </GradientTypography>

                    <Typography
                        variant="h5"
                        sx={{
                            mb: 4,
                            fontWeight: 'medium',
                            color: alpha(theme.palette.text.primary, 0.87),
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {item.filename}
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                mb: 1,
                                color: alpha(theme.palette.text.primary, 0.7)
                            }}
                        >
                            Agreement Type
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {agreement?.category}
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 6 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                mb: 1,
                                color: alpha(theme.palette.text.primary, 0.7)
                            }}
                        >
                            Matter
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {item.matter_name}
                        </Typography>
                    </Box>

                    <Button
                        variant="contained"
                        size="large"
                        onClick={onStartReview}
                        sx={{
                            borderRadius: '12px',
                            py: 1.5,
                            px: 4,
                            textTransform: 'none',
                            fontSize: '1.1rem',
                            background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                            boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.25)}`,
                            '&:hover': {
                                background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                                transform: 'translateY(-2px)',
                                boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                            }
                        }}
                    >
                        Begin Review
                    </Button>
                </Box>
            </StyledBox>
        </Fade>
    );
};

export default ReviewStart;