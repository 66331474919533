import { Typography, Grid } from '@mui/material';
import GaugeChart from '../Charts/GaugeChart';

const SystemMetrics = ({ metrics, theme }) => {
    if (!metrics || !metrics.cpu_usage_percent || !metrics.memory_usage_bytes) {
        return <Typography>No system metrics available</Typography>;
    }

    const cpuUsage = metrics.cpu_usage_percent.values[''][0] || 0;
    const memoryUsageBytes = metrics.memory_usage_bytes.values[''][0] || 0;
    const memoryUsageGB = memoryUsageBytes / (1024 * 1024 * 1024);
    const memoryUsagePercent = (memoryUsageGB / 16) * 100;

    return (
        <Grid container spacing={2}>
            <Grid item>
                <GaugeChart
                    value={cpuUsage}
                    title="CPU Usage %"
                    max={100}
                    theme={theme}
                />
            </Grid>
            <Grid item>
                <GaugeChart
                    value={memoryUsagePercent}
                    title="Memory Usage %"
                    max={100}
                    theme={theme}
                />
            </Grid>
        </Grid>
    );
};

export default SystemMetrics;
