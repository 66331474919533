import { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import MonitorCharts from 'components/Monitor/MonitorCharts';
import Logs from 'pages/Logs/Logs';
import Tasks from 'pages/Tasks/Tasks';

const Monitor = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Metrics" />
                    <Tab label="Logs" />
                    <Tab label="Tasks" />
                </Tabs>
            </Box>
            <Box sx={{ mt: 2 }}>
                {activeTab === 0 && <MonitorCharts />}
                {activeTab === 1 && <Logs />}
                {activeTab === 2 && <Tasks />}
            </Box>
        </Box>
    );
};

export default Monitor;