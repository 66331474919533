import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import InputBar from "components/common/InputBar";
import EmailBubble from "./EmailUtils";

// Add this import at the top of the file
import DOMPurify from 'dompurify';

const EmailThread = ({ emails }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (emails && emails.length > 0) {
      setData(emails);
      setFilteredData(emails);
    }
  }, [emails]);

  useEffect(() => {
    const filtered = data.filter((email) =>
      Object.entries(email).some(([key, value]) => {
        if (key === 'message') {
          // Parse HTML content and search within the text
          const div = document.createElement('div');
          div.innerHTML = DOMPurify.sanitize(value);

          return div.textContent.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
    setFilteredData(filtered);
  }, [searchTerm, data]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="body1">No emails to display.</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          padding: 2,
          borderBottom: 1,
          borderColor: "transparent",
          width: "60%",
          margin: "0 auto",
        }}
      >
        <InputBar
          onSubmit={handleSearchChange}
          placeholder="Search emails..."
          value={searchTerm}
          onChange={handleSearchChange}
          mode="search"
          elevation={1}
          autoFocus={true}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
        }}
      >
        {filteredData.map((email, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: email.from_klg ? "flex-end" : "flex-start",
              marginBottom: 2,
            }}
          >
            <EmailBubble email={email} isFromKlg={email.from_klg} />

          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EmailThread;
