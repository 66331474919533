import { useState, useCallback } from 'react';
import { repostService } from 'services/api';
import { createLogger } from 'utils/debug-config';
import { Box, Grid, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const log = createLogger('UseRepostManagement');

export const useRepostManagement = ({ matter, uses, setUses, matterId, currentUser, selectedUseId, clearSelectedUse }) => {
    const [isRepostsOpen, setIsRepostsOpen] = useState(false);
    const theme = useTheme();

    const filterAndSortUses = useCallback((usesData) => {
        // Count occurrences of each song_title_id
        const songTitleCounts = {};
        usesData.forEach(use => {
            songTitleCounts[use.song_title_id] = (songTitleCounts[use.song_title_id] || 0) + 1;
        });

        // Filter uses where song_title_id appears multiple times and sort them
        return usesData
            .filter(use => songTitleCounts[use.song_title_id] > 1)
            .sort((a, b) => {
                // First sort by repost_group nulls last
                if (a.repost_group !== b.repost_group) {
                    return a.repost_group === null ? 1 : b.repost_group === null ? -1 : 0;
                }
                // Then by song_title_id
                if (a.song_title_id !== b.song_title_id) {
                    return a.song_title_id - b.song_title_id;
                }
                // Then by date_uploaded
                if (a.date_uploaded !== b.date_uploaded) {
                    return new Date(a.date_uploaded) - new Date(b.date_uploaded);
                }
                // Finally by id
                return a.id - b.id;
            });
    }, []);

    const handleUpdateRepostGroup = useCallback(async ({ useId, oldGroupId, newGroupId }) => {
        if (!currentUser) {
            log.error('User not authenticated');
            throw new Error('User not authenticated');
        }

        if (!uses || !Array.isArray(uses)) {
            log.error('Uses data is invalid or not loaded');
            throw new Error('Uses data is invalid or not loaded');
        }

        try {
            const repostToUpdate = uses.find(repost => repost?.id === useId);
            if (!repostToUpdate) {
                log.error(`Repost not found for ID: ${useId}`);
                throw new Error('Repost not found');
            }

            const updatedRepost = {
                id: repostToUpdate.id,
                repost_group: newGroupId === "null" ? null : newGroupId,
                opportunity_id: matterId,
                repost: newGroupId !== "null",
                client_id: repostToUpdate.client_id,
                modified_by_user_id: currentUser.id
            };

            await repostService.updateRepostGroup(updatedRepost);

            setUses(prevReposts =>
                Array.isArray(prevReposts)
                    ? prevReposts.map(repost =>
                        repost?.id === useId
                            ? { ...repost, repost_group: newGroupId }
                            : repost
                    )
                    : []
            );

            return true;
        } catch (error) {
            log.error('Failed to update repost group:', error);
            throw error;
        }
    }, [uses, matterId, currentUser, setUses]);

    const handleCloseReposts = useCallback(() => {
        setIsRepostsOpen(false);
        clearSelectedUse();
    }, [clearSelectedUse]);

    const getRepostInfoContent = useCallback(() => {
        const repostCount = uses.filter(use => use.repost).length;
        const reviewedCount = uses.filter(use => use.reviewed).length;

        const pieData = [
            {
                id: 0,
                value: reviewedCount,
                label: 'Reviewed',
                color: theme.palette.success.main
            },
            {
                id: 1,
                value: uses.length - reviewedCount,
                label: 'Not Reviewed',
                color: theme.palette.grey[300]
            }
        ];

        return (
            <Box sx={{
                p: 2.5,
                background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
                backdropFilter: 'blur(8px)',
                borderRadius: '16px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="overline"
                            sx={{
                                letterSpacing: '0.1em',
                                color: alpha(theme.palette.text.primary, 0.7),
                                fontSize: '0.75rem',
                                fontWeight: 600
                            }}
                        >
                            REPOST REVIEW
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        display: 'block',
                                        mb: 0.5
                                    }}>
                                        Uses
                                    </Typography>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700,
                                        lineHeight: 1
                                    }}>
                                        {uses.length}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption" sx={{
                                        color: alpha(theme.palette.text.primary, 0.6),
                                        display: 'block',
                                        mb: 0.5
                                    }}>
                                        Reposts
                                    </Typography>
                                    <Typography variant="h3" sx={{
                                        fontWeight: 700,
                                        lineHeight: 1
                                    }}>
                                        {repostCount}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                {matter?.repost_review_complete && (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Review Status
                                        </Typography>
                                        <Typography sx={{
                                            color: theme.palette.success.main,
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5
                                        }}>
                                            ✓ Completed {matter.repost_review_complete_date &&
                                                new Date(matter.repost_review_complete_date).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Box sx={{ height: 160 }}>
                                    <PieChart
                                        series={[{
                                            data: pieData,
                                            highlightScope: { faded: 'global', highlighted: 'item' },
                                            innerRadius: 60,
                                            paddingAngle: 2,
                                            cornerRadius: 4,
                                        }]}
                                        slotProps={{
                                            legend: {
                                                direction: 'row',
                                                position: { vertical: 'bottom', horizontal: 'middle' },
                                                padding: 0,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 5,
                                                itemGap: 15,
                                            }
                                        }}
                                        height={160}
                                        margin={{ top: 10, bottom: 40, left: 0, right: 0 }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }, [uses, theme, matter]);

    const handleUpdateRepostsReviewed = useCallback(async ({ useIds, isReviewed }) => {
        if (!currentUser) {
            log.error('User not authenticated');
            throw new Error('User not authenticated');
        }

        try {
            const reviewData = useIds.map(id => ({
                id,
                reviewed: isReviewed,
                modified_by_user_id: currentUser.id
            }));

            await repostService.updateRepostsReviewed(reviewData);

            // Update local state
            setUses(prevUses =>
                prevUses.map(use =>
                    useIds.includes(use.id)
                        ? { ...use, reviewed: isReviewed }
                        : use
                )
            );

            return true;
        } catch (error) {
            log.error('Failed to update reposts reviewed status:', error);
            throw error;
        }
    }, [currentUser, matterId, setUses]);

    return {
        repostUses: filterAndSortUses(uses),
        isRepostsOpen,
        setIsRepostsOpen,
        handleUpdateRepostGroup,
        handleCloseReposts,
        getRepostInfoContent,
        handleUpdateRepostsReviewed,
    };
}; 