import { useState, useEffect, memo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Chip,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { Spotify } from "react-spotify-embed";
import { songTitleService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";
import { DataTableGrouped } from "components/common/DataTable";
import { useTheme } from "themes/Palette";

const MakeSpotifyLink = ({ spotify }) => {
  return `https://open.spotify.com/track/${spotify}`;
};

const parseJoeColor = (joecolor) => {
  if (!joecolor) return {};
  const parts = joecolor.split(":");
  const colors = {};
  parts.forEach((part) => {
    if (part.length > 1) {
      const hex = part.slice(0, -1);
      const role = part.slice(-1);
      colors[role] = `#${hex}`;
    }
  });
  return colors;
};

const InfoChip = ({ label, onClick, style }) => (
  <Chip label={label} sx={style} onClick={onClick} />
);

const ActionChip = ({ label, onClick, style }) => (
  <Chip label={label} sx={style} onClick={onClick} />
);

const AlbumCover = ({ coverart_hq, song_title }) => (
  <Grid item xs={12} md={4}>
    {coverart_hq && (
      <img
        src={coverart_hq}
        alt={`${song_title} album cover`}
        style={{ width: "100%", borderRadius: "8px" }}
      />
    )}
  </Grid>
);

const SongInfo = memo(({ songData, handleExternalLink, infoChipStyle, actionChipStyle }) => {
  const { song_title, artist, album, genre, released, record_label, shazam_page, isrc, mlc, mlc_id, ascap, bmi, spotify } = songData;

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h2" fontWeight="bold" mb={2}>
        {song_title || "Unknown Title"}
      </Typography>
      <Typography variant="h4" mb={3}>
        {artist || "Unknown Artist"}
      </Typography>
      {album && (
        <Typography variant="body1" mb={1}>
          Album: {album}
        </Typography>
      )}
      {genre && (
        <Typography variant="body1" mb={1}>
          Genre: {genre}
        </Typography>
      )}
      {released && (
        <Typography variant="body1" mb={3}>
          Released: {released}
        </Typography>
      )}

      <Stack direction="row" spacing={1} mb={3}>
        {isrc && (
          <InfoChip
            label={`ISRC: ${isrc}`}
            onClick={() => handleExternalLink(`https://isrc.soundexchange.com/?tab=%22code%22&isrcCode=${isrc}&showReleases=1&start=0&number=10`)}
            style={infoChipStyle}
          />
        )}
        {mlc && (
          <InfoChip
            label={`MLC: ${mlc}`}
            onClick={() => handleExternalLink(`https://portal.themlc.com/catalog/work/${mlc_id}`)}
            style={infoChipStyle}
          />
        )}
        {ascap && (
          <InfoChip
            label={`ASCAP: ${ascap}`}
            onClick={() => handleExternalLink(`https://www.ascap.com/repertory#/ace/search/workID/${ascap}`)}
            style={infoChipStyle}
          />
        )}
        {bmi && (
          <InfoChip
            label={`BMI: ${bmi}`}
            onClick={() => handleExternalLink(`https://repertoire.bmi.com/Search/Search?selectMainSearch=BMI+Work+ID&Main_Search=BMI+Work+ID&View_Count=100&Main_Search_Text=${bmi}&Search_Type=all`)}
            style={infoChipStyle}
          />
        )}
      </Stack>

      {record_label && (
        <Typography variant="body1" mb={3} style={{ color: "#121212" }}>
          Record Label: {record_label}
        </Typography>
      )}

      {spotify && (
        <Box mb={3}>
          <Spotify link={MakeSpotifyLink({ spotify })} wide />
        </Box>
      )}

      <Stack direction="row" spacing={2}>
        {shazam_page && (
          <ActionChip
            label="Open in Shazam"
            onClick={() => handleExternalLink(shazam_page)}
            style={actionChipStyle}
          />
        )}
        {spotify && (
          <ActionChip
            label="Open in Spotify"
            onClick={() => handleExternalLink(`https://open.spotify.com/track/${spotify}`)}
            style={actionChipStyle}
          />
        )}
      </Stack>
    </Grid>
  );
});


const SongUsageTable = ({ songTitlesTable, handleRowClick }) => (
  <Box sx={{ paddingTop: 4 }}>
    {songTitlesTable && songTitlesTable.length > 0 ? (
      <DataTableGrouped
        data={songTitlesTable}
        onRowClick={handleRowClick}
        groupBy="matter"
        itemName="use"
      />
    ) : (
      <Typography>Does not appear in any matters</Typography>
    )}
  </Box>
);

const SongTitle = () => {
  const [songData, setSongData] = useState(null);
  const [songTitlesTable, setSongTitlesTable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const location = useLocation();
  const songTitleId = location.state?.songTitleId || id;
  const shazamId = location.state?.shazamId || id;
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [songResponse, usageResponse] = await Promise.all([
          songTitleService.getSongTitle(songTitleId),
          songTitleService.getSongTitleTable(shazamId),
        ]);

        setSongData(songResponse.data[0]);
        setSongTitlesTable(usageResponse.data);
      } catch (err) {
        setError(err.response?.data?.detail || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [songTitleId]);

  const handleRowClick = (row) => {
    navigate(`/use/${row.id}`, {
      state: { useId: row.id },
    });
  };

  const handleExternalLink = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  if (error) {
    return <Typography color="error" align="center">Error: {error}</Typography>;
  }

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <LoadingSpinner />
      </Box>
    );
  }

  if (!songData) {
    return <Typography align="center">No song data available.</Typography>;
  }

  const colors = parseJoeColor(songData.joecolor);
  const backgroundColor = colors.b || "#121212";
  const primaryColor = colors.p || "#ffffff";
  const secondaryColor = colors.s || "#b3b3b3";

  const infoChipStyle = {
    backgroundColor: primaryColor,
    color: secondaryColor,
    "&:hover": {
      color: primaryColor,
      backgroundColor: secondaryColor,
    },
  };

  const actionChipStyle = {
    backgroundColor: secondaryColor,
    color: primaryColor,
    "&:hover": {
      color: secondaryColor,
      backgroundColor: primaryColor,
    },
  };

  return (
    <Box sx={{ minHeight: "100vh", background: "default.paper", padding: 4 }}>
      <Grid container spacing={4}>
        <AlbumCover coverart_hq={songData.coverart_hq} song_title={songData.song_title} />
        <SongInfo
          songData={songData}
          handleExternalLink={handleExternalLink}
          infoChipStyle={infoChipStyle}
          actionChipStyle={actionChipStyle}
        />
      </Grid>
      <SongUsageTable songTitlesTable={songTitlesTable} handleRowClick={handleRowClick} />
    </Box>
  );
};

export default SongTitle;
