import { useState, useEffect, useCallback } from "react";
import { DataTableGrouped } from "./common/DataTable";
import { Typography, Box } from "@mui/material";
import DragAndDrop from 'components/common/DragAndDrop/DragAndDrop';
import { useMatterFiles } from 'services/api';


const formatDate = (date) => {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

const formatUrl = (driveId) => {
  return `https://drive.google.com/file/d/${driveId}`;
};

const parseFiles = (data) => {
  return data
    .map((item) => {
      const dateObject = new Date(item.date);
      return {
        ...item,
        dateObject: dateObject,
        date: formatDate(dateObject),
        url: formatUrl(item.drive_id)
      };
    })
    .sort((a, b) => {
      const sortableCategories = ['TOLLING', 'SETTLEMENT'];
      if (sortableCategories.includes(a.category) && sortableCategories.includes(b.category)) {
        // Sort by date (most recent first)
        const dateComparison = b.dateObject - a.dateObject;
        if (dateComparison !== 0) return dateComparison;

        // If dates are equal, sort by status alphabetically
        return a.status.localeCompare(b.status);
      }
      // For other categories, maintain original order
      return 0;
    })
    .map(({ dateObject, ...item }) => item);
};

const Files = ({ matter }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const matterId = matter.id;
  const matterName = matter.name;

  // Replace the axios call with React Query hook
  const { data: file_data, isLoading } = useMatterFiles(matterId);

  // Update second useEffect to watch the query data
  useEffect(() => {
    if (file_data && file_data.length > 0) {
      setData(parseFiles(file_data));
      setColumns(["category", "status", "filename", "url"]);
    }
  }, [file_data]);

  const onRowClick = useCallback((rowData) => {
    console.log('Row clicked:', rowData);

    if (rowData?.row?.url) {
      console.log('Opening URL:', rowData.row.url);
      window.open(rowData.row.url, '_blank', 'noopener,noreferrer');
    } else {
      console.log('No URL found for this row');
    }
  }, []);

  if (isLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (!file_data || file_data.length === 0) {
    return (
      <>
        <Typography variant="h6">No file data found.</Typography>
        <Box sx={{ mt: 2 }}>
          <DragAndDrop matterId={matterId} matterName={matterName} />
        </Box>
      </>
    );
  }

  return (
    <>
      <DataTableGrouped
        data={data}
        onRowClick={onRowClick}
        columns={columns}
        groupBy="category"
        itemName="file"
      />
      <Box sx={{ mt: 2 }}>
        <DragAndDrop matterId={matterId} matterName={matterName} />
      </Box>
    </>
  );
};

export default Files;
