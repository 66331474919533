import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Notifications from 'components/common/Notifications/Notifications';
import { useAuth } from "contexts/AuthContext";
import React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import Tooltip from '@mui/material/Tooltip';

const TestingDisclaimer = () => {
  return (
    <Tooltip
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
        p: 5,
        '& .MuiTooltip-tooltip': {
          p: 2
        }
      }}
      title={
        <Typography variant="body1" color="text.primary">
          We are still in test mode. No changes will be saved.
        </Typography>
      }
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'warning.main',
          color: 'warning.contrastText',
          py: 0.5,
          px: 2,
          borderRadius: 1,
          mx: 2,
          cursor: 'pointer'
        }}
      >
        <WarningAmberIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 'medium',
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          }}
        >
          Test Environment
        </Typography>
      </Box>
    </Tooltip>
  );
}

const Header = ({ links, isDarkMode, toggleTheme }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, currentUser } = useAuth();


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/user-profile');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSystemMonitor = async () => {
    if (currentUser.access_level === 'sys_admin') {
      navigate('/system-monitor');
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Logo />
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {links.map((link) => (
            <Button
              key={link.label}
              component={link.openInNewTab ? 'a' : RouterLink}
              to={link.to}
              href={link.openInNewTab ? link.to : undefined}
              target={link.openInNewTab ? "_blank" : undefined}
              rel={link.openInNewTab ? "noopener noreferrer" : undefined}
              sx={{ ml: 2 }}
              color="inherit"
            >
              {link.label}
            </Button>
          ))}
        </Box>
      </Box>
      <TestingDisclaimer />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={toggleTheme} sx={{ ml: 2, color: 'inherit' }}>
          {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Notifications />
        <IconButton
          onClick={handleMenu}
          sx={{ ml: 2 }}
        >
          <Avatar alt="User Name" src="/path-to-avatar.jpg" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          {currentUser && currentUser.access_level === 'sys_admin' && <MenuItem onClick={handleSystemMonitor}>System Monitor</MenuItem>}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
