import React, { useState, useMemo } from 'react';
import { Box, Button, Grid, Card, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import { VideoGridGroup } from './VideoGridGroup';
import { createLogger } from 'utils/debug-config';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const log = createLogger('VideoGrid');

/**
 * A responsive grid component for displaying grouped video content.
 * Videos are organized by repost groups and can be played simultaneously within their group.
 * 
 * @component
 * @param {Object} props
 * @param {React.ReactElement} props.videoPlayer - Video player component to be cloned for each video
 * @param {string} props.urlField - Field name in repost object containing the video URL
 * @param {Array<Object>} props.repostGroup - Array of repost objects to display
 * @param {Object} props.repostGroup[].repost_group - Group identifier for the repost
 * @param {string} props.repostGroup[].id - Unique identifier for the repost
 * @param {Function} props.onPrevious - Callback function for navigating to previous group
 * @param {Function} props.onNext - Callback function for navigating to next group
 * @param {boolean} props.isFirstGroup - Flag indicating if current group is the first group
 * @param {boolean} props.isLastGroup - Flag indicating if current group is the last group
 * @param {boolean} props.useRepostGroups - Flag indicating if repost groups should be used
 * @param {Function} props.onUpdateRepostGroup - Callback function for updating repost groups
 * 
 * @returns {React.ReactElement} A responsive grid of videos organized by repost groups
 * 
 * @example
 * <VideoGrid
 *   videoPlayer={<VideoPlayer />}
 *   urlField="video_url"
 *   repostGroup={repostData}
 *   onPrevious={() => {}}
 *   onNext={() => {}}
 *   isFirstGroup={true}
 *   isLastGroup={false}
 *   useRepostGroups={true}
 *   onUpdateRepostGroup={() => {}}
 * />
 */
const VideoGrid = ({
    videoPlayer,
    urlField,
    repostGroup,
    onPrevious,
    onNext,
    isFirstGroup,
    isLastGroup,
    useRepostGroups,
    onUpdateRepostGroup,
    onUpdateRepostsReviewed
}) => {
    const [autoplayGroup, setAutoplayGroup] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [showNewGroupArea, setShowNewGroupArea] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [pendingVideoMove, setPendingVideoMove] = useState(null);
    const [optimisticUpdates, setOptimisticUpdates] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [uniqueUseGroupExists, setUniqueUseGroupExists] = useState(false);

    // Create memoized version of grouped data that includes optimistic updates
    const groupedByRepostGroup = useMemo(() => {
        const groups = repostGroup.reduce((acc, repost) => {
            const groupId = optimisticUpdates[repost.id]?.newGroupId ?? repost.repost_group;
            if (!acc[groupId]) {
                acc[groupId] = [];
            }
            acc[groupId].push(repost);
            return acc;
        }, {});

        log.debug('Checking for unique use and repost groups');
        const hasUniqueUse = Object.values(groups).some(group =>
            group[0].repost_group === null
        );
        const hasRepostGroup = Object.values(groups).some(group =>
            (group[0].repost_group !== null)
        );
        log.debug(`Found unique use group: ${hasUniqueUse}, found repost group: ${hasRepostGroup}`);

        setUniqueUseGroupExists(hasUniqueUse);

        return groups;
    }, [repostGroup, optimisticUpdates]);

    const handleDragStart = (event) => {
        setIsDragging(true);
        setActiveId(event.active.id);
        setShowNewGroupArea(true);
    };

    const handleDragEnd = async (event) => {
        const { active, over } = event;
        setIsDragging(false);
        setShowNewGroupArea(false);

        if (over && active.id !== over.id) {
            const useId = active.id;
            const targetGroupId = over.data.current.groupId;
            const draggedVideo = repostGroup.find(r => r.id === useId);

            if (targetGroupId === 'new-group') {
                // Skip modal if:
                // 1. A unique use group exists (new group must be a repost)
                // 2. The dragged video has null repost_group (creating first repost group)
                if (uniqueUseGroupExists || draggedVideo.repost_group === null) {
                    await onUpdateRepostGroup({
                        useId: useId,
                        newGroupId: draggedVideo.repost_group || useId
                    });
                } else {
                    setPendingVideoMove({ useId });
                    setOpenModal(true);
                    return;
                }
            } else {
                // Apply optimistic update
                setOptimisticUpdates(prev => ({
                    ...prev,
                    [useId]: { newGroupId: targetGroupId }
                }));

                try {
                    await onUpdateRepostGroup({
                        useId: useId,
                        newGroupId: targetGroupId
                    });

                    // Clear optimistic update on success
                    setOptimisticUpdates(prev => {
                        const updated = { ...prev };
                        delete updated[useId];
                        return updated;
                    });
                } catch (error) {
                    // Revert optimistic update on failure
                    setOptimisticUpdates(prev => {
                        const updated = { ...prev };
                        delete updated[useId];
                        return updated;
                    });
                }
            }
        }

        setActiveId(null);
    };

    const handleAutoplayToggle = (repostGroup) => {
        setAutoplayGroup(prevGroup => prevGroup === repostGroup ? null : repostGroup);
    };

    const handleModalDecision = (isUniqueUse) => {
        if (pendingVideoMove) {
            onUpdateRepostGroup({
                useId: pendingVideoMove.useId,
                newGroupId: isUniqueUse ? null : Math.max(...Object.keys(groupedByRepostGroup).map(Number), 0) + 1
            });
        }
        setOpenModal(false);
        setPendingVideoMove(null);
    };

    const handleReviewToggle = async () => {
        const useIds = repostGroup.map(repost => repost.id);
        const isCurrentlyReviewed = repostGroup.every(repost => repost.reviewed);

        try {
            await onUpdateRepostsReviewed({
                useIds,
                isReviewed: !isCurrentlyReviewed
            });
        } catch (error) {
            log.error('Failed to toggle review status:', error);
        }
    };

    const isGroupReviewed = useMemo(() =>
        repostGroup.every(repost => repost.reviewed),
        [repostGroup]
    );

    return (
        <Card sx={{ bgcolor: 'background.paper', p: 2, maxHeight: '80vh', overflow: 'auto' }}>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
                <Button
                    variant="outlined"
                    startIcon={isGroupReviewed ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                    onClick={handleReviewToggle}
                    color={isGroupReviewed ? "success" : "primary"}
                >
                    {isGroupReviewed ? "Reviewed" : "Mark as Reviewed"}
                </Button>
            </Stack>

            <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}
            >
                <Grid container spacing={0}>
                    {Object.entries(groupedByRepostGroup).map(([groupId, reposts]) => (
                        <VideoGridGroup
                            key={groupId}
                            groupId={groupId}
                            reposts={reposts}
                            videoPlayer={videoPlayer}
                            urlField={urlField}
                            autoplayGroup={autoplayGroup}
                            onAutoplayToggle={handleAutoplayToggle}
                            onUpdateRepostGroup={onUpdateRepostGroup}
                        />
                    ))}

                    {/* New Group Drop Area */}
                    {showNewGroupArea && (
                        <VideoGridGroup
                            key="new-group"
                            groupId="new-group"
                            reposts={[]}
                            videoPlayer={videoPlayer}
                            urlField={urlField}
                            isNewGroupArea={true}
                        />
                    )}
                </Grid>

                <DragOverlay>
                    {activeId && (
                        <Box sx={{
                            width: 200,
                            height: 200,
                            backgroundColor: 'background.paper',
                            boxShadow: 3,
                            borderRadius: 1

                        }}>
                            {/* Show a preview of the dragged video */}
                            {repostGroup.find(r => r.id === activeId) && (
                                React.cloneElement(videoPlayer, {
                                    src: repostGroup.find(r => r.id === activeId)[urlField],
                                    autoplay: false,
                                    isDragging: true,

                                })
                            )}
                        </Box>
                    )}
                </DragOverlay>
            </DndContext>

            {/* Add Modal */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>New Group Type</DialogTitle>
                <DialogContent>
                    <Typography>Is this a Unique Use or a Repost Group?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalDecision(true)}>Unique Use</Button>
                    <Button onClick={() => handleModalDecision(false)}>Repost Group</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default VideoGrid;
