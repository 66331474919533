// Modal.js
import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';
import SettlementAgreementFields from './SettlementAgreementFields';
import TollingAgreementFields from './TollingAgreementFields';
import { useUploadMatterFile } from 'services/api';
import { useQueryClient } from '@tanstack/react-query';

const Modal = ({ isOpen, onClose, onDocumentTypeChange, matterId, matterName, file }) => {
    const [documentType, setDocumentType] = useState('');
    const [formData, setFormData] = useState({});
    const queryClient = useQueryClient();
    const uploadMutation = useUploadMatterFile();

    const handleDocumentTypeChange = (e) => {
        const type = e.target.value;
        setDocumentType(type);
        onDocumentTypeChange(type);
    };

    const handleSave = async () => {
        if (file && matterId && matterName && documentType) {
            const metadata = {
                matter_id: matterId,
                matter: matterName,
                filename: file.name,
                document_type: documentType,
                ...formData
            };

            const optimisticFile = {
                id: 'temp-' + Date.now(),
                matter_id: matterId,
                matter: matterName,
                filename: file.name,
                category: documentType,
                status: 'PENDING',
                date: new Date().toISOString(),
                drive_id: null,
                ...formData
            };

            try {
                await uploadMutation.mutateAsync(
                    { metadata, file },
                    {
                        onMutate: async () => {
                            await queryClient.cancelQueries(['matterFiles', matterId]);

                            const previousFiles = queryClient.getQueryData(['matterFiles', matterId]);

                            queryClient.setQueryData(['matterFiles', matterId], old => {
                                return [...(old || []), optimisticFile];
                            });

                            return { previousFiles };
                        },
                        onError: (err, newFile, context) => {
                            queryClient.setQueryData(['matterFiles', matterId], context.previousFiles);
                        },
                        onSettled: () => {
                            queryClient.invalidateQueries(['matterFiles', matterId]);
                        },
                    }
                );
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        handleClose();
    };

    const handleClose = () => {
        onClose();
        setDocumentType('');
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Select Document Type</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ mb: 2 }}>Matter: {matterName}</Typography>
                {file && <Typography variant="body1" sx={{ mb: 2 }}>Filename: {file.name}</Typography>}

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="document-type-label">Document Type</InputLabel>
                    <Select
                        labelId="document-type-label"
                        value={documentType}
                        onChange={handleDocumentTypeChange}
                        label="Document Type"
                    >
                        <MenuItem value="">Select a document type</MenuItem>
                        <MenuItem value="TOLLING">Tolling Agreement</MenuItem>
                        <MenuItem value="SETTLEMENT">Settlement Agreement</MenuItem>
                    </Select>
                </FormControl>
                {documentType === 'TOLLING' && <TollingAgreementFields onDataChange={(data) => setFormData(data)} />}
                {documentType === 'SETTLEMENT' && <SettlementAgreementFields onDataChange={(data) => setFormData(data)} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;