import { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Snackbar,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { authService } from "services/api";
import { styled } from "@mui/material/styles";
import { useTheme } from "themes/Palette";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Custom styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    },
  },
  '& .MuiInputLabel-root': {
    padding: theme.spacing(0, 0),
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  marginBottom: theme.spacing(0),
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset`,
    '-webkit-text-fill-color': theme.palette.text.primary,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
    color: theme.palette.common.white,
  },
}));

const LoginForm = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const navigate = useNavigate();
  const { logo, theme } = useTheme();


  // Add this useEffect hook to disable scrolling
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await authService.login(formData.username, formData.password);
      localStorage.setItem("token", response.data.access_token);
      setIsNavigating(true);
      setTimeout(() => navigate("/search"), 500);
    } catch (err) {
      setError(err.response?.data?.detail || "An error occurred during login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {!isNavigating && (
        <motion.div
          initial={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex", // Add this
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "background.default",
              backgroundSize: "60px 60px",
              backgroundPosition: "0 0, 30px 30px",
              px: { xs: 2, sm: 4 },
              overflow: "auto",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Paper
                elevation={6}
                sx={{
                  p: { xs: 3, sm: 4, md: 6 }, // Responsive padding
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: 450,
                  width: "100%", // Changed from 90% to 100%
                  maxHeight: "90vh", // Add this
                  overflowY: "auto", // Add this
                  borderRadius: "15px",
                  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
                }}
              >
                <LoginHeader logo={logo} />
                <LoginFormFields
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
                <ForgotPasswordLink />
              </Paper>
            </motion.div>
            <ErrorSnackbar error={error} setError={setError} />
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const LoginHeader = ({ logo }) => (
  <>
    <img
      src={logo}
      alt="Law Firm Logo"
      style={{ width: '100%', maxWidth: 120, marginBottom: 30 }}
    />
    <Typography variant="subtitle1" component="h1" gutterBottom sx={{ fontWeight: 100, textAlign: 'center' }}>
      Client Portal Login
    </Typography>

  </>
);

const LoginFormFields = ({ formData, handleInputChange, handleSubmit, loading }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ mt: 3, width: "100%" }}
    >
      <StyledTextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        value={formData.username}
        onChange={handleInputChange}
        spellCheck={false}
      />
      <StyledTextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        value={formData.password}
        onChange={handleInputChange}
        spellCheck={false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                color="primary"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ mt: 4 }}>
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            mb: 3,
            py: { xs: 1.5, sm: 1.5 }, // Responsive padding
            bgcolor: "primary.main",
            '&:hover': {
              bgcolor: "primary.dark"
            },
            // Add these styles to maintain button shape
            height: 48, // Set a fixed height
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={loading}
        >
          {loading ? "Signing In..." : "Sign In"}
        </StyledButton>
      </Box>
    </Box>
  );
};

const ForgotPasswordLink = () => (
  <Typography variant="body2" align="center" sx={{ mt: 2 }}>
    Forgot your password?{' '}
    <Link
      component={RouterLink}
      to="/forgot-password"
      underline="none"
      sx={{ color: (theme) => theme.palette.link.main }}
    >
      Reset it here
    </Link>
  </Typography>
);

const ErrorSnackbar = ({ error, setError }) => (
  <Snackbar
    open={!!error}
    autoHideDuration={6000}
    onClose={() => setError("")}
    message={error}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  />
);

export default LoginForm;
