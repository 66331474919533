import { Box, Typography } from '@mui/material';

const BarChart = ({ data, layout, style = {} }) => {
    const maxValue = Math.max(...data[0].y);
    const barWidth = 60;
    const spacing = 20;
    const chartHeight = 300;

    const getBarHeight = (value) => {
        return (value / maxValue) * chartHeight;
    };

    return (
        <Box sx={{
            width: '100%',
            height: style.height || '400px',
            p: 2,
            bgcolor: layout.paper_bgcolor
        }}>
            <Typography variant="h6" sx={{ color: layout.font.color, mb: 2 }}>
                {layout.title}
            </Typography>

            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                height: chartHeight,
                gap: `${spacing}px`,
                mb: 2
            }}>
                {data[0].x.map((label, index) => (
                    <Box key={label} sx={{ textAlign: 'center' }}>
                        <Box
                            sx={{
                                width: barWidth,
                                height: getBarHeight(data[0].y[index]),
                                bgcolor: data[0].marker.color[index],
                                transition: 'height 0.3s ease',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        />
                        <Typography sx={{ color: layout.font.color, mt: 1 }}>
                            {label}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Typography sx={{ color: layout.font.color }}>
                {layout.xaxis.title}
            </Typography>
        </Box>
    );
};

export default BarChart; 