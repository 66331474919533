import React from 'react';
import { ListItemButton, Typography } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ViewListIcon from '@mui/icons-material/ViewList';

const NotificationActions = ({ onClearAll, onViewAll }) => (
    <>
        <ListItemButton
            onClick={onClearAll}
            sx={{
                justifyContent: 'center',
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                py: 1
            }}
        >
            <ClearAllIcon sx={{ mr: 1, fontSize: '0.9rem' }} />
            <Typography variant="body2" color="text.secondary">
                Clear all notifications
            </Typography>
        </ListItemButton>
        <ListItemButton
            onClick={onViewAll}
            sx={{
                justifyContent: 'center',
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                py: 1
            }}
        >
            <ViewListIcon sx={{ mr: 1, fontSize: '0.9rem' }} />
            <Typography variant="body2" color="text.secondary">
                View all notifications
            </Typography>
        </ListItemButton>
    </>
);

export default NotificationActions;