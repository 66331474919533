// SettlementAgreementFields.js
import React, { useState } from 'react';
import {
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
} from '@mui/material';

const SettlementAgreementFields = ({ onDataChange }) => {
    const [effectiveDate, setEffectiveDate] = useState('');
    const [paymentDue, setPaymentDue] = useState('');
    const [releaseType, setReleaseType] = useState('');
    const [pricing, setPricing] = useState('');

    // Update parent component whenever form data changes
    React.useEffect(() => {
        onDataChange({
            effective_date: effectiveDate,
            payment_due: paymentDue,
            release_type: releaseType,
            pricing: pricing,
        });
    }, [effectiveDate, paymentDue, releaseType, pricing, onDataChange]);

    return (
        <Box mt={2}>
            <Typography variant="h6" gutterBottom>
                Settlement Agreement Fields
            </Typography>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Effective Date"
                    type="date"
                    value={effectiveDate}
                    onChange={(e) => setEffectiveDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Payment Due"
                    type="date"
                    value={paymentDue}
                    onChange={(e) => setPaymentDue(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="release-type-label">Release Type</InputLabel>
                <Select
                    labelId="release-type-label"
                    value={releaseType}
                    onChange={(e) => setReleaseType(e.target.value)}
                    label="Release Type"
                >
                    <MenuItem value="">Select a release type</MenuItem>
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="limited">Limited</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="pricing-label">Pricing</InputLabel>
                <Select
                    labelId="pricing-label"
                    value={pricing}
                    onChange={(e) => setPricing(e.target.value)}
                    label="Pricing"
                >
                    <MenuItem value="">Select pricing</MenuItem>
                    <MenuItem value="independent">Independent Valuation</MenuItem>
                    <MenuItem value="flat">Flat Rate</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default SettlementAgreementFields;