import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

/**
 * @component VideoPlayer
 * @description A component for rendering a video player with autoplay functionality.
 * @param {string} src - The source URL of the video to be played.
 * @param {boolean} autoplay - Whether the video should start playing automatically.
 * @param {boolean} isDragging - Whether the video player is currently being dragged.
 * @param {boolean} isMuted - Whether the video is muted.
 * @returns {JSX.Element} A video element with controls and autoplay functionality.
 */
const VideoPlayer = React.forwardRef(({ src, autoplay, isDragging, isMuted = false }, ref) => {
    const videoRef = useRef(null);
    const previousSrc = useRef(src);
    const currentTime = useRef(0);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    // Use the forwarded ref or the internal ref
    const resolvedRef = ref || videoRef;

    // Combined video loading and source management effect
    useEffect(() => {
        if (!resolvedRef.current) return;

        const video = resolvedRef.current;
        console.log(`[VideoPlayer] Initializing video with src: ${src}`);
        console.log(`[VideoPlayer] Initial readyState: ${video.readyState}`);

        const handleLoadStart = () => {
            console.log(`[VideoPlayer] Load started for: ${src}`);
            setIsLoading(true);
        };

        const handleCanPlay = () => {
            console.log(`[VideoPlayer] Can play event for: ${src}`);
            console.log(`[VideoPlayer] Current readyState: ${video.readyState}`);
            setIsLoading(false);
        };

        const handleLoadedMetadata = () => {
            console.log(`[VideoPlayer] Metadata loaded for: ${src}`);
            console.log(`[VideoPlayer] Video duration: ${video.duration}`);
            if (resolvedRef.current) {
                resolvedRef.current.currentTime = currentTime.current;
                if (autoplay && !isDragging) {
                    console.log(`[VideoPlayer] Attempting autoplay for: ${src}`);
                    resolvedRef.current.play().catch(error => {
                        console.error(`[VideoPlayer] Autoplay prevented for ${src}:`, error);
                    });
                }
            }
        };

        const handleError = (error) => {
            console.error(`[VideoPlayer] Error loading video ${src}:`, {
                error: video.error,
                networkState: video.networkState,
                readyState: video.readyState
            });
        };

        const handleStalled = () => {
            console.warn(`[VideoPlayer] Video playback stalled for: ${src}`, {
                networkState: getNetworkStateDescription(video.networkState),
                readyState: getReadyStateDescription(video.readyState),
                buffered: getBufferedRanges(video),
                currentTime: video.currentTime,
                duration: video.duration,
                paused: video.paused,
                ended: video.ended,
                seeking: video.seeking,
                error: video.error ? {
                    code: video.error.code,
                    message: video.error.message
                } : null
            });
        };

        const handleWaiting = () => {
            console.log(`[VideoPlayer] Video waiting for more data: ${src}`);
            setIsLoading(true);
        };

        // Set up event listeners
        video.addEventListener('loadstart', handleLoadStart);
        video.addEventListener('canplay', handleCanPlay);
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('error', handleError);
        video.addEventListener('stalled', handleStalled);
        video.addEventListener('waiting', handleWaiting);

        // Handle source changes
        if (src !== previousSrc.current) {
            console.log(`[VideoPlayer] Source changed from ${previousSrc.current} to ${src}`);
            currentTime.current = video.currentTime;
            video.pause();
            video.removeAttribute('src');
            video.load();
            video.src = src;
            previousSrc.current = src;
        }

        // Set initial loading state
        const initialReadyState = !video.readyState || video.readyState < 2;
        console.log(`[VideoPlayer] Setting initial loading state: ${initialReadyState}`);
        setIsLoading(initialReadyState);

        // Handle playback state
        if (!isDragging && autoplay) {
            console.log(`[VideoPlayer] Initiating autoplay for: ${src}`);
            video.play().catch(error => {
                console.error(`[VideoPlayer] Playback error for ${src}:`, error);
            });
        } else {
            video.pause();
        }

        return () => {
            console.log(`[VideoPlayer] Cleaning up video player for: ${src}`);
            currentTime.current = video.currentTime;
            video.removeEventListener('loadstart', handleLoadStart);
            video.removeEventListener('canplay', handleCanPlay);
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            video.removeEventListener('error', handleError);
            video.removeEventListener('stalled', handleStalled);
            video.removeEventListener('waiting', handleWaiting);
        };
    }, [src, autoplay, isDragging, isMuted, resolvedRef]);

    // Store current time before unmount
    useEffect(() => {
        return () => {
            if (resolvedRef.current) {
                currentTime.current = resolvedRef.current.currentTime;
            }
        };
    }, [resolvedRef]);

    // Simplified drag handling - just freeze the current frame
    useEffect(() => {
        if (resolvedRef.current) {
            if (isDragging) {
                resolvedRef.current.pause();
            } else if (autoplay) {
                resolvedRef.current.play().catch(error => {
                    console.log("Playback resume error:", error);
                });
            }
        }
    }, [isDragging, autoplay, resolvedRef]);

    // Update video element render props
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            cursor: isDragging ? 'grabbing' : 'auto',
        }}>

            <video
                ref={resolvedRef}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    pointerEvents: isDragging ? 'none' : 'auto',
                    opacity: isLoading ? 0.3 : 1,
                    transition: 'opacity 0.3s ease-in-out',
                    display: 'block', // Prevents bottom margin
                    userSelect: isDragging ? 'none' : 'auto',


                }}
                controls={!isDragging}
                muted={isMuted}
                src={src}
                preload="auto"
                playsInline
            >
                Your browser does not support the video tag.
            </video>
            {isLoading && (
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}>
                    {/* Optional: Add a loading spinner or placeholder here */}
                </div>
            )}
        </div>
    );
});

// Wrap the forwarded ref component with memo
const MemoizedVideoPlayer = React.memo(VideoPlayer, (prevProps, nextProps) => {
    return (
        prevProps.src === nextProps.src &&
        prevProps.autoplay === nextProps.autoplay &&
        prevProps.isDragging === nextProps.isDragging &&
        prevProps.isMuted === nextProps.isMuted
    );
});

MemoizedVideoPlayer.displayName = 'VideoPlayer';

// Helper functions to provide human-readable state descriptions
const getNetworkStateDescription = (state) => {
    const states = {
        0: 'NETWORK_EMPTY - No data has been loaded',
        1: 'NETWORK_IDLE - Network is idle',
        2: 'NETWORK_LOADING - Network is loading data',
        3: 'NETWORK_NO_SOURCE - No video source found'
    };
    return states[state] || `Unknown network state: ${state}`;
};

const getReadyStateDescription = (state) => {
    const states = {
        0: 'HAVE_NOTHING - No information about media',
        1: 'HAVE_METADATA - Metadata has been loaded',
        2: 'HAVE_CURRENT_DATA - Data for current position available',
        3: 'HAVE_FUTURE_DATA - Data for current and next frame available',
        4: 'HAVE_ENOUGH_DATA - Enough data available for playback'
    };
    return states[state] || `Unknown ready state: ${state}`;
};

const getBufferedRanges = (video) => {
    const ranges = [];
    for (let i = 0; i < video.buffered.length; i++) {
        ranges.push({
            start: video.buffered.start(i),
            end: video.buffered.end(i)
        });
    }
    return ranges;
};

export default MemoizedVideoPlayer;