import React from 'react';
import { Box, TextField, Select, MenuItem, Button, FormControl } from '@mui/material';

const TASK_TYPES = [
    { value: 'export_report', label: 'Export Report' },
    { value: 'up_down', label: 'Up Down' },
    { value: 'ten_seconds', label: '10 Seconds Task' },
    { value: 'thirty_seconds', label: '30 Seconds Task' }
];

const TASK_DEFAULTS = {
    'export_report': { matter_id: '' },
    "up_down": { matter_id: '' },
    'ten_seconds': { message: '' },
    'thirty_seconds': { message: '' }
};

const TaskInputForm = ({ kwargs, setKwargs, taskType, setTaskType, createTask }) => {
    // Convert kwargs object to string for display
    const kwargsString = kwargs ? JSON.stringify(kwargs, null, 2) : '';

    const handleSubmit = (e) => {
        e.preventDefault();
        if (kwargs && taskType) {
            createTask(taskType, kwargs);
            setKwargs({});
        }
    };

    const handleKwargsChange = (e) => {
        try {
            const parsedKwargs = JSON.parse(e.target.value);
            setKwargs(parsedKwargs);
        } catch (error) {
            // Handle invalid JSON input
            console.error('Invalid JSON input');
        }
    };

    const handleTaskTypeChange = (event) => {
        const newTaskType = event.target.value;
        setTaskType(newTaskType);
        // Set default kwargs template when task type changes
        setKwargs(TASK_DEFAULTS[newTaskType] || {});
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Select
                    value={taskType}
                    onChange={handleTaskTypeChange}
                    size="small"
                    sx={{ minWidth: 200 }}
                >
                    {TASK_TYPES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                value={kwargsString}
                onChange={handleKwargsChange}
                placeholder="Enter JSON kwargs"
                size="small"
                sx={{ minWidth: 300 }}
                multiline
            />

            <Button
                type="submit"
                variant="contained"
                disabled={!kwargs || !taskType}
            >
                Create Task
            </Button>
        </Box>
    );
};

export default TaskInputForm;
