// frontend/src/pages/Approvals/Approvals.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Typography, MenuItem, FormControl, Select, Container, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingSpinner from 'utils/LoadingSpinner';
import { approvalService } from 'services/api';
import { DataTable } from "components/common/DataTable";
import { useNavigate, useParams } from 'react-router-dom';
import ImportApprovals from './ImportApprovals';
import { TopPageGrid } from './Detections';
import { useTheme } from "themes/Palette";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
        },
    },
    '& .MuiInputLabel-root': {
        padding: theme.spacing(0, 0),
        '&.Mui-focused': {
            color: theme.palette.primary.main,
        },
    },
    '& .MuiSelect-select': {
        color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
    },
}));

const makeFilename = (clientName) => {
    const currentDate = new Date().toISOString().split('T')[0];
    return `${clientName} - Company Approvals - ${currentDate}.xlsx`;
};

const APPROVAL_COLS = ['account', 'client', 'detections', 'foreign', 'domestic', 'est_value', 'status', 'sent_date', 'response_date', 'client_notes', 'internal_notes', 'id', 'account_id', 'client_id',];

const Approvals = () => {
    const { clientId } = useParams();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [approvals, setApprovals] = useState([]);
    const [numAccounts, setNumAccounts] = useState(0);
    const [numApproved, setNumApproved] = useState(0);
    const [numDoNotPursue, setNumDoNotPursue] = useState(0);
    const [numUnreviewed, setNumUnreviewed] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { theme } = useTheme();
    // Combined useEffect for client initialization and data fetching
    useEffect(() => {
        let isMounted = true;

        const initializeData = async () => {
            setLoading(true);
            setError(null);

            try {
                // Fetch clients
                const response = await approvalService.getClients();
                if (!isMounted) return;

                const sortedClients = response.data.sort((a, b) => a.client.localeCompare(b.client));
                setClients(sortedClients);


                // Determine which client to select
                let clientToSelect = null;

                if (clientId) {
                    // Priority 1: URL parameter
                    clientToSelect = sortedClients.find(c => c.id === parseInt(clientId));
                } else {
                    // Priority 2: Session storage
                    const savedClient = sessionStorage.getItem('selectedClient');
                    if (savedClient) {
                        clientToSelect = JSON.parse(savedClient);
                        // Verify the saved client still exists in the current list
                        if (!sortedClients.find(c => c.id === clientToSelect.id)) {
                            clientToSelect = null;
                        }
                    }
                }

                if (clientToSelect) {
                    setSelectedClient(clientToSelect);
                    sessionStorage.setItem('selectedClient', JSON.stringify(clientToSelect));

                    // Fetch approvals for the selected client
                    const approvalsResponse = await approvalService.getApprovals(clientToSelect.id);
                    if (!isMounted) return;
                    setApprovals(approvalsResponse.data);
                    console.log("Accounts: ", approvalsResponse.data);
                    setNumAccounts(approvalsResponse.data.length);
                    setNumApproved(approvalsResponse.data.filter(a => a.approved === true).length);
                    setNumDoNotPursue(approvalsResponse.data.filter(a => a.approved === false).length);
                    setNumUnreviewed(approvalsResponse.data.filter(a => a.reviewed === false).length);
                }
            } catch (err) {
                if (!isMounted) return;
                console.error('Error initializing data:', err);
                setError(err.response?.data?.detail || 'An error occurred while fetching data');
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        initializeData();

        return () => {
            isMounted = false;
        };
    }, [clientId]); // Only re-run if clientId changes

    // Simplified client change handler
    const handleClientChange = useCallback((event) => {
        const selectedClientId = event.target.value;
        const client = clients.find((c) => c.id === selectedClientId);

        if (client) {
            setLoading(true);
            setSelectedClient(client);
            sessionStorage.setItem('selectedClient', JSON.stringify(client));
            navigate(`/approvals/${selectedClientId}`);
        }
    }, [clients, navigate]);

    const getExportFilename = useCallback(() => {
        return makeFilename(selectedClient.client);
    }, [selectedClient]);

    const handleRowClick = (params) => {
        const { client_id, account_id } = params.row;
        navigate(`/approvals/detections/${client_id}/${account_id}`, {
            state: { rowData: params.row }
        });
    };

    const handleImportComplete = useCallback(async () => {
        if (selectedClient) {
            setLoading(true);
            try {
                const response = await approvalService.getApprovals(selectedClient.id);
                setApprovals(response.data);
                // Update the counts
                setNumAccounts(response.data.length);
                setNumApproved(response.data.filter(a => a.approved === true).length);
                setNumDoNotPursue(response.data.filter(a => a.approved === false).length);
                setNumUnreviewed(response.data.filter(a => a.reviewed === false).length);
            } catch (err) {
                setError(err.response?.data?.detail || 'Error refreshing approvals');
            } finally {
                setLoading(false);
            }
        }
    }, [selectedClient]);

    if (isLoading) return <LoadingSpinner />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Container maxWidth={false}>
                <StyledPaper
                    elevation={3}
                    sx={{
                        p: 3,
                        mb: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <FormControl sx={{ minWidth: 250, mr: 4 }}>
                        <InputLabel id="client-select-label">Select Client</InputLabel>
                        <StyledSelect
                            labelId="client-select-label"
                            label="Select Client"
                            value={selectedClient ? selectedClient.id : ''}
                            onChange={handleClientChange}
                            displayEmpty
                        >
                            {clients.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.client}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </FormControl>
                    <TopPageGrid
                        key1="Total Accounts"
                        value1={numAccounts}
                        key2="Approved"
                        value2={numApproved}
                        key3="Do Not Pursue"
                        value3={numDoNotPursue}
                        key4="Unreviewed"
                        value4={numUnreviewed}
                    />

                </StyledPaper>

                {selectedClient && (
                    <StyledPaper elevation={1}>

                        <ImportApprovals onImportComplete={handleImportComplete} />
                        <DataTable
                            data={approvals}
                            defaultVisibleColumns={APPROVAL_COLS}
                            exportFilename={getExportFilename()}
                            onRowClick={handleRowClick}
                        />
                    </StyledPaper>
                )}
            </Container>
        </Box >
    );
};

export default Approvals;