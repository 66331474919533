import React from 'react';
import { MenuItem, Typography, ListItemIcon, Link, Box } from '@mui/material';
import { useNotifications } from './NotificationContext';
import NotificationIcon from './NotificationIcon';
import useTaskManagement from 'pages/Tasks/useTaskManagement';

const NotificationItem = ({ notification, onClose }) => {
    const { removeNotification } = useNotifications();
    const { exportTaskResult } = useTaskManagement();

    // const handleNotificationAction = () => {
    //     const action = notification.task?.result?.action;
    //     if (action) {
    //         console.log("Notification action:", action);
    //         // Implement action handling logic here
    //     }
    //     removeNotification(notification.id);
    //     onClose();
    // };



    const taskResult = notification.task?.result;
    const hasExportableData = taskResult?.data && taskResult.data.length > 0;

    return (
        <MenuItem
            // onClick={null}
            sx={{
                py: 2,
                px: 2.5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '&:last-child': { borderBottom: 'none' },
                display: 'flex',
                alignItems: 'flex-start',
            }}
        >
            <ListItemIcon sx={{ mt: 0.5, mr: 2 }}>
                <NotificationIcon type={notification.type} />
            </ListItemIcon>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="body1" fontWeight="medium" gutterBottom>
                    {notification.message}
                </Typography>
                <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    {new Date(notification.createdAt).toLocaleString()}
                </Typography>
                {hasExportableData ? (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={notification.action}
                        underline="hover"
                        sx={{
                            textAlign: 'left',
                            wordBreak: 'break-word',
                            display: 'block',
                            mt: 0.5
                        }}
                    >
                        {taskResult.data_name || 'Export Data'}
                    </Link>
                ) : taskResult?.data_name ? (
                    <Typography
                        variant="body2"
                        sx={{
                            wordBreak: 'break-word',
                            mt: 0.5
                        }}
                    >
                        {taskResult.data_name}
                    </Typography>
                ) : null}
            </Box>
        </MenuItem>
    );
};

export default NotificationItem;
