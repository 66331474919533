import { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, FormControlLabel, Switch, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GalleryView from './GalleryView';
import PricingCard from './PricingCard';
import VideoPlayer from './VideoPlayer';
import { formatPercentage } from 'utils/dataUtils';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { pricingService } from 'services/api';
import { formatDateTime } from 'utils/dataUtils';
import FlagIcon from '@mui/icons-material/Flag';
import MovieIcon from '@mui/icons-material/Movie';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import BlockIcon from '@mui/icons-material/Block';
import PercentIcon from '@mui/icons-material/Percent';
import ReplayIcon from '@mui/icons-material/Replay';
import ImageIcon from '@mui/icons-material/Image';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupIcon from '@mui/icons-material/Group';
import CampaignIcon from '@mui/icons-material/Campaign';
import GavelIcon from '@mui/icons-material/Gavel';
import { alpha } from '@mui/material/styles';
import { useTheme } from 'themes/Palette';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const BackgroundContentWrapper = ({ currentUse, ...props }) => {
    const { fetchNotesForUse } = props;

    useEffect(() => {
        if (currentUse?.id) {
            fetchNotesForUse(currentUse.id);
        }
    }, [currentUse?.id, fetchNotesForUse]);

    return <BackgroundContent currentUse={currentUse} {...props} />;
};

const PricingUse = ({ isOpen, onClose, useData, orderedUseIds, selectedUseId, onUpdates, currentUser }) => {
    const [autoplay, setAutoplay] = useState(false);
    const [notesMap, setNotesMap] = useState({});
    const { isDarkMode, toggleTheme, theme } = useTheme();

    const fetchNotesForUse = useCallback(async (useId) => {
        if (!useId || notesMap[useId]) return;

        const response = await pricingService.getNotes(useId);
        setNotesMap(prev => ({
            ...prev,
            [useId]: response.data
        }));
    }, [notesMap]);

    const orderedUseData = orderedUseIds.map(id => useData.find(use => use.id === id));

    const handleUpdates = useCallback(async (updateType, updates) => {
        try {
            const result = await onUpdates(updateType, updates);

            if (updateType === 'add_note' && result) {
                setNotesMap(prev => ({
                    ...prev,
                    [updates.useId]: [...(prev[updates.useId] || []), {
                        note: updates.note,
                        user: currentUser.username,
                        user_photo: currentUser.photo,
                        date_created: new Date().toISOString(),
                    }]
                }));
            }

            return result;
        } catch (error) {
            if (updateType === 'add_note') {
                const response = await pricingService.getNotes(updates.useId);
                setNotesMap(prev => ({
                    ...prev,
                    [updates.useId]: response.data
                }));
            }
            throw error;
        }
    }, [onUpdates]);

    const renderForegroundContent = ({ currentItem: currentUse, onPrevious, onNext, isFirstItem, isLastItem }) => (
        <ForegroundContent
            currentUse={currentUse}
            autoplay={autoplay}
            onPrevious={onPrevious}
            onNext={onNext}
            onPriceClick={handlePriceClick}
            isFirstUse={isFirstItem}
            isLastUse={isLastItem}
            onUpdates={handleUpdates}
        />
    );

    const renderBackgroundContent = ({ currentIndex, totalItems, onClose }) => {
        const currentUse = orderedUseData[currentIndex];

        return (
            <BackgroundContentWrapper
                autoplay={autoplay}
                onAutoplayToggle={(e) => setAutoplay(e.target.checked)}
                currentIndex={currentIndex}
                totalUses={totalItems}
                onClose={onClose}
                currentUse={currentUse}
                notes={notesMap[currentUse?.id] || []}
                theme={theme}
                isDarkMode={isDarkMode}
                toggleTheme={toggleTheme}
                fetchNotesForUse={fetchNotesForUse}
            />
        );
    };

    const handlePriceClick = (useId, price) => {
        console.log(`Pricing use ${useId} at ${price}`);
    };

    const initialItemIndex = selectedUseId ? orderedUseIds.indexOf(selectedUseId) : 0;

    return (
        <GalleryView
            isOpen={isOpen}
            onClose={onClose}
            items={orderedUseData}
            initialItemIndex={initialItemIndex}
            renderForegroundContent={renderForegroundContent}
            renderBackgroundContent={renderBackgroundContent}
        />
    );
};

PricingUse.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    useData: PropTypes.array.isRequired,
    orderedUseIds: PropTypes.array.isRequired,
    selectedUseId: PropTypes.number,
    onOrderChanged: PropTypes.func,
    onUpdates: PropTypes.func.isRequired
};

const flagIcons = {
    'Artist Promo': CampaignIcon,
    'Drop Use': BlockIcon,
    'Fair Use': GavelIcon,
    'Incorrect Song Listed': MusicNoteIcon,
    'Missing Ownership %': PercentIcon,
    'No Song Embedded': BlockIcon,
    'Optics': VisibilityIcon,
    'Owns Both Sides': GroupIcon,
    'Repost': ReplayIcon,
    'Static Image': ImageIcon,
    'UGC': MovieIcon
};

const UseFlags = ({ flags }) => {
    if (!flags?.length) return null;

    return (
        <Box sx={{
            position: 'absolute',
            left: "0%",
            bottom: '5%',
            width: '20%',
            textAlign: 'left',
            opacity: 0.6,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderRadius: 2,
            boxShadow: 1,
            zIndex: 1000,
        }}>
            <Typography variant="h6" gutterBottom>Flags</Typography>
            <List>
                {flags.map((flag, index) => {
                    const FlagIconComponent = flagIcons[flag] || FlagIcon;
                    return (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FlagIconComponent />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={flag} />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

const BackgroundContent = ({ autoplay, onAutoplayToggle, currentIndex, totalUses, onClose, currentUse, notes, theme, isDarkMode, toggleTheme }) => (
    <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={2}
        zIndex={1001}
        sx={{
            pointerEvents: 'none',
            '& > *': {
                pointerEvents: 'auto'
            }
        }}
    >
        <Box display="flex" justifyContent="right" width="100%" gap={2}>
            <IconButton onClick={toggleTheme} sx={{ color: 'inherit' }}>
                {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <FormControlLabel
                control={
                    <Switch
                        checked={autoplay}
                        onChange={onAutoplayToggle}
                        color="primary"
                    />
                }
                label="Autoplay"
            />
            <IconButton onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>
        </Box>
        <Box alignSelf="flex-end">
            <Typography variant="body2" color="text.secondary">
                {currentIndex + 1} of {totalUses}
            </Typography>
        </Box>
        <UseInfo currentUse={currentUse} theme={theme} />
        <UserNotes notes={notes} theme={theme} />
    </Box>
);

const UseInfo = ({ currentUse, theme }) => (

    <Box
        sx={{
            position: 'absolute',
            left: "2.5%",
            top: '50%',
            transform: 'translateY(-50%)', // Vertically center the element
            width: '25%',
            height: '60%',
            opacity: 0.9,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            zIndex: 1000,
            pointerEvents: 'none',
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
            backdropFilter: 'blur(8px)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }}>
        {/* Song Information Section */}
        <Box sx={{
            borderBottom: '1px solid rgba(255,255,255,0.1)',
            paddingBottom: 2
        }}>
            <Typography
                variant="overline"
                sx={{
                    // color: alpha(theme.palette.text.primary, 0.7),
                    letterSpacing: '0.1em',
                    marginBottom: 1
                }}
            >
                SONG DETAILS
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                {currentUse.song_title}
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {currentUse.artist}
            </Typography>
            <Box sx={{ display: 'flex', gap: 3 }}>
                <Box>
                    <Typography variant="caption" >
                        Ownership
                    </Typography>
                    <Typography variant="body1" >
                        {formatPercentage(currentUse.ownership_info)}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption" >
                        Side
                    </Typography>
                    <Typography variant="body1" >
                        {currentUse.side}
                    </Typography>
                </Box>
            </Box>
        </Box>

        {/* Video Information Section */}
        <Box>
            <Typography
                variant="overline"
                sx={{
                    color: alpha(theme.palette.text.primary, 0.7),
                    letterSpacing: '0.1em',
                    marginBottom: 1
                }}
            >
                VIDEO DETAILS
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant="caption" sx={{ color: alpha(theme.palette.text.primary, 0.6) }}>
                        Platform
                    </Typography>
                    <Typography variant="body1" >
                        {currentUse.service} • {currentUse.channel}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption" sx={{ color: alpha(theme.palette.text.primary, 0.6) }}>
                        Upload Date
                    </Typography>
                    <Typography variant="body1" >
                        {currentUse.date_uploaded}
                    </Typography>
                    <Typography variant="caption" sx={{ color: alpha(theme.palette.text.primary, 0.6) }}>
                        Use Duration
                    </Typography>
                    <Typography variant="body1" >
                        {currentUse.use_duration}
                    </Typography>
                </Box>
                {currentUse.video_title && (
                    <Box>
                        <Typography variant="caption" sx={{ color: alpha(theme.palette.text.primary, 0.6) }}>
                            Title
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                pointerEvents: 'auto',
                                '&:hover': {
                                    WebkitLineClamp: 'unset',
                                    cursor: 'pointer'
                                }
                            }}
                            title={currentUse.video_title}
                        >
                            {currentUse.video_title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: alpha(theme.palette.text.primary, 0.6) }}>Use ID: {currentUse.id}</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    </Box>
);

const UserNotes = ({ notes, theme }) => {
    if (!notes?.length) return null;

    return (
        <Box sx={{
            position: 'absolute',
            right: "2.5%",
            top: '50%',
            transform: 'translateY(-50%)',
            width: '25%',
            minHeight: '60%',
            maxHeight: '90%',
            opacity: 0.9,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            zIndex: 1000,
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
            backdropFilter: 'blur(8px)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }}>
            <Typography
                variant="overline"
                sx={{
                    letterSpacing: '0.1em',
                    marginBottom: 1
                }}
            >
                NOTES
            </Typography>
            {notes.map((note, index) => (
                <Box
                    key={index}
                    sx={{
                        backgroundColor: 'background.paper',
                        borderRadius: 2,
                        padding: 2,
                        boxShadow: 1,
                        width: '100%',
                        '&:hover': {
                            boxShadow: 3,
                            transform: 'translateY(-2px)',
                            transition: 'all 0.2s ease-in-out'
                        },
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                    }}>
                        <Avatar
                            src={note.user_photo}
                            alt={note.user}
                            sx={{
                                width: 40,
                                height: 40,
                                flexShrink: 0,
                                border: '2px solid',
                                borderColor: 'primary.light',
                            }}
                        >
                            {note.user.charAt(0)}
                        </Avatar>
                        <Box sx={{
                            flex: 1,
                            minWidth: 0,
                        }}>
                            <Box sx={{ mb: 0.5 }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        lineHeight: 1.2,
                                        display: 'inline-block',
                                        mr: 1,
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {note.user}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{ display: 'inline-block' }}
                                >
                                    {formatDateTime(note.date_created)}
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    whiteSpace: 'pre-wrap',
                                    color: 'text.primary',
                                    lineHeight: 1.6,
                                    wordBreak: 'break-word'
                                }}
                            >
                                {note.note}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const ForegroundContent = ({ currentUse, autoplay, onPrevious, onNext, onPriceClick, isFirstUse, isLastUse, onUpdates }) => {
    console.log('Content props:', { currentUse, autoplay, isFirstUse, isLastUse });

    if (!currentUse) {
        console.error('currentUse is undefined in Content component');
        return null;
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="40vw"
            height="90vh"
            zIndex={1000}
            py="5vh"
            px={4}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
            >
                <AnimatePresence mode="wait">
                    <motion.div
                        key={currentUse.id}
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <PricingCard
                            use={currentUse}
                            onPriceClick={onPriceClick}
                            onUpdates={onUpdates}
                            videoPlayer={
                                <VideoPlayer
                                    src={currentUse.archive_link}
                                    autoplay={autoplay}
                                />
                            }
                        />
                    </motion.div>
                </AnimatePresence>
            </Box>
        </Box>
    );
};

ForegroundContent.propTypes = {
    currentUse: PropTypes.object,
    autoplay: PropTypes.bool,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onPriceClick: PropTypes.func,
    isFirstUse: PropTypes.bool,
    isLastUse: PropTypes.bool,
    onUpdates: PropTypes.func.isRequired
};

export default PricingUse;
