import { webSocketService } from 'services/webSocketService';
import api from 'services/api';

class TaskConnection {
    constructor(taskId, options = {}) {
        this.taskId = taskId;
        this.options = options;
        this.connection = null;
        this.isConnected = false;
    }

    connect() {
        const url = TaskService.getTaskWebSocketUrl(this.taskId);

        this.connection = webSocketService.createConnection(url, {
            debug: this.options.debug,
            reconnectAttempts: this.options.reconnectAttempts || 5,
            connectionTimeout: this.options.connectionTimeout || 10000
        });

        // Set up event listeners with task-specific context
        this.connection.on('open', () => {
            this.isConnected = true;
            this.options.onOpen?.({
                taskId: this.taskId,
                timestamp: new Date()
            });
        });

        this.connection.on('message', (data) => {
            this.options.onMessage?.({
                taskId: this.taskId,
                data,
                timestamp: new Date()
            });
        });

        this.connection.on('error', (error) => {
            this.options.onError?.({
                taskId: this.taskId,
                error,
                timestamp: new Date()
            });
        });

        this.connection.on('close', (event) => {
            this.isConnected = false;
            this.options.onClose?.({
                taskId: this.taskId,
                event,
                timestamp: new Date()
            });
        });

        return this;
    }

    send(data) {
        if (!this.isConnected) {
            console.warn(`Attempting to send message while disconnected (Task ${this.taskId})`);
            return false;
        }
        this.connection.send(data);
        return true;
    }

    disconnect() {
        if (this.connection) {
            this.connection.close();
            this.connection = null;
            this.isConnected = false;
        }
    }

    getState() {
        return {
            taskId: this.taskId,
            isConnected: this.isConnected,
            connectionState: this.connection?.getState()
        };
    }
}

export class TaskService {
    static async createTask(taskInput) {
        const createUrl = `/tasks`;
        console.log('createUrl', createUrl);
        return await api.post(createUrl, taskInput);
    }

    static async getAllTasks() {
        return await api.get(`/all_tasks`);
    }

    static async getPendingTasks() {
        return await api.get(`/pending_tasks`);
    }

    static async getTaskStats() {
        return await api.get(`/task_stats`);
    }

    static getTaskWebSocketUrl(taskId) {
        const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const wsPort = process.env.NODE_ENV === 'production' ? '' : ':8000';
        const wsHost = process.env.NODE_ENV === 'production'
            ? window.location.hostname
            : 'localhost';

        return `${wsProtocol}//${wsHost}${wsPort}/api/ws/tasks/${taskId}`;
    }

    static createWebSocketConnection(taskId, options = {}) {
        return new TaskConnection(taskId, {
            debug: process.env.NODE_ENV !== 'production',
            ...options
        }).connect();
    }
}

export const task2Service = TaskService;