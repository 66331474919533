import {
  Box,
  Typography,
  Grid,
  Container,
  Stack,
  Paper,
} from "@mui/material";
import { formatPercentage, formatMoney } from "utils/dataUtils";
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

const MatterDetails = ({ matter }) => {
  const navigate = useNavigate();

  const handleFirmClick = () => {
    navigate(`/firm/${matter.firm_id}`, { state: { firmId: matter.firm_id } });
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%", py: 3 }}>
      <Container maxWidth={false}>
        {matter.summary && (
          <Paper elevation={0} sx={{ p: 3, mb: 4, backgroundColor: 'primary.lighter' }}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <InfoIcon color="primary" />
              <Typography variant="h5" color="primary.main">
                Summary
              </Typography>
            </Stack>
            <Typography variant="body1" color="text.secondary">
              {matter.summary}
            </Typography>
          </Paper>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack spacing={4}>
              <Paper elevation={0} sx={{ p: 3 }}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
                  <InfoIcon color="primary" />
                  <Typography variant="h6">Use Details</Typography>
                </Stack>
                <Stack spacing={2}>
                  {[
                    { label: 'Uses', value: matter.number_of_uses },
                    { label: 'Unique', value: matter.number_of_unique_uses },
                    { label: 'Unique Ownership', value: formatPercentage(matter.unique_use_ownership) }
                  ].map((item) => (
                    <Stack
                      key={item.label}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ borderBottom: '1px solid', borderColor: 'divider', pb: 1 }}
                    >
                      <Typography color="text.secondary">{item.label}</Typography>
                      <Typography fontWeight="medium">{item.value}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Paper>

              <Paper elevation={0} sx={{ p: 3 }}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
                  <PeopleAltIcon color="primary" />
                  <Typography variant="h6">Assignments</Typography>
                </Stack>
                <Stack spacing={2}>
                  {[
                    { label: 'Assigned To', value: matter.assigned_to },
                    { label: 'Assigned External', value: matter.assigned_external },
                    {
                      label: 'User Firm',
                      value: matter.user_firm,
                      clickable: matter.firm_id
                    },
                    { label: 'Account Block', value: matter.account_block }
                  ].map((item) => (
                    <Stack
                      key={item.label}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ borderBottom: '1px solid', borderColor: 'divider', pb: 1 }}
                    >
                      <Typography color="text.secondary">{item.label}</Typography>
                      {item.clickable ? (
                        <MuiLink
                          component="button"
                          onClick={handleFirmClick}
                          sx={{ textAlign: 'right', fontWeight: 'medium' }}
                        >
                          {item.value}
                        </MuiLink>
                      ) : (
                        <Typography fontWeight="medium">{item.value}</Typography>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
                <MonetizationOnIcon color="primary" />
                <Typography variant="h6">Offer Details</Typography>
              </Stack>
              <Stack spacing={2}>
                {[
                  { label: 'Opening Offer', value: matter.opening_offer },
                  { label: 'Current Offer', value: matter.current_offer },
                  { label: 'Counter Offer', value: matter.counteroffer },
                  { label: 'Floor', value: matter.floor, divider: true },
                  { label: 'Settlement Amount', value: matter.settlement_amount },
                  { label: 'Amount Per Use', value: matter.amount_per_use }
                ].map((item, index) => (
                  <React.Fragment key={item.label}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        borderBottom: item.divider ? 2 : 1,
                        borderColor: 'divider',
                        pb: 1
                      }}
                    >
                      <Typography color="text.secondary">{item.label}</Typography>
                      <Typography fontWeight="medium">
                        {item.value !== null ? formatMoney.format(item.value) : '-'}
                      </Typography>
                    </Stack>
                    {item.divider && <Box sx={{ height: 16 }} />}
                  </React.Fragment>
                ))}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MatterDetails;
