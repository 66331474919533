import { DataTable } from "components/common/DataTable";

const TableRenderer = ({ data, onRowClick }) => {
  return (
    <DataTable
      data={data}
      onRowClick={onRowClick}
      style={{ width: '100%', height: '100%' }} // Added height: '100%'
      rowsPerPage={20}
    />
  );
};

export default TableRenderer;