import * as XLSX from 'xlsx-js-style';
import { titleAndReplace } from './dataUtils';
import { formatTypes, columnFormats } from './columnFormats';

export const convertToCSV = async (jsonArray, titleCaseHeaders = true) => {
    if (jsonArray.length === 0) return '';
    const headers = Object.keys(jsonArray[0]);
    const formattedHeaders = titleCaseHeaders ? await Promise.all(headers.map(titleAndReplace)) : headers;
    const headerRow = formattedHeaders.join(',') + '\n';
    const rows = await Promise.all(jsonArray.map(async obj =>
        (await Promise.all(headers.map(header => obj[header]))).join(',')
    ));
    return headerRow + rows.join('\n');
};

export const formatExcelWorksheet = async (worksheet, titleCaseHeaders = true) => {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    const maxRow = range.e.r;
    const maxCol = range.e.c;

    // Set font family, size, alignment, and apply column formats
    for (let R = 0; R <= maxRow; ++R) {
        for (let C = 0; C <= maxCol; ++C) {
            const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
            if (!worksheet[cellRef]) worksheet[cellRef] = { t: 's', v: '' };

            const cellValue = worksheet[cellRef].v;
            const columnHeader = worksheet[XLSX.utils.encode_cell({ r: 0, c: C })].v;
            const columnFormatType = columnFormats[columnHeader];
            const columnFormat = formatTypes[columnFormatType];

            worksheet[cellRef].s = {
                font: { name: 'Roboto', sz: 12 },
                alignment: {
                    horizontal: 'left',
                    vertical: 'bottom',
                    wrapText: true
                },
            };

            if (R === 0) {
                // Skip formatting for header row
                continue;
            }

            // Handle null/empty values
            if (cellValue === null || cellValue === undefined || cellValue === '') {
                worksheet[cellRef] = { t: 's', v: '' };
                continue;
            }

            if (columnFormat) {
                if (columnFormatType === 'url') {
                    // Format as hyperlink
                    if (cellValue) {
                        worksheet[cellRef] = {
                            ...worksheet[cellRef],
                            t: 's',
                            v: cellValue,
                            l: { Target: cellValue, Tooltip: cellValue }
                        };
                        worksheet[cellRef].s = {
                            ...worksheet[cellRef].s,
                            font: { ...worksheet[cellRef].s.font, color: { rgb: "0000FF" }, underline: true }
                        };
                    }
                } else if (columnFormatType === 'date' && cellValue) {
                    // Special handling for dates
                    const dateValue = new Date(cellValue);
                    if (!isNaN(dateValue.getTime())) {
                        worksheet[cellRef] = {
                            t: 'd',
                            v: dateValue,
                            z: columnFormat.format
                        };
                    } else {
                        worksheet[cellRef] = { t: 's', v: '' };
                    }
                } else {
                    worksheet[cellRef].t = columnFormat.type;
                    worksheet[cellRef].z = columnFormat.format;
                }
            } else if (typeof cellValue === 'number' || (typeof cellValue === 'string' && !isNaN(cellValue))) {
                worksheet[cellRef].z = formatTypes.number.format;
            }
        }
    }

    // Format headers after formatting the worksheet
    for (let C = 0; C <= maxCol; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
        if (titleCaseHeaders) {
            worksheet[cellRef].v = await titleAndReplace(worksheet[cellRef].v);
        }
        worksheet[cellRef].s = {
            font: { name: 'Roboto', sz: 12, bold: true },
            alignment: { horizontal: 'left', vertical: 'bottom', wrapText: true },
        };
    }

    // Set column widths and row heights
    const defaultColWidth = 21.75; // Equivalent to 175 pixels
    const defaultRowHeight = 33;
    worksheet['!cols'] = Array(maxCol + 1).fill({ width: defaultColWidth });
    worksheet['!rows'] = Array(maxRow + 1).fill({ hpt: defaultRowHeight });

    return worksheet;
};

export const convertToXLSX = async (jsonArray, titleCaseHeaders = true) => {
    // Preprocess the data to handle empty values
    const processedArray = await Promise.all(jsonArray.map(async item => {
        const processedItem = {};
        for (const [key, value] of Object.entries(item)) {
            processedItem[key] = value === null || value === undefined ? '' : value;
        }
        return processedItem;
    }));

    // Create worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(processedArray, {
        header: Object.keys(processedArray[0])
    });

    const formattedWorksheet = await formatExcelWorksheet(worksheet, titleCaseHeaders);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, formattedWorksheet, 'Sheet1');
    return XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
};
