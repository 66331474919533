// DragAndDropArea.js
import React, { useState } from 'react';
import Modal from './Modal';
import TollingAgreementFields from './TollingAgreementFields';
import SettlementAgreementFields from './SettlementAgreementFields';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DragAndDrop = ({ matterId, matterName }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [file, setFile] = useState(null);
    const theme = useTheme();

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        setIsModalOpen(true);
    };

    const handleDocumentTypeChange = (type) => {
        setDocumentType(type);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setDocumentType('');
        setFile(null);
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setIsModalOpen(true);
        }
    };

    const renderFieldSet = () => {
        switch (documentType) {
            case 'TOLLING':
                return <TollingAgreementFields />;
            case 'SETTLEMENT':
                return <SettlementAgreementFields />;
            default:
                return null;
        }
    };

    return (
        <Box
            component="label"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 100,
                border: `2px dashed ${theme.palette.primary.main}30`,
                borderRadius: 2,
                backgroundColor: 'transparent',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: theme.palette.background.default,
                },
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />
            <Typography variant="body1" color="text.secondary">
                Drag and drop files here
            </Typography>
            <Modal
                isOpen={isModalOpen}
                onClose={handleClose}
                onDocumentTypeChange={handleDocumentTypeChange}
                matterId={matterId}
                matterName={matterName}
                file={file}
            >
                {renderFieldSet()}
            </Modal>
        </Box>
    );
};

export default DragAndDrop;